import i18n from "i18next";
import HttpApi from 'i18next-http-backend';
import {initReactI18next} from "react-i18next";

export default i18n
    .use(initReactI18next)
    .use(HttpApi)
    .init({
        lng: 'en',
        fallbackLng: "en",
        backend: {
            loadPath: '/locales/en/translation.json'
        },
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
    });
