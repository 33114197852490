import React from "react";

const SmallLogo = (props: React.SVGProps<SVGSVGElement>) => {
    return (
        <svg width="50" height="50" viewBox="0 0 17 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M3.64258 5.60516H12.8089V6.63289C12.8089 9.16411 10.757 11.2161 8.22576 11.2161C5.69454 11.2161 3.64258 9.16411 3.64258 6.63289V5.60516Z" fill="#32A6D5" stroke="#32A6D5" strokeWidth="1.5"/>
            <path d="M13.3356 18.1881V18.1881C10.5134 18.1881 8.22559 15.9002 8.22559 13.0781V12.3311V11.0771" stroke="#32A6D5" strokeWidth="1.5"/>
            <path d="M5.6308 4.85518V2.1886" stroke="#32A6D5" strokeWidth="1.5" strokeLinecap="round"/>
            <path d="M10.9639 4.85518V2.1886" stroke="#32A6D5" strokeWidth="1.5" strokeLinecap="round"/>
        </svg>
    )
}

export default SmallLogo;