import React from "react";

const Access = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="33"
      height="33"
      viewBox="0 0 33 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M0.699707 16.4471C0.699707 7.6105 7.86315 0.447052 16.6997 0.447052V0.447052C25.5363 0.447052 32.6997 7.6105 32.6997 16.4471V16.4471C32.6997 25.2836 25.5363 32.4471 16.6997 32.4471V32.4471C7.86315 32.4471 0.699707 25.2836 0.699707 16.4471V16.4471Z"
        fill="#3B3558"
      />
      <path
        d="M17.8056 8.51777C17.8056 9.10915 17.3262 9.58856 16.7348 9.58856C16.1435 9.58856 15.6641 9.10915 15.6641 8.51777C15.6641 7.9264 16.1435 7.44699 16.7348 7.44699C17.3262 7.44699 17.8056 7.9264 17.8056 8.51777Z"
        fill="white"
      />
      <path
        d="M13.1336 12.9885C12.8905 13.0881 12.6513 13.2052 12.4175 13.3402C9.60886 14.9617 8.64656 18.5531 10.2681 21.3617C11.8897 24.1703 15.481 25.1326 18.2896 23.511C18.7705 23.2334 19.1972 22.898 19.5661 22.5187"
        stroke="white"
        strokeLinecap="round"
      />
      <path
        d="M16.6543 11.3108V13.3292M25.0672 23.5285H23.6101L22.0995 18.9489H17.6543C17.102 18.9489 16.6543 18.5012 16.6543 17.9489V13.3292M16.6543 13.3292H19.5798"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Access;
