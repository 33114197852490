import styled from "styled-components";
import {Divider} from "antd";
import {Link} from "react-router-dom";

export const ContentCard = styled.div`
  display: flex;
  padding: 16px;
  flex-direction: column;
  width: 1400px;
  height: 100%;
  border-radius: 16px;
  background: #ffffff;
  z-index: 100;
  position: relative;
`;

export const RowItem = styled.div`
  display: flex;
  width: 100%;
  padding: 15px 30px 15px 30px;
  flex-direction: row;
  justify-content: space-between;
`;

export const CardTitle = styled.span`
  padding-right:30px;
  font-family: "Nunito";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 150%;
`;
export const CardText = styled.span`
  display: flex;
  align-items: center;
  gap: 30px;
  font-family: "Nunito";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: #3b3558;
`;
export const DividerLine = styled(Divider)`
  width: calc(100% + 2%);
  margin-left: -16px;
  margin-right: -0px;
`;

export const LinkItem = styled(Link)`
  display: flex;
  align-items: center;
  gap: 30px;
  font-family: "Nunito";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: #3b3558;
`
