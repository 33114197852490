import { useState } from "react";
import { Button } from "antd";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { NAVIGATION } from "../../enums/navigation";
import { Logo } from "../../icons";
import ForgotPasswordForm from "../../components/ForgotPasswordForm";
import {
  CardBody,
  CardContainer,
  CardHeader,
  LoginText,
  Wrapper,
} from "../Login/styles";
import { TextReset } from "./styles";

const ForgotPassword = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [isPasswordSent, setIsPasswordSent] = useState(false);

  const handleRedirect = () => navigate(NAVIGATION.LOGIN);
  const handleSendPassword = () => setIsPasswordSent(true)

  if (isPasswordSent) {
    return (
      <Wrapper>
        <CardContainer>
          <CardHeader>
            <Logo width={270} height={71} />
          </CardHeader>
          <CardBody>
            <LoginText>Check your inbox</LoginText>
            <TextReset>
              We've sent password reset instructions to your registered email
            </TextReset>
            <Button onClick={handleRedirect}>Back</Button>
          </CardBody>
        </CardContainer>
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <CardContainer>
        <CardHeader>
          <Logo width={270} height={71} />
        </CardHeader>
        <CardBody>
          <LoginText>Reset Password</LoginText>
          <TextReset>
            Enter your registered email address and we'll send instructions to
            reset your password
          </TextReset>
          <ForgotPasswordForm handleSendPassword={handleSendPassword} />
        </CardBody>
      </CardContainer>
    </Wrapper>
  );
};

export default ForgotPassword;
