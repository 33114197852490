import React from "react";
import { ButtonGroup, WrapperHeader} from "./styles";
import { OutlinedButton } from "../../OutlinedButton";

interface IProps {
    handleAddPromoCode: () => void;
}

const TableHeader: React.FC<IProps> = ({
    handleAddPromoCode
}) => {
    return (
			<WrapperHeader>
				<ButtonGroup>
						<OutlinedButton onClick={handleAddPromoCode}>Add New</OutlinedButton>
				</ButtonGroup>
			</WrapperHeader>
    )
}

export default TableHeader;
