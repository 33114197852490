import React, { useEffect } from "react";
import moment from "moment";
import {
  PreviewButton,
  RightSide,
  Row,
  StatusCardItem,
  StatusItems,
  UpdateContentButton,
  WrapperCardRightSide,
} from "../../pages/About/styles";
import { Item } from "../../types/rest/editor/IContent";
import { IMultiItems } from "../../types/rest/editor/IMultiContent";

interface IProps {
  lastEditionDate: Item[] | IMultiItems[];
  handleToggle: () => void;
  onSubmit?: () => Promise<void>;
}

const ContentEditorRightSide: React.FC<IProps> = ({
  lastEditionDate,
  handleToggle,
  onSubmit,
}) => {
  let timeArray: string[] = [];
  let latestEdition = "";

  const handleSortData = (arr: string[]) =>
    arr.sort((a, b) => moment(a).unix() - moment(b).unix());

  useEffect(() => {
    if (!lastEditionDate.length) {
      return;
    }

    lastEditionDate.forEach((item) => {
      if (!item.updatedAt) {
        return;
      }
      timeArray.push(item.updatedAt);
      const lastEdition = handleSortData(timeArray);
      latestEdition = lastEdition[0];
    });
  }, []);

  if (!lastEditionDate.length) {
    return <p>Loading</p>;
  }

  return (
    <WrapperCardRightSide>
      <RightSide title="Status">
        <StatusItems>
          <Row>
            <StatusCardItem>Last Edition Date</StatusCardItem>
            <StatusCardItem>
              {moment(latestEdition[0]).format("YYYY-MM-DD").toString()}
            </StatusCardItem>
          </Row>
          <PreviewButton onClick={handleToggle}>Preview</PreviewButton>
          <UpdateContentButton onClick={onSubmit}>
            Update Content
          </UpdateContentButton>
        </StatusItems>
      </RightSide>
    </WrapperCardRightSide>
  );
};

export default ContentEditorRightSide;
