import {useEffect, useState} from "react";
import useSWR from "swr";
import {Layout, message} from "antd";
import EditorContent from "../../components/EditorContent/EditorContent";
import { Content, Header, Nav } from "../../layout";
import ContentEditorTitle from "../../components/ContentEditorTitle";
import ContentEditorRightSide from "../../components/ContentEditorRightSide";
import PreviewTerms from "../../components/Modal/Previews/PreviewTerms";
import getContentUrl from "../../consts/getContentUrl";
import {IContent} from "../../types/rest/editor/IContent";
import {CONTENT_EDITOR_TITLE} from "../../enums/contentEditorTitle";
import {CONTENT_EDITOR_TYPE} from "../../enums/contentEditorType";
import Axios from "../../serverConfig";
import getEditContentUrl from "../../consts/getEditContentUrl";
import { RightSection } from "../Users/styles";
import {
  ContentTextHeader,
  EditorCard,
  LeftCard,
  LeftSide,
  WrapperCard,
} from "../About/styles";

const TermsConditions = () => {
    const { data, isValidating } = useSWR(getContentUrl);
    const [stateEditor, setStateEditor] = useState<IContent>();
    const [isOpenPreview, setIsOpenPreview] = useState(false);

    const handleToggle = () => {
        setIsOpenPreview((prevState) => !prevState);
    };

    const errorMessage = () => message.error("Sorry something going wrong!")
    const successMessage = () => message.success("Success!")

    useEffect(()=>{
        if(!data){
            return
        }
        handleData()
    },[isValidating,data])

    const handleData = () => {
        try {
            data.map((item: IContent) => {
                if (
                    item.title === CONTENT_EDITOR_TITLE.TERMS &&
                    item.type === CONTENT_EDITOR_TYPE.SINGLE
                )
                    setStateEditor(item);
            })
        }catch (e) {
            errorMessage()
        }
    }

    const handleSubmit = async () => {
        try {
            if(!stateEditor){
                return
            }
            await Axios.post(getEditContentUrl(), {
                id: stateEditor.id,
                type: stateEditor.type,
                title: stateEditor.title,
                items: [{
                    markdown:stateEditor.items.map((item)=>item.markdown)
                }],
            })
            successMessage()
        } catch (err: unknown) {
            errorMessage()
        }
    };

    if(isValidating){
        return <p>loading...</p>
    }

    if(!stateEditor){
        return <p>Empty</p>
    }

    return (
        <>
            <PreviewTerms
                data={stateEditor}
                handleToggle={handleToggle}
                isOpenPreview={isOpenPreview}
            />
            <Layout>
                <Nav />
                <RightSection>
                    <Header />
                    <Content>
                        <WrapperCard>
                            <LeftSide>
                                <LeftCard>
                                    <ContentEditorTitle nameSection={stateEditor.title} />
                                    <EditorCard>
                                        <ContentTextHeader>Content</ContentTextHeader>
                                        <EditorContent setData={setStateEditor} data={stateEditor} />
                                    </EditorCard>
                                </LeftCard>
                            </LeftSide>
                            <ContentEditorRightSide handleToggle={handleToggle} lastEditionDate={stateEditor.items} onSubmit={handleSubmit}/>
                        </WrapperCard>
                    </Content>
                </RightSection>
            </Layout>
        </>
    );
};

export default TermsConditions;
