import styled from "styled-components";

export const OpinionSection = styled.div`
  height: 100%;
  display: flex;
  flex-direction: row;
  gap: 20px;
`;

export const OpinionRightSide = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;
export const OpinionUserName = styled.span`
  font-family: Nunito;
  display: flex;
  align-items: center;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: #3b3558;
`;
export const OpinionDate = styled.span`
  display: flex;
  align-items: center;
  font-family: Nunito;
  font-style: normal;
  font-size: 12px;
  line-height: 150%;
  font-weight: 400;
  color: #b0b9c6;
  line-height: 18px;
`;

export const OpinionUserDate = styled.div`
  display: flex;
  gap: 8px;
  flex-direction: row;
`;

export const OpinionStar = styled.div`
  display: flex;
  flex-direction: row;
`;
