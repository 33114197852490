import React, { useRef, useState } from "react";
import { Checkbox, Form, message, Modal, TimePicker } from "antd";
import { CheckboxChangeEvent } from "antd/es/checkbox";
import { Formik } from "formik";
import moment from "moment";
import { DividerModal } from "../../Divider";
import { IDays } from "../../../types/IDays";
import {
  IAvailability,
  IChargerDetails,
} from "../../../types/rest/charger/IChargerDetails";
import { getEditCharger } from "../../../consts/getEditCharger";
import Axios from "../../../serverConfig";
import { ButtonSubmit, ButtonsWrapper, CancelButton } from "../styles";
import { Col, Days, Row, Wrapper } from "./styles";

interface IProps {
  editAvailability: IAvailability | undefined;
  toggleOpenEditor: boolean;
  handleToggle: () => void;
  details: IChargerDetails;
  setDetails: React.Dispatch<React.SetStateAction<IChargerDetails | undefined>>;
}

const EditAvailability: React.FC<IProps> = ({
  toggleOpenEditor,
  handleToggle,
  editAvailability,
  details,
  setDetails,
}) => {
  const errorMessage = () => message.error("Sorry something going wrong!");
  const successEditMessage = () =>
    message.success("Success! Charger availability changed");

  const [time, setTime] = useState(() => {
    if (editAvailability) {
      return {
        startTime: editAvailability.startTime,
        endTime: editAvailability.endTime,
      };
    }
    return {
      startTime: new Date(),
      endTime: new Date(),
    };
  });

  const [newDaysAvailability, setNewDaysAvailability] = useState<IDays>({
    monday: editAvailability!.monday,
    tuesday: editAvailability!.tuesday,
    wednesday: editAvailability!.wednesday,
    thursday: editAvailability!.thursday,
    friday: editAvailability!.friday,
    saturday: editAvailability!.saturday,
    sunday: editAvailability!.sunday,
  });

  const initialValues: IAvailability | undefined = editAvailability;

  const onChange = (e: CheckboxChangeEvent) => {
    if (e) {
      let lowerString = e.target.name?.toLowerCase();
      setNewDaysAvailability((prevState) => ({
        ...prevState,
        [lowerString as string]: e.target.checked,
      }));
    }
  };

  const formikRef = useRef<any>();

  const handleFormikClick = () => {
    if (formikRef?.current !== null) {
      formikRef.current.handleSubmit();
    }
  };

  const onChangeTime = (values: any, formatString: [string, string]) => {
    setTime({
      startTime: values[0]._d,
      endTime: values[1]._d,
    });
  };

  const handleSubmitForm = async (values: IAvailability) => {
    if (!values) {
      return;
    }

    const isMinimumOneDayAvailability = Object.values(newDaysAvailability).find((item)=>item)

    if(!isMinimumOneDayAvailability){
      alert("You must choose one day minimum")
      return
    }

    const newAvailability = {
      startTime: (moment(time.startTime)).format(),
      endTime: (moment(time.endTime)).format(),
      ...newDaysAvailability,
      id: editAvailability!.id,
    };
    const { chargerAvailability, ...rest } = details;
    const chargerWithIdChanged = chargerAvailability.map((item) => {
      if (item.id === editAvailability!.id) {
        item = newAvailability;
        return item;
      }
      return item;
    });

    if (!chargerWithIdChanged) {
      return;
    }
    try {
      setDetails({
        chargerAvailability: chargerWithIdChanged,
        ...rest,
      });

      const newChargerDetails: IChargerDetails = {
        chargerAvailability: chargerWithIdChanged,
        ...rest,
      }

      const {chargerAvailability,ratings,...chargerRest} = newChargerDetails
      const body = {
        chargerAvailability,
        ...chargerRest
      }

      await Axios.post(getEditCharger(), body);
      successEditMessage();
      handleToggle();
    } catch (e) {
      errorMessage();
    }
  };


  if (!editAvailability) {
    return <p>empty</p>;
  }

  const { id, startTime, endTime, ...rest } = editAvailability;

  return (
    <Modal
      mask={true}
      maskStyle={{ backdropFilter: "blur(8px)" }}
      title="Edit Availability Information "
      centered
      visible={toggleOpenEditor}
      onOk={handleFormikClick}
      onCancel={handleToggle}
    >
      <Formik
        innerRef={formikRef}
        initialValues={initialValues!}
        onSubmit={handleSubmitForm}
      >
        {({ handleSubmit, values, handleChange, errors, touched }) => (
          <Form
            onFinish={handleSubmit}
            name="basic"
            layout="vertical"
            autoComplete="off"
          >
            <Form.Item>
              <Wrapper>
                <Row>
                  <Days>Days</Days>
                  <Col>
                    {Object.entries(rest).map(([day, value], index) => {
                      return (
                        <div>
                          <Checkbox
                            name={day}
                            onChange={onChange}
                            defaultChecked={value}
                          >
                            {day[0].toUpperCase() + day.substring(1)}
                          </Checkbox>
                        </div>
                      );
                    })}
                  </Col>
                </Row>
              </Wrapper>
            </Form.Item>
            <DividerModal bottom={"15px"} top={"15px"} />
            <Form.Item>
              <Row>
                <Days>Time</Days>
                <TimePicker.RangePicker
                  defaultValue={[(moment(time.startTime)), (moment(time.endTime))]}
                  format={"HH:mm"}
                  showSecond={false}
                  onChange={onChangeTime}
                />
              </Row>
            </Form.Item>
            <ButtonsWrapper>
              <CancelButton onClick={handleToggle}>Cancel</CancelButton>
              <ButtonSubmit htmlType="submit">Save</ButtonSubmit>
            </ButtonsWrapper>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};
export default EditAvailability;
