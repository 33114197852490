import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import useSWR from "swr";
import moment from "moment";
import useGetChannels from "../../../../firebase/useGetChannels";
import getUsersUrl from "../../../../consts/getUsersUrl";
import TabsContext from "../../../../context/tabsContext";
import useSubscribeChannelMessages from "../../../../firebase/useSubscribeChannelMessages";
import BottomBiggerCard from "../../../Cards/BottomBiggerCard";
import { InboxCardsWrapper } from "../styles";
import Inbox from "./Inbox";

interface IUserInfoMessage {
  id: string;
  concatChannelId: string;
  assignedUsers: number[];
  userInfo: any;
  text:any
}

const InboxContainer = () => {
  const { data } = useSWR(getUsersUrl());

  const { activeChannel, handleChannelId } = useContext(TabsContext);

  const [userInfoMessage, setUserInfoMessage] = useState<IUserInfoMessage[]>(
    []
  );

  const params = useParams();
  const { userId: uid } = params;
  const parsedId = parseInt(uid!);

  const { channels, idChannels } = useGetChannels(uid!);

  const { channelMessages } = useSubscribeChannelMessages(idChannels);

  useEffect(() => {
    if (!data || !uid || !channelMessages.length) {
      return;
    }

    const parsedUsers = channels.map((item) => ({
      ...item,
      assignedUsers: item.assignedUsers.filter((id) => id !== parsedId),
    }));

    const idFromParsedUsers = parsedUsers.map((item) => item.assignedUsers[0]);

    const idFromParsedUsersFiltered = idFromParsedUsers.filter((item)=>item)

    if (!idFromParsedUsersFiltered) {
      return;
    }

    const usersData = data.filter(({ userId }: { userId: number }) => idFromParsedUsersFiltered.includes(userId)
    );

    const channelWithUserInfo = parsedUsers.map((item) => ({
      ...item,
      userInfo: usersData.filter(
        ({ userId }: { userId: number }) => item.assignedUsers.includes(userId)
      )[0],
      text:channelMessages.filter(({user})=>user._id === item.assignedUsers[0] || user._id === uid)
    }));
    setUserInfoMessage(channelWithUserInfo);

  }, [data, channels, idChannels, channelMessages]);

  if (!userInfoMessage) {
    return <p>No message</p>;
  }

  return (
    <BottomBiggerCard title={"Inbox"} overflow={true}>
      <InboxCardsWrapper>
        {userInfoMessage.map(({ userInfo, id,text}) => (
          <span key={id} onClick={() => handleChannelId(id)}>
            <Inbox
              channelId={id}
              userHandle={userInfo?.handle}
              userLogo={userInfo?.image}
              userName={`${userInfo?.firstName} ${userInfo?.lastName}`}
              textMessage={text[0]?.text}
              dateMessage={moment(text[0]?.createdAt.toDate())
                .format("DD-MM-YYYY")
                .toString()}
            />
          </span>
        ))}
      </InboxCardsWrapper>
    </BottomBiggerCard>
  );
};
export default InboxContainer;
