import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import * as ReactDOMServer from "react-dom/server";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { Editor } from "react-draft-wysiwyg";
import htmlToDraft from "html-to-draftjs";
import draftToHtml from "draftjs-to-html";
import { ContentState, convertToRaw, EditorState } from "draft-js";
import { IContent } from "../../../types/rest/editor/IContent";
import "../editorContent.css";

const HtmlToReactParser = require("html-to-react").Parser;
const htmlToReactParser = new HtmlToReactParser();

interface IProps {
  data: IContent;
  setData: Dispatch<SetStateAction<IContent | undefined>>;
}

const EditorContent: React.FC<IProps> = ({ data, setData }) => {
  const [editorState, setEditorState] = useState<EditorState>();

  useEffect(() => {
    setEditorState(() => {
      if (data) {
        let initialValue = "";
        data.items.forEach((item) => (initialValue = item.markdown));
        const blockFromHtml = htmlToDraft(initialValue);
        const state = ContentState.createFromBlockArray(
          blockFromHtml.contentBlocks,
          blockFromHtml.entityMap
        );
        return EditorState.createWithContent(state);
      }
      return EditorState.createEmpty();
    });
  }, []);

  useEffect(() => {
    if (!editorState) {
      return;
    }
    const text = draftToHtml(convertToRaw(editorState.getCurrentContent()));
    const newValues = htmlToReactParser.parse(text);
    const reactHtml = ReactDOMServer.renderToStaticMarkup(newValues);
    setData({
      id: data.id,
      title: data.title,
      type: data.type,
      items: data.items.map((item) => ({
        id: item.id,
        markdown: reactHtml,
        updatedAt: item.updatedAt,
      })),
    });
  }, [editorState]);

  return (
    <Editor
      key={data.id}
      editorState={editorState}
      wrapperClassName="editor-content"
      editorClassName="editor-wrapper"
      onEditorStateChange={setEditorState}
      toolbar={{
        list: { inDropdown: true },
        link: { inDropdown: true },
        options: ['inline', 'blockType', 'list', 'textAlign', 'colorPicker', 'link', 'embedded', 'emoji', 'image', 'remove', 'history'],
        inline: {
          options: [
            "bold",
            "italic",
            "underline",
            "strikethrough",
            "monospace",
          ],
        },
      }}
    ></Editor>
  );
};

export default EditorContent;
