
const getUserDetailUrl = (id:string | undefined) => {
    const link = process.env.REACT_APP_SERVER_API_V2
    if (!id) {
        return "null"
    }
    return (`${link}/user/${id}`)
}

export default  getUserDetailUrl
