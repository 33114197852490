import React from "react";

const Pending = (props: React.SVGProps<SVGSVGElement>) => {
    return (
        <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="8.5" cy="8.5" r="7.25" stroke="#DC0028" strokeWidth="1.5"/>
            <path d="M8.5 4.74902L8.5 10.0998" stroke="#DC0028" strokeWidth="1.5" strokeLinecap="round"/>
            <path d="M8.5 12.3251V12.29" stroke="#DC0028" strokeWidth="1.5" strokeLinecap="round"/>
        </svg>

    )
}

export default Pending
