import React from "react";

const Admin = (props: React.SVGProps<SVGSVGElement>) => {
    return (<svg width="56" height="35" viewBox="0 0 61 40" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <rect width="40" height="40" rx="20" fill="#32A6D5"/>
            <path
                d="M14.8147 26.144C14.5267 26.144 14.2927 26.078 14.1127 25.946C13.9447 25.814 13.8427 25.64 13.8067 25.424C13.7707 25.196 13.8127 24.944 13.9327 24.668L18.6847 14.138C18.8407 13.79 19.0267 13.544 19.2427 13.4C19.4707 13.244 19.7287 13.166 20.0167 13.166C20.2927 13.166 20.5387 13.244 20.7547 13.4C20.9827 13.544 21.1747 13.79 21.3307 14.138L26.1007 24.668C26.2327 24.944 26.2807 25.196 26.2447 25.424C26.2087 25.652 26.1067 25.832 25.9387 25.964C25.7707 26.084 25.5487 26.144 25.2727 26.144C24.9367 26.144 24.6727 26.066 24.4807 25.91C24.3007 25.742 24.1387 25.49 23.9947 25.154L22.8247 22.436L23.7967 23.066H16.2007L17.1727 22.436L16.0207 25.154C15.8647 25.502 15.7027 25.754 15.5347 25.91C15.3667 26.066 15.1267 26.144 14.8147 26.144ZM19.9807 15.812L17.4607 21.806L16.9927 21.23H23.0047L22.5547 21.806L20.0167 15.812H19.9807Z"
                fill="white"/>
            <path d="M60 17L54 23L48 17" stroke="#3B3558" strokeWidth="2" strokeLinecap="round"
                  strokeLinejoin="round"/>
        </svg>
    )
}

export default Admin
