import styled from "styled-components";

export const ContentEditorsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  min-height: 300px;
  gap: 10px;
  width: 100%;
  max-width: 1200px;
  background: #ffffff;
  box-shadow: inset 0px -1px 0px #f0f0f0;
  border-radius: 4px;
  border: 1px solid #f0f0f0;
`;

export const EditorCard = styled.div`
  display: flex;
  position: relative;
  min-height: 650px;
  flex-direction: column;
  width: 100%;
  max-width: 1200px;
  padding: 16px 16px 8px;
  gap: 10px;
  background: #ffffff;
  border-radius: 16px 16px 0px 0px;
`;

export const AddEditorButton = styled.button`
  width: 100%;
  margin-top: 20px;
  padding: 8px 16px;
  height: 30px;
  font-family: "Nunito";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #32a6d5;
  background: #ffffff;
  border: 1px solid #f0f0f0;
  box-shadow: inset 0px -1px 0px #f0f0f0;
  border-radius: 4px;
  display: flex;
  justify-content: flex-start;
`;

export const LeftContainer = styled.div`
  display: flex;
  border-radius: 16px;
  flex-direction: column;
  width: 100%;
  background: #ffffff;
  gap: 20px;
`;
