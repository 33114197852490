import BottomBiggerCard from "../../../Cards/BottomBiggerCard";
import Booking from "./Booking";

const BookingsContainer = () => {
  return (
    <BottomBiggerCard title={"Bookings"} overflow={true}>
      <Booking/>
    </BottomBiggerCard>
  );
};

export default BookingsContainer;
