import styled from "styled-components";

export const LabelItem = styled.span`
  display: flex;
  font-family: Nunito;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #3b3558;
`;
export const ValueItem = styled.span`
  text-align: right;
  font-family: Nunito;
  font-size: 14px;
  line-height: 18px;
  font-weight: 400;
  color: #b0b9c6;
`;
