import * as Yup from "yup";

const ValidationUserEditSchema = Yup.object().shape({
    handle: Yup.string().min(1, "Too Short").max(30,"Too Long").notRequired(),
    firstName: Yup.string().min(2, "Too Short").max(35,"Too Long").notRequired(),
    lastName: Yup.string().min(2, "Too Short").max(20,"Too Long").notRequired(),
    company: Yup.string().min(2, "Too Short").notRequired().max(45,"Too Long").notRequired(),
    address:Yup.object().shape({
        city: Yup.string().nullable().max(50,"Too Long").notRequired(),
        number: Yup.string().nullable().max(50,"Too Long").notRequired(),
        street: Yup.string().nullable().max(50,"Too Long").notRequired(),
        postCode: Yup.string().nullable().max(50,"Too Long").notRequired(),
        locality:Yup.string().nullable().max(50,"Too Long").notRequired(),
    }),
    phone: Yup.string().notRequired(),
    email: Yup.string().email("Please enter email format").notRequired(),
    dateOfBirth: Yup.string().notRequired()
});

export default ValidationUserEditSchema;
