import { Layout } from 'antd';
import { Content, Header, Nav } from '../../layout';
import { RightSection } from '../Users/styles';
import TablePromoCodes from '../../components/TablePromoCodes';

const PromoCodes = () => {
  return (
    <Layout>
      <Nav />
      <RightSection>
        <Header />
        <Content>
          <TablePromoCodes />
        </Content>
      </RightSection>
    </Layout>
  );
};

export default PromoCodes;
