import { Card } from "antd";
import styled from "styled-components";

export const CardItem: typeof Card = styled(Card)`
  display: flex;
  min-height:600px;
  flex: 0 1 33%;
  overflow: hidden;
  width:100%;
  flex-direction: column;
  border-radius: 15px;
  background: #ffffff;
`;
export const CardItemWithOverflow: typeof Card = styled(Card)`
  display: flex;
  max-height:600px;
  flex: 0 1 33%;
  overflow-x: hidden;
  overflow-y: scroll;
  width:100%;
  flex-direction: column;
  border-radius: 15px;
  background: #ffffff;
`;

export const CardTitle = styled.div`
  display: flex;
  font-family: Nunito;
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
`;
