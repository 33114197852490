const link = process.env.REACT_APP_SERVER_API_V2

export const getChargerDetailsUrl = (id:number | null) => {
    if (!id) {
        return
    }
    return (
        `${link}/charger/${id}`
    )
}
