import Axios from '../../serverConfig';

export const Image = {
  getImageUrl: async (filename: string) => {
    const response = await Axios({
      method: 'POST',
      url: `${process.env.REACT_APP_SERVER_API_V2}/fetch/image/${filename}`,
    });

    return response.data.content;
  },
};
