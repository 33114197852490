const getProgressPercent = (
  sum: number,
  rate_1: number,
  rate_2: number,
  rate_3: number,
  rate_4: number,
  rate_5: number
) => {
  const sumRate1 = rate_1 * 1;
  const sumRate2 = rate_2 * 2;
  const sumRate3 = rate_3 * 3;
  const sumRate4 = rate_4 * 4;
  const sumRate5 = rate_5 * 5;
  const percentProgressBar_1 = Math.round((sumRate1 * 100) / sum);
  const percentProgressBar_2 = Math.round((sumRate2 * 100) / sum);
  const percentProgressBar_3 = Math.round((sumRate3 * 100) / sum);
  const percentProgressBar_4 = Math.round ((sumRate4 * 100) / sum);
  const percentProgressBar_5 = Math.round((sumRate5 * 100) / sum);
  return {
    percentProgressBar_1,
    percentProgressBar_2,
    percentProgressBar_3,
    percentProgressBar_4,
    percentProgressBar_5,
  };
};

export default getProgressPercent;
