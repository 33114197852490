import React, { useRef, useState } from "react";
import { Checkbox, Form, message, Modal, TimePicker } from "antd";
import { CheckboxChangeEvent } from "antd/es/checkbox";
import { Formik } from "formik";
import moment from "moment";
import { DividerModal } from "../../Divider";
import { IDays } from "../../../types/IDays";
import {
  IAvailability,
  IChargerDetails,
} from "../../../types/rest/charger/IChargerDetails";
import { getEditCharger } from "../../../consts/getEditCharger";
import Axios from "../../../serverConfig";
import { ButtonSubmit, ButtonsWrapper, CancelButton } from "../styles";
import { Col, Days, Row, Wrapper } from "../EditAvailabilityInformation/styles";

interface IProps {
  toggleOpenEditor: boolean;
  handleToggle: () => void;
  details: IChargerDetails;
  setDetails: React.Dispatch<React.SetStateAction<IChargerDetails | undefined>>;
}

const NewAvailability: React.FC<IProps> = ({
  toggleOpenEditor,
  handleToggle,
  details,
  setDetails,
}) => {
  const errorMessage = () => message.error("Sorry something going wrong!");
  const successEditMessage = () =>
    message.success("Success! Charger availability changed");

  const [time, setTime] = useState({
    startTime: new Date().toString(),
    endTime: new Date().toString(),
  });

  const [newDaysAvailability, setNewDaysAvailability] = useState<IDays>({
    monday: false,
    tuesday: false,
    wednesday: false,
    thursday: false,
    friday: false,
    saturday: false,
    sunday: false,
  });

  const initialValues: IAvailability = {
    ...newDaysAvailability,
    ...time,
  };

  const onChange = (e: CheckboxChangeEvent) => {
    if (e) {
      let lowerString = e.target.name?.toLowerCase();
      setNewDaysAvailability((prevState) => ({
        ...prevState,
        [lowerString as string]: e.target.checked,
      }));
    }
  };

  const formikRef = useRef<any>();

  const handleFormikClick = () => {
    if (formikRef?.current !== null) {
      formikRef.current.handleSubmit();
    }
  };

  const onChangeTime = (values: any, formatString: [string, string]) => {
    setTime({
      startTime: values[0]._d,
      endTime: values[1]._d,
    });
  };

  const handleSubmitForm = async (values: IAvailability) => {
    if (!values) {
      return;
    }

    const isMinimumOneDayAvailability = Object.values(newDaysAvailability).find((item)=>item)

    if(!isMinimumOneDayAvailability){
      alert("You must choose one day minimum")
      return
    }

    const newAvailability = [
      {
        startTime: moment(time.startTime).format("YYYY-MM-DD HH:mm"),
        endTime: moment(time.endTime).format("YYYY-MM-DD HH:mm"),
        ...newDaysAvailability,
      },
    ];

    const { chargerAvailability, ratings,  ...rest } = details;

    const newListDays = [...chargerAvailability, ...newAvailability];

    try {
      const body: IChargerDetails = {
        chargerAvailability: newListDays,
        ...rest,
      };
      setDetails(body);
      await Axios.post(getEditCharger(), body);
      successEditMessage();
      handleToggle();
    } catch (e) {
      errorMessage();
    }
  };

  return (
    <Modal
      mask={true}
      maskStyle={{ backdropFilter: "blur(8px)" }}
      title="New Availability Information "
      centered
      visible={toggleOpenEditor}
      onOk={handleFormikClick}
      onCancel={handleToggle}
    >
      <Formik
        innerRef={formikRef}
        initialValues={initialValues!}
        onSubmit={handleSubmitForm}
      >
        {({ handleSubmit, values, handleChange, errors, touched }) => (
          <Form
            onFinish={handleSubmit}
            name="basic"
            layout="vertical"
            autoComplete="off"
          >
            <Form.Item>
              <Wrapper>
                <Row>
                  <Days>Days</Days>
                  <Col>
                    {Object.entries(newDaysAvailability).map(
                      ([day, value], index) => {
                        return (
                          <div>
                            <Checkbox
                              name={day}
                              onChange={onChange}
                              defaultChecked={value}
                            >
                              {day[0].toUpperCase() + day.substring(1)}
                            </Checkbox>
                          </div>
                        );
                      }
                    )}
                  </Col>
                </Row>
              </Wrapper>
            </Form.Item>
            <DividerModal bottom={"15px"} top={"15px"} />
            <Form.Item>
              <Row>
                <Days>Time</Days>
                <TimePicker.RangePicker
                  defaultValue={[moment(time.startTime), moment(time.endTime)]}
                  showSecond={false}
                  onChange={onChangeTime}
                />
              </Row>
            </Form.Item>
            <ButtonsWrapper>
              <CancelButton onClick={handleToggle}>Cancel</CancelButton>
              <ButtonSubmit htmlType="submit">Save</ButtonSubmit>
            </ButtonsWrapper>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};
export default NewAvailability;
