import React from "react";
import { Dropdown } from "antd";
import { Link, useLocation, useParams } from "react-router-dom";
import { NAVIGATION } from "../../enums/navigation";
import { AdminIcon, ArrowLeft } from "../../icons";
import { ButtonBack, HeaderWrapper, HeaderWrapperFlexEnd } from "./styles";
import MenuItems from "./MenuItems";

const Header = () => {
  const params = useParams();
  const { search } = useLocation();

  if (params.userId) {
    return (
      <HeaderWrapper>
        <Link to={`${NAVIGATION.USERS}/${search}`}>
          <ButtonBack icon={<ArrowLeft width={30} height={40} />}>
            Back
          </ButtonBack>
        </Link>
        <Dropdown overlay={<MenuItems />} placement="top">
          <AdminIcon />
        </Dropdown>
      </HeaderWrapper>
    );
  }
  return (
    <HeaderWrapperFlexEnd>
      <Dropdown overlay={<MenuItems />} placement="top">
        <AdminIcon />
      </Dropdown>
    </HeaderWrapperFlexEnd>
  );
};

export default Header;
