import React from "react";
import { useTranslation } from "react-i18next";
import { Form, message, Modal, Switch } from "antd";
import { Formik, FormikValues } from "formik";
import ValidationEditLocationInformation from "../../../validationSchema/ValidationEditLocationInformation";
import { ILocationInformation } from "../../../types/ILocationInformation";
import Axios from "../../../serverConfig";
import { IChargerDetails } from "../../../types/rest/charger/IChargerDetails";
import { getEditCharger } from "../../../consts/getEditCharger";
import { TextInputRow } from "../../index";
import { DividerModal } from "../../Divider";
import {
  ButtonSubmit,
  ButtonsWrapper,
  CancelButton,
  Label,
  Row,
} from "../styles";

interface IProps {
  details: IChargerDetails;
  setDetails: React.Dispatch<React.SetStateAction<IChargerDetails | undefined>>;
  toggleOpenEditor: boolean;
  handleToggle: () => void;
}

const EditLocationInformation: React.FC<IProps> = ({
  toggleOpenEditor,
  handleToggle,
  details,
  setDetails,
}) => {
  const { t } = useTranslation();

  const initialValues: ILocationInformation = {
    ...details,
  };

  const errorMessage = () => message.error("Sorry something going wrong!");
  const successEditMessage = () =>
    message.success("Success.Charger location information changed");

  const handleSubmitForm = async (values: FormikValues) => {
    if (!values) {
      return;
    }
    try {
      const { ratings , ...rest } = values
      const body: IChargerDetails = rest as IChargerDetails;
      await Axios.post(getEditCharger(), body);
      successEditMessage();
      setDetails(body);
      handleToggle();
    } catch (e: unknown) {
      errorMessage();
    }
  };

  return (
    <Modal
      mask={true}
      maskStyle={{ backdropFilter: "blur(8px)" }}
      title="Change location information"
      centered
      visible={toggleOpenEditor}
      onOk={handleSubmitForm}
      onCancel={handleToggle}
    >
      <Formik
        initialValues={initialValues}
        validationSchema={ValidationEditLocationInformation}
        onSubmit={handleSubmitForm}
      >
        {({
          handleSubmit,
          values,
          handleChange,
          errors,
          touched,
          setFieldValue,
        }) => (
          <Form
            onFinish={handleSubmit}
            name="basic"
            layout="vertical"
            autoComplete="off"
          >
            <Form.Item>
              <TextInputRow
                labelText={t("name")}
                name={"name"}
                value={values.name}
                handleChange={handleChange}
                errors={errors.name}
                touched={touched.name}
                disabled={true}
              />
            </Form.Item>
            <DividerModal bottom={"15px"} top={"15px"} />
            <Form.Item>
              <TextInputRow
                  labelText={"Building Number/Name"}
                  name={"address.number"}
                  value={values.address.number}
                  handleChange={handleChange}
                  errors={errors.address?.number}
                  touched={touched.address?.number}
              />
            </Form.Item>
            <Form.Item>
              <TextInputRow
                  labelText={"Street address"}
                  name={"address.street"}
                  value={values.address.street}
                  handleChange={handleChange}
                  errors={errors.address?.street}
                  touched={touched.address?.street}
              />
            </Form.Item>
            <Form.Item>
              <TextInputRow
                  labelText={"Locality"}
                  name={"address.locality"}
                  value={values.address.locality}
                  handleChange={handleChange}
                  errors={errors.address?.locality}
                  touched={touched.address?.locality}
              />
            </Form.Item>
            <Form.Item>
              <TextInputRow
                labelText={t("City")}
                name={"address.city"}
                value={values.address.city}
                handleChange={handleChange}
                errors={errors.address?.city}
                touched={touched.address?.city}
              />
            </Form.Item>
            <Form.Item>
              <TextInputRow
                labelText={"Post code"}
                name={"address.postCode"}
                value={values.address.postCode}
                handleChange={handleChange}
                errors={errors.address?.postCode}
                touched={touched.address?.postCode}
              />
            </Form.Item>
            <DividerModal bottom={"15px"} top={"15px"} />
            <Form.Item name={"attendedCharging"}>
              <Row>
                <Label>{t("attendedCharging")}</Label>
                <Switch
                  defaultChecked={values.attendedCharging}
                  onChange={(value: boolean) =>
                    setFieldValue("attendedCharging", value)
                  }
                />
              </Row>
            </Form.Item>
            <DividerModal bottom={"15px"} top={"5px"} />
            <Form.Item name={"coveredParking"}>
              <Row>
                <Label>{t("coveredParking")}</Label>
                <Switch
                  defaultChecked={values.coveredParking}
                  onChange={(value: boolean) =>
                    setFieldValue("coveredParking", value)
                  }
                />
              </Row>
            </Form.Item>
            <Form.Item name={"cctv"}>
              <Row>
                <Label>{t("cctv")}</Label>
                <Switch
                  defaultChecked={values.cctv}
                  onChange={(value: boolean) => setFieldValue("cctv", value)}
                />
              </Row>
            </Form.Item>
            <Form.Item name={"wifi"}>
              <Row>
                <Label>{t("wifi")}</Label>
                <Switch
                  defaultChecked={values.wifi}
                  onChange={(value: boolean) => setFieldValue("wifi", value)}
                />
              </Row>
            </Form.Item>
            <Form.Item name={"lighting"}>
              <Row>
                <Label>{t("lighting")}</Label>
                <Switch
                  defaultChecked={values.lighting}
                  onChange={(value: boolean) =>
                    setFieldValue("lighting", value)
                  }
                />
              </Row>
            </Form.Item>
            <Form.Item name={"disabledAccess"}>
              <Row>
                <Label>{t("disabledAccess")}</Label>
                <Switch
                  defaultChecked={values.disabledAccess}
                  onChange={(value: boolean) =>
                    setFieldValue("disabledAccess", value)
                  }
                />
              </Row>
            </Form.Item>
            <DividerModal bottom={"15px"} top={"15px"} />
            <ButtonsWrapper>
              <CancelButton onClick={handleToggle}>Cancel</CancelButton>
              <ButtonSubmit htmlType="submit">Save</ButtonSubmit>
            </ButtonsWrapper>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default EditLocationInformation;
