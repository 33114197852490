import { Layout } from "antd";
import {useContext, useEffect} from "react";
import TableUsers from "../../components/TableUsers";
import { Content, Header, Nav } from "../../layout";
import TabsContext from "../../context/tabsContext";
import { RightSection } from "./styles";

const Users = () => {
    const {handleId , handleChannelId} = useContext(TabsContext)

    useEffect(()=>{
        handleId(0)
        handleChannelId("")
    },[])

  return (
    <Layout>
      <Nav />
      <RightSection>
        <Header />
        <Content>
          <TableUsers  />
        </Content>
      </RightSection>
    </Layout>
  );
};

export default Users;
