import {Layout} from "antd";
import styled from "styled-components";

export const { Content } = Layout;

const ContentWrapper: typeof Content = styled(Content)`
  height:100vh;
  overflow-y: auto;
`

export default ContentWrapper;
