import React from "react";
import {IMultiContent, IMultiItems} from "../../../../types/rest/editor/IMultiContent";
import { DividerWithNoMargin } from "../../../Divider";
import { Container, Head, ModalComponent, Title } from "../styles";

interface IProps {
  data: IMultiItems[];
  handleIsOpen: () => void;
  isOpenPreview: boolean;
}

const PreviewFaq: React.FC<IProps> = ({
  data,
  handleIsOpen,
  isOpenPreview,
}) => {
  return (
    <ModalComponent
      mask={true}
      maskStyle={{ backdropFilter: "blur(8px)" }}
      visible={isOpenPreview}
      onOk={handleIsOpen}
      onCancel={handleIsOpen}
    >
      <Container>
        <Head>
          <Title> FAQ</Title>
        </Head>
          {data.map((item)=>{
            return (
                <>
                  <h1>{item.title}</h1>
                  <div dangerouslySetInnerHTML={{ __html: item.markdown }} />
                  <DividerWithNoMargin top={"6px"} bottom={"6px"} />
                </>
            );
          })}

        <DividerWithNoMargin top={"6px"} bottom={"6px"} />
      </Container>
    </ModalComponent>
  );
};

export default PreviewFaq;
