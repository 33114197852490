import React from "react";

const Success = (props: React.SVGProps<SVGSVGElement>) => {
    return (
        <svg width="217" height="77" viewBox="0 0 217 77" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M51.4972 53.2376L52.6695 53.2371C55.2215 53.2362 57.2841 55.3174 57.2602 57.8694V57.8694C57.2387 60.1667 58.9194 62.126 61.1933 62.4544L61.5214 62.5018C63.9658 62.8549 65.7798 64.9497 65.7798 67.4194L65.7798 68.9982" stroke="#32A6D5" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M145.997 32.5001L144.924 32.915C142.613 33.8089 141.284 36.2401 141.778 38.6684V38.6684C142.244 40.9525 141.096 43.2603 138.993 44.2663L138.846 44.3365C136.657 45.3838 135.461 47.7864 135.946 50.1642V50.1642C136.43 52.542 135.235 54.9446 133.046 55.9918L132.167 56.4121C130.385 57.2645 129.137 58.9392 128.83 60.8904L128.497 63.0001" stroke="#32A6D5" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M70.6113 20.7606C82.1642 25.8479 97.0179 42.8054 98.6683 64.8501" stroke="#32A6D5" strokeWidth="2" strokeLinecap="round"/>
            <path d="M174.824 54.8043C168.172 55.7753 158.602 61.7331 155.571 71.5096" stroke="#32A6D5" strokeWidth="2" strokeLinecap="round"/>
            <circle cx="193.785" cy="72.0001" r="3" stroke="#32A6D5" strokeWidth="2" strokeLinecap="round"/>
            <circle cx="156.785" cy="50.0001" r="1" fill="#32A6D5" stroke="#32A6D5" strokeWidth="2" strokeLinecap="round"/>
            <circle cx="68.2849" cy="2.50012" r="1.5" fill="#32A6D5" stroke="#32A6D5" strokeWidth="2" strokeLinecap="round"/>
            <circle cx="30.5292" cy="71.6558" r="4.34418" stroke="#32A6D5" strokeWidth="2" strokeLinecap="round"/>
            <path d="M39.5582 24.3288L51.388 22.3855L47.156 33.602L39.5582 24.3288Z" stroke="#32A6D5" strokeWidth="2" strokeLinecap="round"/>
            <path d="M115.616 34.1893L122.755 43.8201L110.845 45.1874L115.616 34.1893Z" stroke="#32A6D5" strokeWidth="2" strokeLinecap="round"/>
            <path d="M128.618 21.17L128.826 23.3345L126.847 22.4328L128.618 21.17Z" fill="#32A6D5" stroke="#32A6D5" strokeWidth="2" strokeLinecap="round"/>
            <path d="M4.90101 66.7882L8.43983 67.9013L5.70645 70.4095L4.90101 66.7882Z" fill="#32A6D5" stroke="#32A6D5" strokeWidth="2" strokeLinecap="round"/>
            <path d="M151.847 14.7162L152.408 14.9498L152.874 14.5582L155.956 11.9669L155.595 15.9438L155.539 16.5583L156.064 16.8837L159.449 18.9857L155.522 19.9275L154.935 20.0682L154.786 20.6527L153.787 24.5661L151.735 21.1507L151.417 20.6219L150.802 20.6687L146.82 20.9719L149.455 17.928L149.854 17.468L149.628 16.9029L148.141 13.1755L151.847 14.7162Z" stroke="#32A6D5" strokeWidth="2"/>
            <path d="M22.9276 41.4722L23.1684 42.023L23.7678 42.0687L27.7058 42.369L24.7443 44.7824L24.2514 45.184L24.406 45.8008L25.3276 49.4762L21.8672 47.4654L21.3647 47.1735L20.8623 47.4654L17.4019 49.4762L18.3235 45.8008L18.4781 45.184L17.9852 44.7824L15.0237 42.369L18.9617 42.0687L19.5611 42.023L19.8019 41.4722L21.3647 37.8975L22.9276 41.4722ZM25.4147 49.8233C25.4146 49.8232 25.4146 49.8231 25.4146 49.823L25.4147 49.8233Z" stroke="#32A6D5" strokeWidth="2"/>
            <path d="M73.1783 43.7037L73.4203 44.2519L74.0178 44.2971L74.8969 44.3635L74.2737 44.8665L73.7751 45.2689L73.9323 45.8901L74.1428 46.7214L73.2837 46.227L72.7849 45.94L72.2861 46.227L71.427 46.7214L71.6375 45.8901L71.7948 45.2689L71.2962 44.8665L70.673 44.3635L71.552 44.2971L72.1495 44.2519L72.3915 43.7037L72.7849 42.8125L73.1783 43.7037Z" fill="#32A6D5" stroke="#32A6D5" strokeWidth="2"/>
            <path d="M213.339 56.8695L213.374 56.5492L213.577 56.7996L213.89 56.7339L213.716 57.0023L213.876 57.281L213.565 57.1978L213.349 57.436L213.332 57.1152L213.04 56.9842L213.339 56.8695Z" fill="#32A6D5" stroke="#32A6D5" strokeWidth="2"/>
            <path d="M193.243 29.9509L195.808 34.3942" stroke="#32A6D5" strokeWidth="2" strokeLinecap="round"/>
            <path d="M190.426 40.464L194.869 37.8986" stroke="#32A6D5" strokeWidth="2" strokeLinecap="round"/>
            <path d="M198.374 38.8377L200.939 43.281" stroke="#32A6D5" strokeWidth="2" strokeLinecap="round"/>
            <path d="M199.313 35.3331L203.756 32.7677" stroke="#32A6D5" strokeWidth="2" strokeLinecap="round"/>
            <path d="M98.4497 10.9241L102.399 11.5592" stroke="#32A6D5" strokeWidth="2" strokeLinecap="round"/>
            <path d="M103.421 17.8007L104.056 13.8514" stroke="#32A6D5" strokeWidth="2" strokeLinecap="round"/>
            <path d="M106.348 12.1943L110.298 12.8294" stroke="#32A6D5" strokeWidth="2" strokeLinecap="round"/>
            <path d="M104.691 9.90216L105.326 5.9529" stroke="#32A6D5" strokeWidth="2" strokeLinecap="round"/>
        </svg>
    )
}

export default Success
