interface IDays {
  monday: boolean;
  tuesday: boolean;
  wednesday: boolean;
  thursday: boolean;
  friday: boolean;
  saturday: boolean;
  sunday: boolean;
}

export const convertDays = (days: IDays) => {
  let savedMinMax = [];

  let minMax = [];

  for (let [day, value] of Object.entries(days)) {
    if (value) {
      minMax.push(`${day[0].toUpperCase()}${day[1]}${day[2]}`);
    }
    if (!value) {
      minMax = [];
    }
    if (minMax.length === 1) {
      savedMinMax.push(minMax);
    }
  }

  return savedMinMax;
};

export const convertArrayToDaysLimit = (days: string[]) => {
  if (days.length === 1) {
    return `${days[0]} `;
  }
  if (days.length > 1) {
    return `${days[0]}-${days[days.length - 1]}  `;
  }
};

export default convertDays;
