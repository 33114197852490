import React from "react";
import { LineDivider } from "../styles";

interface IProps {
  top: string;
  bottom: string;
  color?: string;
}

const Divider: React.FC<IProps> = ({ top, bottom, color }) => {
  return <LineDivider top={top} bottom={bottom} color={color} />;
};

export default Divider;
