import styled from "styled-components";
import {Layout} from "antd";

const {Sider} = Layout;

export const NavContainer: typeof Sider = styled(Sider)`
  min-height: 100vh;
  background-color: #FFFFFF;
`

export const NavHeader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

export const LogoContainer = styled.div`
  padding: 1.875rem 1.25rem 1.875rem 1.25rem;
`