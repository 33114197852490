import styled from "styled-components";
import { Modal } from "antd";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px;
`;

export const ModalComponent = styled(Modal)`
  padding: 5px;

  .ant-modal-content {
    background-color: transparent;
    box-shadow: none;
  }
  .ant-modal-content {
    background-color: transparent;
    box-shadow:none;
  }

  .ant-modal-body {
    border:8px black solid;
    border-radius:30px;
    background: #E5E5E5;
  }
  
`;

export const Head = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  padding: 20px;
  justify-content: center;
  text-align: center;
  border-bottom: 0.5px solid #b0b9c6;
`;

export const Title = styled.span`
  font-family: "Nunito";
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 26px;
  color: #3b3558;
`;

export const ArrowSpan = styled.span`
  position: absolute;
  color: #32a6d5;
  left: -10px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const CardImage = styled.div`
  display: flex;
  height: 128px;
  border-radius: 8px;
  background: #ffffff;
  width: 100%;
`;

export const List = styled.ul`
  font-family: "Nunito";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  color: #3b3558;
`;

export const ListItem = styled.li`
  font-family: "Nunito";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #3b3558;
`;
