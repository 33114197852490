import React from "react";
import renderStars from "../../../../helpers/createRateStar";
import getProgressPercent from "../../../../helpers/getProgressPercent";
import handleRoundPrice from "../../../../helpers/handleRoundPrice";
import { DividerWithMargin } from "../../../Divider";
import { ProgressBar } from "../../../index";
import {
  RateContainer,
  RateStars,
  RateLeftSide,
  RateRightSide,
  Rates, RateAmount, RateAmountInteger,
} from "./styles";

interface IProps {
  rate_count: number;
  rate: number;
  rate_1: number;
  rate_2: number;
  rate_3: number;
  rate_4: number;
  rate_5: number;
}

const RateCard: React.FC<IProps> = ({
  rate_count,
  rate,
  rate_1,
  rate_2,
  rate_3,
  rate_4,
  rate_5,
}) => {
  const percents = getProgressPercent(
    rate_count,
    rate_1,
    rate_2,
    rate_3,
    rate_4,
    rate_5
  );

  return (
    <>
      <RateContainer>
        <RateLeftSide>
          {Number.isInteger(rate) ?  <RateAmountInteger>{handleRoundPrice(rate.toString())}</RateAmountInteger> : <RateAmount>{handleRoundPrice(rate.toString())}</RateAmount>}
          <RateStars>{renderStars(rate)}</RateStars>
          <Rates>{rate_count} Rates</Rates>
        </RateLeftSide>
        <RateRightSide>
          <ProgressBar percent={percents.percentProgressBar_5} count={rate_5} />
          <ProgressBar percent={percents.percentProgressBar_4} count={rate_4} />
          <ProgressBar percent={percents.percentProgressBar_3} count={rate_3} />
          <ProgressBar percent={percents.percentProgressBar_2} count={rate_2} />
          <ProgressBar percent={percents.percentProgressBar_1} count={rate_1} />
        </RateRightSide>
      </RateContainer>
      <DividerWithMargin top={"12px"} bottom={"2px"} />
    </>
  );
};
export default RateCard;
