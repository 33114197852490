import { Menu } from "antd";
import Cookies from "universal-cookie";
import { useNavigate } from "react-router-dom";
import { NAVIGATION } from "../../../enums/navigation";

const MenuItems = () => {
  const cookies = new Cookies();
  const navigate = useNavigate();

  const handleLogOut = async () => {
    await cookies.remove("access_token", { path: "/" });
    navigate(NAVIGATION.LOGIN);
  };

  return (
    <Menu>
      <Menu.Item key="1" onClick={handleLogOut} style={{width:"100px",textAlign:"center"}}>
        Log Out
      </Menu.Item>
    </Menu>
  );
};

export default MenuItems;
