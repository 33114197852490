import { CallendarItemsWrapper } from "./styles";
import VehicleChargingContainer from "./VehicleChargingCard";
import BookingsContainer from "./BookingsCard";
import CalendarContainer from "./CalendarCard";

const CalendarItemsWrapper = () => {
  return (
    <CallendarItemsWrapper>
      <CalendarContainer />
      <BookingsContainer />
      <VehicleChargingContainer />
    </CallendarItemsWrapper>
  );
};

export default CalendarItemsWrapper;
