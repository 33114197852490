import React from "react";

const Edit = (props: React.SVGProps<SVGSVGElement>) => {
    return (<svg width="19" height="20" viewBox="0 0 19 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M8.8809 1.39893H5.45851C2.64389 1.39893 0.87915 3.39158 0.87915 6.2126V13.8226C0.87915 16.6436 2.63565 18.6363 5.45851 18.6363H13.5353C16.3591 18.6363 18.1156 16.6436 18.1156 13.8226V10.1357"
                stroke="#3B3558" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M6.44205 8.84229L13.2823 2.00208C14.1344 1.15084 15.5156 1.15084 16.3678 2.00208L17.4818 3.11603C18.3339 3.9682 18.3339 5.35033 17.4818 6.20158L10.6086 13.0747C10.2361 13.4473 9.7308 13.6569 9.20358 13.6569H5.77478L5.86082 10.197C5.87364 9.68804 6.08141 9.20292 6.44205 8.84229Z"
                  stroke="#3B3558" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M12.2428 3.05896L16.4222 7.23832" stroke="#3B3558" strokeWidth="1.5" strokeLinecap="round"
                  strokeLinejoin="round"/>
        </svg>
    )
}

export default Edit
