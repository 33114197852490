import React from "react";

const ArrowInbox = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="7"
      height="12"
      viewBox="0 0 7 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M1 1.29626L6 6.29626L1 11.2963" stroke="#908E9B" />
    </svg>
  );
};

export default ArrowInbox;
