import React from "react";

const UnBlock = (props: React.SVGProps<SVGSVGElement>) => {
    return (<svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="9.50366" cy="9.27161" r="8.25" stroke="#8BD033" strokeWidth="1.5"/>
            <path d="M4.91261 9.27148L8.28446 12.6434" stroke="#8BD033" strokeWidth="1.5" strokeLinecap="round"/>
            <path d="M14.0947 6.83327L8.2845 12.6434" stroke="#8BD033" strokeWidth="1.5" strokeLinecap="round"/>
        </svg>
    )
}

export default UnBlock
