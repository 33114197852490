import { useParams } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import useSWR from "swr";
import { Badge, Calendar, Col, Row, Select } from "antd";
import moment, { Moment } from "moment";
import { CalendarMode } from "antd/es/calendar/generateCalendar";
import { IBookingInformation } from "../../../../../types/rest/booking/IBookingInformation";
import getBookingDetails from "../../../../../consts/getBookingDetails";
import TabsContext from "../../../../../context/tabsContext";
import { Wrapper } from "./styles";
import "./style.css";
import {ICalendar} from "../../../../../types/rest/calendar/ICalendar";

const CalendarComponent = () => {
  const params = useParams();
  const { userId } = params;
  const { data, isValidating } = useSWR(getBookingDetails(userId));

  const { date, setDate } = useContext(TabsContext);

  const [bookingInfo, setBookingInfo] = useState<IBookingInformation[]>([]);

  const [datesList, setDatesList] = useState<string[]>([]);

  useEffect(() => {
    if (!data) {
      return;
    }
    Object.values(data).map((item:any) => {
      item.forEach((val:ICalendar) => {
        setDatesList((prevState) => [...prevState, val.day]);
        setBookingInfo((prevState) => [...prevState, JSON.parse(val.name)]);
      });
    });
  }, [isValidating,data]);


  if (isValidating) {
    return <p>Loading</p>;
  }

  if (!data) {
    return <p>empty</p>;
  }

  if (!Object.keys(data).length) {
    <p>Something going wrong</p>;
  }

  const dateCellRender = (value: any) => {
    const stringValue = value.format("YYYY-MM-DD");

    const listData = datesList.filter(
      (item: any) => moment(item).format("YYYY-MM-DD") === stringValue
    );

    const slicedLists = listData.slice(0,4)

    return (
      <ul className="events">
        {slicedLists.map((item: any, index: number) => (
          <li key={item + index}>
            <Badge status={"success"} />
          </li>
        ))}
      </ul>
    );
  };


  return (
    <Wrapper>
      <Calendar
        fullscreen={false}
        headerRender={({ value, type, onChange, onTypeChange }) => {
          const start = 0;
          const end = 12;
          const monthOptions = [];

          const current = value.clone();
          const localeData = value.localeData();
          const months = [];
          for (let i = 0; i < 12; i++) {
            current.month(i);
            months.push(localeData.monthsShort(current));
          }

          for (let i = start; i < end; i++) {
            monthOptions.push(
              <Select.Option key={i} value={i} className="month-item">
                {months[i]}
              </Select.Option>
            );
          }

          const year = value.year();
          const month = value.month();
          const options = [];
          for (let i = year - 10; i < year + 10; i += 1) {
            options.push(
              <Select.Option key={i} value={i} className="year-item">
                {i}
              </Select.Option>
            );
          }
          return (
            <div style={{ padding: 8 }}>
              <Row
                gutter={8}
                style={{ display: "flex", justifyContent: "flex-end" }}
              >
                <Col>
                  <Select
                    size="small"
                    dropdownMatchSelectWidth={false}
                    className="my-year-select"
                    value={year}
                    onChange={(newYear) => {
                      const now = value.clone().year(newYear);
                      onChange(now);
                    }}
                  >
                    {options}
                  </Select>
                </Col>
                <Col>
                  <Select
                    size="small"
                    dropdownMatchSelectWidth={false}
                    value={month}
                    onChange={(newMonth) => {
                      const now = value.clone().month(newMonth);
                      onChange(now);
                    }}
                  >
                    {monthOptions}
                  </Select>
                </Col>
              </Row>
            </div>
          );
        }}
        onChange={(e) => setDate(e.format())}
        dateCellRender={dateCellRender}
      />
    </Wrapper>
  );
};

export default CalendarComponent;
