import styled from "styled-components";

export const VehiclesWrapper = styled.div`
  height:300px;
  flex-grow:1;
  overflow-x: hidden;
  overflow-y: auto;
  display:flex;
  flex-direction: column;
`
