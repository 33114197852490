import React, { useRef, useState } from "react";
import { Formik} from "formik";
import {useTranslation} from "react-i18next";
import {Form, message, Modal, Radio, Space, Switch} from "antd";
import ValidationEditParkingInformation from "../../../validationSchema/ValidationEditParkingInformation";
import {IChargerDetails} from "../../../types/rest/charger/IChargerDetails";
import Axios from "../../../serverConfig";
import {getEditCharger} from "../../../consts/getEditCharger";
import { DividerModal } from "../../Divider";
import { InputField } from "../../TextInput/TextInputRow/styles";
import { TextInputRow } from "../../index";
import { ButtonSubmit, ButtonsWrapper, CancelButton } from "../styles";
import {
  Col,
  ContainerPadding,
  DisabledContainer, ErrorContainer,
  Label,
  Row,
  ShortLabel,
  Wrapper,
} from "./styles";

interface IProps {
  handleToggle: () => void;
  toggleOpenEditor: boolean;
  parkingInfo: IChargerDetails;
  setParkingInfo:  React.Dispatch<React.SetStateAction<IChargerDetails | undefined>>
}

const EditParkingInformation: React.FC<IProps> = ({
  handleToggle,
  toggleOpenEditor,
  parkingInfo,
  setParkingInfo,
}) => {
  const { t } = useTranslation();

  const errorMessage = () => message.error("Sorry something going wrong!")
  const successEditMessage = () => message.success("Success.Charger parking information changed")

  const [heightRestricted, setHeightRestricted] = useState(
    !!parkingInfo.heightRestrict
  );
  const [valAccess, setValAccess] = useState(() => {
    if (!!parkingInfo.narrowAccess) {
      return "narrowAccess";
    }
    if (!!parkingInfo.wideAccess) {
      return "wideAccess";
    } else {
      return "";
    }
  });

  const initialValues = {
    ...parkingInfo,
  };

  const formikRef = useRef<any>();

  const handleFormikClick = () => {
    if (formikRef?.current !== null) {
      formikRef.current.handleSubmit();
    }
  };

  const handleSubmitForm = async (values: IChargerDetails) => {
    if (!values) {
      return
    }
      try{
        const { ratings, ...rest } = values

        const body:IChargerDetails = rest
        await Axios.post(getEditCharger(),body)
        successEditMessage()
        setParkingInfo(values as IChargerDetails)
        handleToggle()
      }catch (e:unknown) {
        errorMessage()
      }
  };

  return (
    <>
      <Modal
        width={600}
        mask={true}
        maskStyle={{ backdropFilter: "blur(8px)" }}
        title="Edit Parking Information"
        centered
        visible={toggleOpenEditor}
        onOk={handleFormikClick}
        onCancel={handleToggle}
      >
        <Formik
          innerRef={formikRef}
          initialValues={initialValues}
          validationSchema={ValidationEditParkingInformation}
          onSubmit={handleSubmitForm}
        >
          {({
            handleSubmit,
            values,
            handleChange,
            errors,
            touched,
            setFieldValue,
          }) => (
            <Form
              onFinish={handleSubmit}
              name="basic"
              layout="vertical"
              autoComplete="off"
            >
              <Row>
                <Label>{t("heightRestricted")}</Label>
                <Switch
                  defaultChecked={heightRestricted}
                  onChange={(value) => {
                    if (!value) {
                      setFieldValue("heightRestrict", "");
                    }
                    setHeightRestricted(value);
                  }}
                />
              </Row>
              <Form.Item>
                <Wrapper>
                  <DisabledContainer>
                    <InputField
                      addonAfter={"m"}
                      disabled={!heightRestricted}
                      name={"heightRestrict"}
                      value={values.heightRestrict}
                      onChange={handleChange}
                    />
                    {errors.heightRestrict && <ErrorContainer>{errors.heightRestrict}</ErrorContainer>}

                  </DisabledContainer>
                </Wrapper>
                <DividerModal bottom={"5px"} top={"15px"} />
              </Form.Item>
              <Form.Item>
                <Row>
                  <ShortLabel>{t("parkingSpaceWidth")}</ShortLabel>
                  <Col>
                    <Radio.Group
                      onChange={(e) => setValAccess(e.target.value)}
                      defaultValue={valAccess}
                    >
                      <Space direction="vertical">
                        <Radio
                          name={"Narrow Access"}
                          value={"narrowAccess"}
                          onClick={(value) => setFieldValue("wideAccess", "")}
                        >
                          Narrow Access
                        </Radio>
                        <Radio
                          name={"Wide Access"}
                          value={"wideAccess"}
                          onClick={(value) => setFieldValue("narrowAccess", "")}
                        >
                          Wide Access
                        </Radio>
                      </Space>
                    </Radio.Group>
                  </Col>
                </Row>
                <ContainerPadding>
                  {valAccess === "wideAccess" && (
                    <TextInputRow
                      addonAfter={"m"}
                      name={"wideAccess"}
                      value={values.wideAccess!}
                      handleChange={handleChange}
                      errors={errors.wideAccess}
                      touched={touched.wideAccess}
                    />
                  )}
                  {valAccess === "narrowAccess" && (
                    <TextInputRow
                      name={"narrowAccess"}
                      value={values.narrowAccess!}
                      handleChange={handleChange}
                      errors={errors.narrowAccess}
                      touched={touched.narrowAccess}
                    />
                  )}
                </ContainerPadding>
              </Form.Item>
              <DividerModal bottom={"20px"} top={"10px"} />
              <Form.Item name={"permitRequired"}>
                <Row>
                  <Label>{t("permitRequired")}</Label>
                  <Switch
                    defaultChecked={values.permitRequired}
                    onChange={(value: boolean) =>
                      setFieldValue("permitRequired", value)
                    }
                  />
                </Row>
              </Form.Item>
              <DividerModal bottom={"10px"} top={"0px"} />
              <Form.Item name={"keyFob"}>
                <Row>
                  <Label>{t("accessRequiredKeyFob")}</Label>
                  <Switch
                    defaultChecked={values.keyFob}
                    onChange={(value: boolean) =>
                      setFieldValue("keyFob", value)
                    }
                  />
                </Row>
              </Form.Item>
              <Form.Item name={"securityCode"}>
                <Row>
                  <Label>{t("accessRequiredSecurityCode")}</Label>
                  <Switch
                    defaultChecked={values.securityCode}
                    onChange={(value: boolean) =>
                      setFieldValue("securityCode", value)
                    }
                  />
                </Row>
              </Form.Item>
              <Form.Item name={"physicalKey"}>
                <Row>
                  <Label>{t("accessRequiredPhysicalKey")}</Label>
                  <Switch
                    defaultChecked={values.physicalKey}
                    onChange={(value: boolean) =>
                      setFieldValue("physicalKey", value)
                    }
                  />
                </Row>
              </Form.Item>
              <DividerModal bottom={"15px"} top={"15px"} />
              <Form.Item name={"gated"}>
                <Row>
                  <Label>{t("gatedEntry")}</Label>
                  <Switch
                    defaultChecked={values.gated}
                    onChange={(value: boolean) => setFieldValue("gated", value)}
                  />
                </Row>
              </Form.Item>
              <DividerModal bottom={"20px"} top={"15px"} />
              <Form.Item>
                <TextInputRow
                  labelText={t("additionalInformation")}
                  name={"locationAdditionalInformation"}
                  value={values.locationAdditionalInformation!}
                  handleChange={handleChange}
                  errors={errors.locationAdditionalInformation}
                  touched={touched.locationAdditionalInformation}
                />
              </Form.Item>
              <ButtonsWrapper>
                <CancelButton onClick={handleToggle}>Cancel</CancelButton>
                <ButtonSubmit htmlType="submit">Save</ButtonSubmit>
              </ButtonsWrapper>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  );
};

export default EditParkingInformation;
