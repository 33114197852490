import React, {useContext} from "react";
import TabsContext from "../../../../../context/tabsContext";
import ArrowInbox from "../../../../../icons/ArrowInbox";
import {
  DateOrHour,
  Handle,
  IntroText,
  Row,
  Col,
  UserName,
  Wrapper,
  HandleAndUserNameContainer,
} from "./styles";
import {CirclePhotoInbox} from "../styles";

interface IProps {
  channelId:string
  userName:string,
  userHandle:string,
  userLogo:string,
  dateMessage:string
  textMessage:string

}

const Inbox:React.FC<IProps> = ({userName,userHandle,userLogo,dateMessage,textMessage,channelId}) => {
  const {activeChannel,handleChannelId} = useContext(TabsContext)

  return (
    <Wrapper active={activeChannel === channelId ? true : false }>
      {!userLogo ? (
          <CirclePhotoInbox>{userName[0].toUpperCase()}</CirclePhotoInbox>
      ) : (
          <img
              src={`data:image/png;base64,${userLogo}`}
              width={35}
              height={35}
              style={{ borderRadius: "50%" }}
          />
      )}
      <Col>
        <Row>
          <HandleAndUserNameContainer>
            <Handle>{userHandle}</Handle>
            <UserName>{userName}</UserName>
          </HandleAndUserNameContainer>
          <DateOrHour>{dateMessage}<ArrowInbox height={15} width={10}/></DateOrHour>
        </Row>
        <IntroText>{textMessage}</IntroText>
      </Col>
    </Wrapper>
  );
};

export default Inbox;
