import React, { useContext } from "react";
import dateContext from "../../../../../../context/tabsContext";
import { DividerWithNoMargin } from "../../../../../Divider";
import handleRoundPrice from "../../../../../../helpers/handleRoundPrice";
import { BookingType } from "../../../../../../enums/bookingType";
import BookingStatus from "../../../../../../enums/bookingStatus";
import Rejected from "../../../../../../icons/Rejected";
import {
  BookingAddress,
  BookingCar,
  BookingHourEnd,
  BookingHourStart,
  BookingPrice,
  BookingRowContainer,
  BookingSumTime,
  BookingUser,
  BookItem, StatusIconContainer,
} from "./styles";
import Pending from "../../../../../../icons/Pending";

interface IProps {
  handleSelectBooking: (id: string) => void;
  uid: string;
  isHost: boolean;
  status: string;
  hourStart: string;
  hourEnd: string;
  hourTime: string;
  price: string;
  nameCharger: string;
  nameCar: string;
  modelCar: string;
  nameUser: string;
  lastName: string;
  type: BookingType;
}

const Book: React.FC<IProps> = ({
  handleSelectBooking,
  uid,
  isHost,
  status,
  hourStart,
  hourEnd,
  hourTime,
  price,
  nameCharger,
  nameCar,
  modelCar,
  nameUser,
  lastName,
  type,
}) => {
  const { bookingId } = useContext(dateContext);

  return (
    <BookItem
      isHost={type === BookingType.SHARING ? true : false}
      status={status}
      isSelected={bookingId === uid ? true : false}
      onClick={() => handleSelectBooking(uid)}
    >
      <BookingRowContainer>
        <BookingHourStart status={status} isHost={isHost}>
          {hourStart} -
        </BookingHourStart>
        <BookingHourEnd status={status} isHost={isHost}>
          {hourEnd}
        </BookingHourEnd>
        {status === BookingStatus.REJECTED &&  <StatusIconContainer><Rejected/></StatusIconContainer>}
        {status === BookingStatus.PENDING &&  <StatusIconContainer><Pending/></StatusIconContainer>}
      </BookingRowContainer>
      <BookingRowContainer>
        <BookingSumTime status={status} isHost={isHost}>
          {hourTime} hour
        </BookingSumTime>
        <BookingPrice status={status} isHost={isHost}>
          £ {handleRoundPrice(price)}
        </BookingPrice>
      </BookingRowContainer>
      <DividerWithNoMargin top={"4px"} bottom={"2px"} color={"#B0B9C6"} />
      <BookingAddress status={status} isHost={isHost}>
        {nameCharger}
      </BookingAddress>
      <BookingCar status={status} isHost={isHost}>
        {nameCar} {modelCar}
      </BookingCar>
      <BookingUser status={status} isHost={isHost}>
        {nameUser} {lastName}
      </BookingUser>
    </BookItem>
  );
};

export default Book;
