import React, { useState } from "react";
import EditParkingInformation from "../../../../../../Modal/EditParkingInformation";
import { BlueEdit } from "../../../../../../../icons";
import { IChargerDetails } from "../../../../../../../types/rest/charger/IChargerDetails";
import {
  Wrapper,
  List,
  ListItem,
  ListItemValue,
  EditButton,
  TextAreaField,
} from "./style";
import handleRoundPrice from "../../../../../../../helpers/handleRoundPrice";

interface IProps {
  details: IChargerDetails;
  setDetails: React.Dispatch<React.SetStateAction<IChargerDetails | undefined>>;
}

const Parking: React.FC<IProps> = ({ details, setDetails }) => {
  const [toggleOpenEditor, setToggleOpenEditor] = useState(false);

  const handleToggle = () => {
    setToggleOpenEditor((prevState) => !prevState);
  };
  return (
    <>
      <EditParkingInformation
        toggleOpenEditor={toggleOpenEditor}
        handleToggle={handleToggle}
        parkingInfo={details}
        setParkingInfo={setDetails}
      />
      <Wrapper>
        <List>
          {details.heightRestrict && (
            <ListItem>
              <span> Height Restricted </span>
              <ListItemValue>{handleRoundPrice(details.heightRestrict)}m </ListItemValue>
            </ListItem>
          )}
          {details.wideAccess && (
            <ListItem>
              <span> Wide Access</span>
              <ListItemValue>{handleRoundPrice(details.wideAccess)}m </ListItemValue>
            </ListItem>
          )}
          {details.narrowAccess && (
            <ListItem>
              <span> Narrow Access </span>
              <ListItemValue>{handleRoundPrice(details.narrowAccess)}m </ListItemValue>
            </ListItem>
          )}
          {details.permitRequired && (
            <ListItem>
              <span> Permit Required </span>
              <ListItemValue>{details.permitRequired} </ListItemValue>
            </ListItem>
          )}
          {details.gated && (
            <ListItem>
              <span> Gated</span>
              <ListItemValue>{details.gated} </ListItemValue>
            </ListItem>
          )}
          {details.physicalKey && (
            <ListItem>
              <span> Physical Key</span>
              <ListItemValue>{details.physicalKey} </ListItemValue>
            </ListItem>
          )}
          {details.securityCode && (
            <ListItem>
              <span> Security Code</span>
              <ListItemValue>{details.securityCode}</ListItemValue>
            </ListItem>
          )}
          {details.keyFob && (
            <ListItem>
              <span> Key Fob</span>
              <ListItemValue>{details.keyFob}</ListItemValue>
            </ListItem>
          )}
          <TextAreaField disabled value={details.locationAdditionalInformation} />
          <EditButton type="text" icon={<BlueEdit />} onClick={handleToggle} />
        </List>
      </Wrapper>
    </>
  );
};

export default Parking;
