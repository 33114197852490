import React, { useEffect, useState } from "react";
import useSWR from "swr";
import { useParams } from "react-router-dom";
import moment from "moment";
import { Button, message, Spin } from "antd";
import { IUserData } from "../../../types/IUserData";
import getDisableUser from "../../../consts/getDisableUser";
import Axios from "../../../serverConfig";
import UserStatus from "../../../enums/userTypes";
import { BlueEdit, Delete } from "../../../icons";
import getUserDetailUrl from "../../../consts/getUserDetailUrl";
import parseAllDataToEmptyStringObject from "../../../helpers/parseNullToEmptyStringObject";
import { LoaderContainer } from "../../TableUsers/styles";
import BlockModal from "../../Modal/BlockUser";
import UnBlock from "../../../icons/UnBlock";
import { EditUser } from "../../index";
import { DividerWithMargin } from "../../Divider";
import Card from "../Card";
import {
  ActionWrapper,
  CirclePhoto,
  Details,
  Row,
  UserHandle,
  UserName,
  UserNameContainer,
  UserProfileContent,
  WrapperName,
} from "./styles";
import DetailsItem from "./DetailsItem";

const CardUserData = () => {
  const [isOpenEditor, setIsOpenEditor] = useState(false);
  const [isOpenBlockModal, setIsOpenBlockModal] = useState(false);
  const [isOpenUnBlockModal, setIsOpenUnBlockModal] = useState(false);

  const params = useParams();
  const { userId } = params;

  const { data, isValidating } = useSWR(getUserDetailUrl(userId));

  const [userData, setUserData] = useState<IUserData>();

  const errorMessage = () => message.error("Sorry something going wrong!");
  const disabledUserMessage = () =>
    message.success("Success! The user is disabled.");
  const unDisabledUserMessage = () =>
    message.success("Success! The user is UnDisabled.");

  useEffect(() => {
    if (!data) {
      return;
    }
    const parsed = parseAllDataToEmptyStringObject(data);
    const { vehicles, chargers, ratings, ...rest } = parsed;
    setUserData(parsed);
  }, [isValidating]);

  const handleToggleEditor = () => setIsOpenEditor((prevState) => !prevState);
  const handleToggleBlockModal = () =>
    setIsOpenBlockModal((prevState) => !prevState);
  const handleToggleUnBlockModal = () =>
    setIsOpenUnBlockModal((prevState) => !prevState);

  const handleDisableUser = async () => {
    try {
      const res = await Axios.post(getDisableUser(), {
        userId: userData!.id,
        status: UserStatus.SUSPENDED,
      });
      if (res) {
        disabledUserMessage();
      }
    } catch (e) {
      errorMessage();
    }
    setIsOpenBlockModal(false);
  };

  const handleUnDisableUser = async () => {
    try {
      const res = await Axios.post(getDisableUser(), {
        userId: userData!.id,
        status: UserStatus.ACTIVE,
      });
      if (res) {
        unDisabledUserMessage();
      }
    } catch (e) {
      errorMessage();
    }
    setIsOpenUnBlockModal(false);
  };

  if (!userId) {
    return <p>Something going wrong</p>;
  }

  if (!data || !userData) {
    return (
      <LoaderContainer>
        <Spin size={"large"} />
      </LoaderContainer>
    );
  }

  return (
    <>
      <BlockModal
        isOpenEditor={isOpenBlockModal}
        handleToggle={handleToggleBlockModal}
        onSubmit={handleDisableUser}
        titleModal={"Block access"}
        textModal={"Are you sure you want to block this user?"}
      />
      <BlockModal
        toggleUnBlockModal={isOpenUnBlockModal}
        isOpenEditor={isOpenUnBlockModal}
        handleToggle={handleToggleUnBlockModal}
        onSubmit={handleUnDisableUser}
        titleModal={"Unblock access"}
        textModal={"Are you sure you want to unblock this user?"}
      />
      <EditUser
        toggleOpenEditor={isOpenEditor}
        handleToggle={handleToggleEditor}
        userData={userData}
        setUserData={setUserData}
      />
      <Card title="User Data">
        <UserProfileContent>
          {!userData.image ? (
            <CirclePhoto>
              {userData.firstName && userData.firstName[0].toUpperCase()}
            </CirclePhoto>
          ) : (
            <img
              src={`data:image/png;base64,${userData.image}`}
              width={45}
              height={45}
              style={{ borderRadius: "50%" }}
            />
          )}
          <UserNameContainer>
            <WrapperName>
              <UserHandle>{userData.handle}</UserHandle>
              <UserName>
                {userData.firstName} {userData.lastName}
              </UserName>
            </WrapperName>
            <ActionWrapper>
              <Button
                type="text"
                icon={<BlueEdit />}
                onClick={handleToggleEditor}
              />
              {userData.userStatus === UserStatus.ACTIVE && (
                <Button
                  type="text"
                  icon={<UnBlock />}
                  onClick={handleToggleBlockModal}
                />
              )}
              {userData.userStatus === UserStatus.SUSPENDED && (
                <Button
                  type="text"
                  icon={<Delete />}
                  onClick={handleToggleUnBlockModal}
                />
              )}
            </ActionWrapper>
          </UserNameContainer>
        </UserProfileContent>
        <DividerWithMargin top="21px" bottom="3px" />
        <Details>
          <Row>
            <DetailsItem label={"Company"} value={userData.company} />
          </Row>
          <Row>
            <DetailsItem label={"Email"} value={userData.email} />
          </Row>
          <Row>
            <DetailsItem
              label={"Phone Number"}
              value={userData.phone.toString()}
            />
          </Row>
          <Row>
            <DetailsItem
              label={"Address"}
              value={`${userData.address.number} ${userData.address.street} ${userData.address.locality} ${userData.address.city} ${userData.address.postCode}`}
            />
          </Row>
          <Row>
            <DetailsItem
              label={"Date Of Birth "}
              value={moment(userData.dateOfBirth).format("DD-MM-YYYY")}
            />
          </Row>
        </Details>
      </Card>
    </>
  );
};

export default CardUserData;
