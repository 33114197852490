import React from "react";

const ArrowLeft = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M14.6564 7.32115H4.04305L10.3955 1.89258C10.4971 1.80508 10.4354 1.64258 10.3012 1.64258H8.69582C8.62508 1.64258 8.55796 1.66758 8.50536 1.71222L1.65045 7.56758C1.58767 7.62115 1.53732 7.68739 1.50281 7.76179C1.4683 7.8362 1.45044 7.91704 1.45044 7.99883C1.45044 8.08062 1.4683 8.16146 1.50281 8.23587C1.53732 8.31027 1.58767 8.3765 1.65045 8.43008L8.54526 14.3211C8.57247 14.3444 8.60512 14.3569 8.63959 14.3569H10.2993C10.4336 14.3569 10.4953 14.1926 10.3937 14.1069L4.04305 8.67829H14.6564C14.7363 8.67829 14.8016 8.61401 14.8016 8.53544V7.46401C14.8016 7.38544 14.7363 7.32115 14.6564 7.32115Z"
        fill="#32A6D5"
      />
    </svg>
  );
};

export default ArrowLeft;
