import { Modal } from "antd";
import React from "react";
import { DividerModal } from "../../Divider";
import {
  ButtonSubmitGreen,
  ButtonSubmitRed,
  ButtonsWrapper,
  CancelButton,
  Title,
} from "./styles";

interface IProps {
  isOpenEditor: boolean;
  handleToggle: () => void;
  onSubmit: () => void;
  titleModal: string;
  textModal: string;
  toggleUnBlockModal?: boolean;
}

const BlockModal: React.FC<IProps> = ({
  handleToggle,
  textModal,
  onSubmit,
  isOpenEditor,
  titleModal,
  toggleUnBlockModal,
}) => {
  if (toggleUnBlockModal) {
    return (
      <Modal
        width={400}
        mask={true}
        maskStyle={{ backdropFilter: "blur(8px)" }}
        title={titleModal}
        centered
        visible={isOpenEditor}
        onOk={onSubmit}
        onCancel={handleToggle}
      >
        <Title>{textModal}</Title>
        <DividerModal bottom={"15px"} top={"15px"} />
        <ButtonsWrapper>
          <CancelButton onClick={handleToggle}>Cancel</CancelButton>
          <ButtonSubmitGreen htmlType="submit" onClick={onSubmit}>Unblock</ButtonSubmitGreen>
        </ButtonsWrapper>
      </Modal>
    );
  }

  return (
    <Modal
      width={400}
      mask={true}
      maskStyle={{ backdropFilter: "blur(8px)" }}
      title={titleModal}
      centered
      visible={isOpenEditor}
      onOk={onSubmit}
      onCancel={handleToggle}
    >
      <Title>{textModal}</Title>
      <DividerModal bottom={"15px"} top={"15px"} />
      <ButtonsWrapper>
        <CancelButton onClick={handleToggle}>Cancel</CancelButton>
        <ButtonSubmitRed htmlType="submit" onClick={onSubmit}>Block</ButtonSubmitRed>
      </ButtonsWrapper>
    </Modal>
  );
};

export default BlockModal;
