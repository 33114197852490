import BottomBiggerCard from "../../../Cards/BottomBiggerCard";
import VehicleCharger from "./VehicleCharger";

const VehicleChargingContainer = () => {
  return (
      <BottomBiggerCard overflow={true} title={"Vehicle Charging"}>
        <VehicleCharger />
    </BottomBiggerCard>
  );
};

export default VehicleChargingContainer;
