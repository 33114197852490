import { IUserDataWithNull, IUserDataWithParsedNull} from "../types/IUserData";

export const parseAllDataToEmptyStringObject = (data:IUserDataWithNull) => {
    const parsedData = Object.fromEntries(Object.entries(data).map(([key, value]) =>
            value === null ? [key, "none"] : [key, value]
        )
    )as IUserDataWithParsedNull
    return parsedData;
}

export default parseAllDataToEmptyStringObject
