import React from "react";

const Delete = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="19"
      height="18"
      viewBox="0 0 19 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx="9.66675" cy="9" r="8.25" stroke="#DC0028" strokeWidth="1.5" />
      <path
        d="M3.43193 2.76499L15.9016 15.2349"
        stroke="#DC0028"
        strokeWidth="1.5"
      />
    </svg>
  );
};

export default Delete
