import {useContext, useEffect} from "react";
import TabsContext from "../../context/tabsContext";
import { TabPanel, TabsCard, TabsWrapper } from "./styles";
import ChargersContainer from "./Chargers";
import InboxCardContainer from "./Inbox/InboxCardContainer";
import CalendarItemsWrapper from "./Calendar";

const TabsContainer = () => {
  const {handleId, activeTab} = useContext(TabsContext)

  useEffect(()=>{
    handleId(0)
  },[])

  return (
    <TabsWrapper>
      <TabsCard defaultActiveKey={activeTab.toString()} type="card" size="small">
        <TabPanel tab="Chargers" key="1">
          <ChargersContainer />
        </TabPanel>
        <TabPanel tab="Calendar" key="2">
          <CalendarItemsWrapper />
        </TabPanel>
        <TabPanel tab="Inbox" key="3">
          <InboxCardContainer />
        </TabPanel>
      </TabsCard>
    </TabsWrapper>
  );
};

export default TabsContainer;
