import React, { useRef } from "react";
import { Formik } from "formik";
import useSWR from "swr";
import {Form, message, Modal} from "antd";
import { useTranslation } from "react-i18next";
import { IVehicle } from "../../../types/IVehicle";
import { getCarLists } from "../../../consts/getCarLists";
import Axios from "../../../serverConfig";
import { getEditVehicleUrl } from "../../../consts/getEditVehicleUrl";
import validationVehicleEditSchema from "../../../validationSchema/ValidationVehicleEditSchema";
import { TextInputRow } from "../../index";
import { Label, RowModal } from "../../TextInput/TextInputRow/styles";
import {
  ButtonSubmit,
  ButtonsWrapper,
  CancelButton,
  SelectCar,
} from "../styles";

interface IProps {
  toggleOpenEditor: boolean;
  handleToggle: () => void;
  vehicleDetails: IVehicle;
  setVehicleDetails: React.Dispatch<React.SetStateAction<IVehicle>>;
}

const EditVehicle: React.FC<IProps> = ({
  toggleOpenEditor,
  handleToggle,
  vehicleDetails,
  setVehicleDetails,
}) => {
  const initialValues: IVehicle = {
    ...vehicleDetails,
  };

  const { data, isValidating } = useSWR(getCarLists());

  const errorMessage = () => message.error("Sorry something going wrong!")
  const successEditMessage = () => message.success("Success.Vehicle information changed")

  const { t } = useTranslation();

  const formikRef = useRef<any>();

  const handleFormikClick = () => {
    if (formikRef?.current !== null) {
      formikRef.current.handleSubmit();
    }
  };

  const handleSubmitForm = async (values: IVehicle) => {
    if (!values) {
      return;
    }
    try {
      setVehicleDetails(values);
      const body = values
      await Axios.post(getEditVehicleUrl(), body )
      successEditMessage()
      handleToggle();
    } catch (err: unknown) {
      errorMessage()
    }
  };

  if (isValidating) {
    return <p>Loading..</p>;
  }

  return (
    <Modal
      mask={true}
      maskStyle={{ backdropFilter: "blur(8px)" }}
      title="Edit vehicle data"
      centered
      visible={toggleOpenEditor}
      onOk={handleFormikClick}
      onCancel={handleToggle}
    >
      <Formik
        innerRef={formikRef}
        initialValues={initialValues}
        validationSchema={validationVehicleEditSchema}
        onSubmit={handleSubmitForm}
      >
        {({ handleSubmit, values, handleChange, errors, touched }) => (
          <Form
            onFinish={handleSubmit}
            name="basic"
            layout="vertical"
            autoComplete="off"
          >
            <Form.Item>
              <TextInputRow
                labelText={t("name")}
                name={"name"}
                value={values.name}
                handleChange={handleChange}
                errors={errors.name}
                touched={touched.name}
              />
            </Form.Item>
            <Form.Item name={"make"}>
              <RowModal>
                <Label>{t("make")}</Label>
                <SelectCar
                  name={"make"}
                  value={values.make}
                  onChange={handleChange}
                >
                  {data.map((item: string) => {
                    if (item.toUpperCase() === values.make.toUpperCase()) {
                      return (
                        <option value={values.make}>
                          {item[0].toUpperCase() + item.substring(1)}
                        </option>
                      );
                    }
                    return (
                      <option value={item}>
                        {item[0].toUpperCase() + item.substring(1)}
                      </option>
                    );
                  })}
                </SelectCar>
              </RowModal>
            </Form.Item>
            <Form.Item>
              <TextInputRow
                labelText={t("model")}
                name={"model"}
                value={values.model}
                handleChange={handleChange}
                errors={errors.model}
                touched={touched.model}
              />
            </Form.Item>
            <Form.Item>
              <TextInputRow
                labelText={t("batteryCapacity")}
                name={"batteryCapacity"}
                value={values.batteryCapacity}
                handleChange={handleChange}
                errors={errors.batteryCapacity}
                touched={touched.batteryCapacity}
              />
            </Form.Item>
            <Form.Item>
              <TextInputRow
                labelText={t("plateReg")}
                name={"plateReg"}
                value={values.plateReg}
                handleChange={handleChange}
                errors={errors.plateReg}
                touched={touched.plateReg}
              />
            </Form.Item>
            <ButtonsWrapper>
              <CancelButton onClick={handleToggle}>Cancel</CancelButton>
              <ButtonSubmit htmlType="submit">Save</ButtonSubmit>
            </ButtonsWrapper>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default EditVehicle;
