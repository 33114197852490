import React from "react";
import {CardTitle} from "../../../Cards/BottomBiggerCard/styles";
import {DividerWithMargin} from "../../../Divider";
import Thread from "./Thread";
import {Wrapper} from "./styles";

const ThreadsContainer:React.FC = () => {
  return (
      <Wrapper>
          <CardTitle>Threads</CardTitle>
          <DividerWithMargin top={"12px"} bottom={"20px"} />
          <Thread />
      </Wrapper>

  );
};

export default ThreadsContainer;
