import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  padding:5px;
  width: 100%;
  flex-direction: column;
`;
export const VehicleNameAndStatus = styled.div`
  display: flex;
  flex-direction: column;
`;
export const VehicleName = styled.span`
  font-family: Nunito;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  color: #3b3558;
`;
export const VehicleStatus = styled.span`
  font-family: Nunito;
  display: list-item;
  font-style: normal;
  font-weight: 400;
  list-style: inside;
  font-size: 14px;
  line-height: 20px;
  color: #8bd033;
`;
export const BookingScheduleContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;
export const Title = styled.span`
  font-family: Nunito;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 25px;
  color: #3b3558;
`;
export const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;
export const Time = styled.span`
  font-family: Nunito;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 150%;
  color: #b0b9c6;
`;
export const Card = styled.div`
  display: flex;
  flex-direction: column;
  border: 2px solid #f1f3f5;
  width: 134px;
  height: 64px;
  align-items: center;
  padding: 8px 16px;
`;
export const CardDate = styled.span`
  font-family: Nunito;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 150%;
  color: #3b3558;
`;

export const CardTime = styled.span`
  font-family: Nunito;
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 26px;
  color: #3b3558;
`;

export const Rectangle = styled.div`
  width: 24px;
  height: 4px;
  border-radius: 2px;
  background: #f1f3f5;
`;
export const OccurrenceContainer = styled.div`
  display: flex;
  gap: 10px;
  flex-direction: column;
`;

export const UserContent = styled.div`
  padding-left:10px;
  gap: 15px;
  padding-top: 5px;
  display: flex;
  flex-direction: row;
`;

export const UserHandle = styled.span`
  font-family: Nunito;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 25px;
  color: #3b3558;
`;
export const UserHandleAndFullName = styled.div`
  display: flex;
  padding-top: 5px;
  flex-direction: column;
`;

export const UserFullName = styled.span`
  font-family: Nunito;
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 150%;
  color: #b0b9c6;
`;
export const ChargerContainer = styled.div`
  min-height: 100px;
  padding: 16px;
  gap: 20px;
  margin-top:10px;
  width: 100%;
  display: flex;
  flex-direction: row;
  border: 2px solid #f1f3f5;
  border-radius: 8px;
  background: #ffffff;
`;
export const ChargerImage = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  flex: 0 0 10%;
`;

export const ChargerType = styled.span`
  font-family: Nunito;
  font-style: normal;
  font-weight: 700;
  font-size: 11px;
  line-height: 150%;
  text-align: center;
  color: #32a6d5;
`;

export const RightSide = styled.div`
  display: flex;
  flex-direction: column;
  flex: 0 0 90%;
`;

export const ChargerName = styled.span`
  font-family: Nunito;
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 26px;
  color: #3b3558;
`;
export const ChargerAddress = styled.span`
  font-family: Nunito;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #3b3558;
`;
export const SummaryCard = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px;
  gap: 16px;
  height: 200px;
  background: #ffffff;
  border: 2px solid #f1f3f5;
  border-radius: 8px;
`;

export const SummaryTextStart = styled.span`
  display: flex;
  justify-content: flex-start;
  font-family: "Nunito";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  padding-right: 20px;
  color: #3b3558;
`;
export const SummaryTextEnd = styled.span`
  font-family: "Nunito";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: #3b3558;
`;
export const SummaryRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`;

export const MinimumSessionFee = styled.span`
  font-family: Nunito;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: #b0b9c6;
`;
export const MinimumSessionValue = styled.span`
  font-family: Nunito;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: #b0b9c6;
`;
export const AnticipatedCost = styled.span`
  font-family: Nunito;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 25px;
  padding-bottom: 3px;
  color: #8bd033;
`;
