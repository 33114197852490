import React, { useContext, useEffect, useState } from "react";
import useSWR from "swr";
import { message, Spin } from "antd";
import { useParams } from "react-router-dom";
import { ICharger } from "../../../../types/rest/charger/ICharger";
import { IActiveCharger } from "../../../../types/IActiveCharger";
import BottomBiggerCard from "../../../Cards/BottomBiggerCard";
import getUserDetailUrl from "../../../../consts/getUserDetailUrl";
import parseAllDataToEmptyStringObject from "../../../../helpers/parseNullToEmptyStringObject";
import TabsContext from "../../../../context/tabsContext";
import Axios from "../../../../serverConfig";
import { getEditCharger } from "../../../../consts/getEditCharger";
import { getChargerDetailsUrl } from "../../../../consts/getChargerDetailsUrl";
import Charger from "./Charger";
import { ChargerItems } from "./styles";

const ChargersCard: React.FC = () => {
  const { handleId } = useContext(TabsContext);

  const errorMessage = () => message.error("Sorry something going wrong!");

  const [currentCharger, setCurrentCharger] = useState<IActiveCharger>({
    id: 0,
    active: true,
  });

  const [chargerList, setChargerList] = useState<ICharger[]>([]);

  const params = useParams();
  const { userId } = params;

  const { data, isValidating } = useSWR(getUserDetailUrl(userId));

  useEffect(() => {
    if (!data) {
      return;
    }
    const parsed = parseAllDataToEmptyStringObject(data);
    const { chargers } = parsed;
    setChargerList(chargers);
    if (chargerList.length > 0) {
      handleId(chargerList[0].id);
      setCurrentCharger({
        id: chargerList[0].id,
        active: true,
      });
    }
  }, [isValidating, chargerList]);

  const handleSetCurrentCharger = (id: number) => {
    setCurrentCharger({
      id: id,
      active: true,
    });
  };

  const handleDisableCharger = async (id: number) => {
    try {
      const chargerDetail = await Axios.get(getChargerDetailsUrl(id));
      if (!chargerDetail.data) {
        return;
      }
      const { live, ...rest } = chargerDetail.data;

      const body = {
        ...rest,
        live: false,
      };

      const res = await Axios.post(getEditCharger(), body);
      if (res) {
        window.location.reload()
      }
    } catch (e: unknown) {
      errorMessage();
    }
  };

  const handleUnDisableCharger = async (id: number) => {
    try {
      const chargerDetail = await Axios.get(getChargerDetailsUrl(id));
      if (!chargerDetail.data) {
        return;
      }
      const { live, ...rest } = chargerDetail.data;

      const body = {
        ...rest,
        live: true,
      };

      const res = await Axios.post(getEditCharger(), body);
      if (res) {
        window.location.reload()
      }
    } catch (e: unknown) {
      errorMessage();
    }
  };

  if (isValidating) {
    return (
      <BottomBiggerCard title={"Chargers"} overflow={true}>
        <Spin size={"large"} />
      </BottomBiggerCard>
    );
  }

  if (!chargerList.length) {
    return (
      <BottomBiggerCard title={"Chargers"} overflow={true}>
        <ChargerItems>
          <h1>Empty</h1>
        </ChargerItems>
      </BottomBiggerCard>
    );
  }

  return (
    <BottomBiggerCard title={"Chargers"} overflow={true}>
      <ChargerItems>
        {chargerList.map((item) => {
          const { id, name, address, averageRating } = item;
          return (
            <Charger
              handleUnDisableCharger={handleUnDisableCharger}
              handleDisableCharger={handleDisableCharger}
              currentCharger={currentCharger}
              handleSetCurrentCharger={handleSetCurrentCharger}
              id={id}
              name={name}
              address={`${address.number} ${address.street} ${address.locality} ${address.city} ${address.postCode}`}
              chargerRate={averageRating?.toString()}
            />
          );
        })}
      </ChargerItems>
    </BottomBiggerCard>
  );
};

export default ChargersCard;
