import styled from "styled-components";

export const OpinionsWrapper = styled.div`
  display: flex;
  overflow-y: scroll;
  height: 280px;
  flex-grow: 1;
  flex-direction: column;
  padding: 10px 4px;
  gap: 5px;
`
