import styled from "styled-components";
import {Tabs} from "antd";

const { TabPane } = Tabs

export const TabsWrapper = styled.div`
  display: flex;
  padding: 0 12px 0 12px;
  background: #e5e5e5;
`
export const TabsCard: typeof Tabs = styled(Tabs)`
  display: flex;
  width: 100%;
  background-color: #e5e5e5;
  border-radius: 15px;

  &.ant-tabs-card > .ant-tabs-nav::before {
    display: none;
  }

  &.ant-tabs-card .ant-tabs-tab,
  [data-theme='compact'] .card-container > .ant-tabs-card .ant-tabs-tab {
    border-color: transparent;
  }

  &.ant-tabs-card .ant-tabs-content {
    margin-top: -18px;
  }

  &.ant-tabs-card .ant-tabs-content > .ant-tabs-tabpane {
    padding: 16px;
    background: white;
    border-top-right-radius: 15px;
    border-bottom-right-radius: 15px;
    border-bottom-left-radius: 15px;
  }

  &.ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab-active,
  .ant-tabs-card.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-tab-active {
    border-bottom-color: #fff;
    border-radius: 16px 16px 0px 0px;
    background:white;
  }
  
  &.ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab {
    border-radius: 16px 16px 0px 0px;
    background: rgba(255, 255, 255, 0.5);
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    color: #B0B9C6;
  }
  
  &.ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab-active {
    background: white;
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    color: rgba(0, 0, 0, 0.85) !important;
  }

  &.ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab {
    padding: 8px 16px 8px 16px;
    border-radius: 16px 16px 0px 0px;
  }
`

export const TabPanel: typeof TabPane = styled(TabPane)`
  display: flex;
  padding: 10px;
  justify-content: space-between;
`
