import { Outlet, Navigate } from 'react-router-dom'
import Cookies from "universal-cookie";

const PrivateRoutes = () => {
    const cookies = new Cookies()

    const token =cookies.get('access_token')

    return(
        token ? <Outlet/> : <Navigate to="/login"/>
    )
}

export default PrivateRoutes
