import moment from "moment";

const handleSumTimeBooking = (dateStart: string, dateEnd: string) => {
  const parsedDateStart = moment(dateStart);
  const parsedDateEnd = moment(dateEnd);

  const sumTime = moment.duration(parsedDateEnd.diff(parsedDateStart));
  const sumTimeParsedToHours = sumTime.asHours();

  return sumTimeParsedToHours;
};

export default handleSumTimeBooking;
