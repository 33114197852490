import styled from "styled-components";
import BookingStatus from "../../../../../../enums/bookingStatus";
import handleColorStatusType from "../../../../../../helpers/getColorStatusType";

export const BookItem = styled.div.attrs(
  (props: { status: string; isSelected: boolean; isHost: boolean }) => props
)`
  box-shadow: rgba(105, 103, 130, 0.24) 0px 1px 4px,
    rgb(105, 103, 130, 0.24) 0px 0px 0px 3px;
  box-shadow: ${(props) =>
    props.isSelected
      ? handleColorStatusType(props.status as BookingStatus, props.isHost)
          .boxShadowSelected
      : handleColorStatusType(props.status as BookingStatus, props.isHost)
          .boxShadow};
  background: ${(props) =>
    handleColorStatusType(props.status as BookingStatus, props.isHost)
      .cardColor};
  border: ${(props) =>
    props.isSelected
      ? handleColorStatusType(props.status as BookingStatus, props.isHost)
          .border
      : handleColorStatusType(props.status as BookingStatus, props.isHost)
          .borderSelected};
  display: flex;
  min-height: 130px;
  width: 95%;
  flex-direction: column;
  padding: 8px 8px 6px 12px;
  border-radius: 8px;
`;
export const BookingRowContainer = styled.div`
  display: flex;
  gap: 4px;
  flex-direction: row;
`;

export const BookingHourStart = styled.span.attrs(
  (props: { status: string; isHost: boolean }) => props
)`
  color: ${(props) =>
    handleColorStatusType(props.status as BookingStatus, props.isHost)
      .hourColor};
  display: flex;
  font-family: "Nunito";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
`;
export const BookingHourEnd = styled.span.attrs(
  (props: { status: string; isHost: boolean }) => props
)`
  color: ${(props) =>
    handleColorStatusType(props.status as BookingStatus, props.isHost)
      .hourColor};
  display: flex;
  font-family: "Nunito";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
`;
export const BookingSumTime = styled.span.attrs(
  (props: { status: string; isHost: boolean }) => props
)`
  display: flex;
  font-size: 10px;
  font-family: "Nunito";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 150%;
  width: 42px;
  height: 15px;
  border-radius: 8px;
  background-color: ${(props) =>
    handleColorStatusType(props.status as BookingStatus, props.isHost)
      .hourBackgroundColor};
  padding: 0px 4px;
`;
export const BookingPrice = styled.span.attrs(
  (props: { status: string; isHost: boolean }) => props
)`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 10px;
  width: 60px;
  font-family: Nunito;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  height: 15px;
  border-radius: 8px;
  color: #3b3558;
  background-color: ${(props) =>
    handleColorStatusType(props.status as BookingStatus, props.isHost)
      .hourBackgroundColor};
`;

export const BookingAddress = styled.span.attrs(
  (props: { status: string; isHost: boolean }) => props
)`
  display: flex;
  font-family: Nunito;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 150%;
  color: ${(props) =>
    handleColorStatusType(props.status as BookingStatus, props.isHost)
      .chargerName};
`;
export const BookingCar = styled.span.attrs(
  (props: { status: string; isHost: boolean }) => props
)`
  display: flex;
  font-family: Nunito;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 150%;
  color: ${(props) =>
    handleColorStatusType(props.status as BookingStatus, props.isHost)
      .chargerAddress};
`;
export const BookingUser = styled.span.attrs(
  (props: { status: string; isHost: boolean }) => props
)`
  color: ${(props) =>
    handleColorStatusType(props.status as BookingStatus, props.isHost).name};
  font-family: Nunito;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  display: flex;
`;

export const StatusIconContainer = styled.div`
padding-left:60%;
`
