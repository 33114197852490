import React from "react";
import { LineDividerNoMargin } from "../styles";

interface IProps {
  top: string;
  bottom: string;
  color?: string;
}

const DividerWithNoMargin: React.FC<IProps> = ({ top, bottom, color }) => {
  return <LineDividerNoMargin top={top} bottom={bottom} color={color} />;
};

export default DividerWithNoMargin;
