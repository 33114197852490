import {useEffect, useState} from "react";
import useSWR from "swr";
import moment from "moment";
import {NAVIGATION} from "../../enums/navigation";
import getContentUrl from "../../consts/getContentUrl";
import {IContent} from "../../types/rest/editor/IContent";
import {CONTENT_EDITOR_TITLE} from "../../enums/contentEditorTitle";
import {CONTENT_EDITOR_TYPE} from "../../enums/contentEditorType";
import {
  CardTitle,
  ContentCard,
  DividerLine,
  RowItem,
} from "./styles";
import ContentNavItem from "./ContentNavItem";

const ContentNav = () => {
  const { data, isValidating } = useSWR(getContentUrl());
  const [lastEditAbout,setLastEditAbout] = useState('')
  const [lastEditFAQ,setLastEditFAQ] = useState('')
  const [lastEditTerms,setLastEditTerms] = useState('')
  const [lastEditContact,setLastEditContact] = useState('')


  useEffect(() => {
    if (!data) {
      return;
    }
    handleData()
  }, [isValidating, data]);

  const handleData = () => {
      data.forEach((item: IContent) => {
        if (
            item.title === CONTENT_EDITOR_TITLE.ABOUT &&
            item.type === CONTENT_EDITOR_TYPE.SINGLE
        )
          setLastEditAbout(item.items[0].updatedAt);
      });

    data.forEach((item: IContent) => {
      if (
          item.title === CONTENT_EDITOR_TITLE.CONTACT &&
          item.type === CONTENT_EDITOR_TYPE.SINGLE
      )
        setLastEditContact(item.items[0].updatedAt);
    });

    data.forEach((item: IContent) => {
      if (
          item.title === CONTENT_EDITOR_TITLE.TERMS &&
          item.type === CONTENT_EDITOR_TYPE.SINGLE
      )
        setLastEditTerms(item.items[0].updatedAt);
    });

    data.forEach((item: IContent) => {
      if (
          item.title === CONTENT_EDITOR_TITLE.FAQ &&
          item.type === CONTENT_EDITOR_TYPE.MULTI
      )
        setLastEditFAQ(item.items[0].updatedAt);
    });
  };

  return (
    <ContentCard>
      <RowItem>
        <CardTitle>Title</CardTitle>
        <CardTitle> Last Edition Date</CardTitle>
      </RowItem>
      <DividerLine />
        <ContentNavItem navigationUrl={NAVIGATION.ABOUT} dateEdit={moment(lastEditAbout).format("DD-MM-YYYY")} textLink={"About"}/>
        <ContentNavItem navigationUrl={NAVIGATION.FAQ} dateEdit={moment(lastEditFAQ).format("DD-MM-YYYY")} textLink={"FAQ"}/>
        <ContentNavItem navigationUrl={NAVIGATION.TERMS}  dateEdit={moment(lastEditTerms).format("DD-MM-YYYY")} textLink={"Terms & Conditions"}/>
        <ContentNavItem navigationUrl={NAVIGATION.CONTACT} dateEdit={moment(lastEditContact).format("DD-MM-YYYY")} textLink={"Contact"}/>
    </ContentCard>
  );
};

export default ContentNav;
