import { GreyStar, YellowStar } from "../icons";

const yellowStar = () => {
  return (
    <>
      <YellowStar />
    </>
  );
};

const greyStar = () => {
  return (
    <>
      <GreyStar />
    </>
  );
};

const renderStars = (x: number) => {
  const yellowStars = Math.round(x);
  const greyStars = 5 - yellowStars;

  const arrOfYellowStar = Array.from({ length: x }, () => yellowStar());
  const arrOfGreyStar = Array.from({ length: greyStars }, () => greyStar());

  const arrOfStars = [arrOfYellowStar, arrOfGreyStar];

  return arrOfStars.map((star) => {
    return <>{star}</>;
  });
};

export default renderStars;
