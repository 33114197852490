import { useTranslation } from "react-i18next";
import {LoginForm} from "../../components";
import { Logo } from "../../icons";
import {
  CardBody,
  CardContainer,
  CardHeader,
  LoginText,
  Wrapper,
} from "./styles";

const Login = () => {
  const { t } = useTranslation();

  return (
    <Wrapper>
      <CardContainer>
        <CardHeader>
          <Logo width={270} height={71} />
        </CardHeader>
        <CardBody>
          <LoginText>{t("login_into_account")}</LoginText>
          <LoginForm />
        </CardBody>
      </CardContainer>
    </Wrapper>
  );
};

export default Login;
