import { Card } from "antd";
import styled from "styled-components";

export const Wrapper: typeof Card = styled(Card)`
  display: flex;
  flex: 0 1 70%;
  overflow-y: scroll;
  overflow-x: hidden;
  flex-direction: column;
  border-radius: 15px;
  background: #ffffff;
`;
