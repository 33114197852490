import React from "react";
import ArrowInbox from "../../../icons/ArrowInbox";
import { CardText, DividerLine, LinkItem, RowItem } from "../styles";

interface IProps {
  navigationUrl: string;
  dateEdit: string;
  textLink: string;
}

const ContentNavItem: React.FC<IProps> = ({
  navigationUrl,
  dateEdit,
  textLink,
}) => {
  return (
    <>
      <RowItem>
        <LinkItem to={navigationUrl}>{textLink}</LinkItem>
        <CardText>
          <LinkItem to={navigationUrl}>
            {dateEdit} <ArrowInbox />
          </LinkItem>
        </CardText>
      </RowItem>
      <DividerLine />
    </>
  );
};

export default ContentNavItem;
