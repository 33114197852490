import React from "react";
import { LabelItem, ValueItem } from "./style";

interface IProps {
  label: string;
  value: string | string[],
}

const DetailsItem: React.FC<IProps> = ({ label, value }) => {
  return (
    <>
      <LabelItem>{label} </LabelItem>
      <ValueItem>{value} </ValueItem>
    </>
  );
};

export default DetailsItem;
