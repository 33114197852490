import React from "react";

const AddButton = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx="8" cy="8.89383" r="7.5" stroke="#32A6D5" />
      <path
        d="M7.99989 12.4294L7.99989 5.3583M4.46436 8.89383L11.5354 8.89383"
        stroke="#32A6D5"
        strokeLinecap="square"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default AddButton;
