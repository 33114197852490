import * as Yup from "yup";

const ValidationResetPassword = Yup.object().shape({
    password: Yup.string()
        .required("Required")
        .min(8,"Must Contain 8 Characters, One Uppercase, One Special Case Character")
        .matches(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*[!?$()`@#\$%\^&\*])(?=.{8,})/,
            ("Must Contain 8 Characters, One Uppercase, One Special Case Character"),
        ),
    confirmPassword: Yup.string().oneOf(
        [Yup.ref("password")],
        ("Password must match"),
    ),
})
export default ValidationResetPassword
