import React from "react";

const BlueEdit = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.42679 1.03528H6.0044C3.18979 1.03528 1.42505 3.02793 1.42505 5.84896V13.4589C1.42505 16.28 3.18155 18.2726 6.0044 18.2726H14.0812C16.905 18.2726 18.6615 16.28 18.6615 13.4589V9.77203"
        stroke="#32A6D5"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.98783 8.47864L13.828 1.63844C14.6802 0.787188 16.0614 0.787188 16.9136 1.63844L18.0275 2.75238C18.8797 3.60455 18.8797 4.98669 18.0275 5.83793L11.1544 12.7111C10.7818 13.0836 10.2766 13.2932 9.74935 13.2932H6.32056L6.4066 9.83332C6.41941 9.3244 6.62719 8.83928 6.98783 8.47864Z"
        stroke="#32A6D5"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.7886 2.69531L16.9679 6.87467"
        stroke="#32A6D5"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default BlueEdit;
