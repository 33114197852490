import React, { ReactNode } from "react";
import { ContainerWrapper } from "./styles";

interface IProps {
  children: ReactNode;
}

const CardContainer: React.FC<IProps> = ({ children }) => {
  return <ContainerWrapper>{children}</ContainerWrapper>;
};
export default CardContainer;
