import React, {useContext, useEffect, useState} from "react";
import useSWR from "swr";
import { Button } from "antd";
import EditLocationInformation from "../../../../Modal/EditLocationInformation";
import {getChargerDetailsUrl} from "../../../../../consts/getChargerDetailsUrl";
import {IChargerDetails} from "../../../../../types/rest/charger/IChargerDetails";
import {
  Access,
  BlueEdit,
  Cctv,
  CoveredParking,
  Lighting,
  Wifi,
} from "../../../../../icons";
import TabsContext from "../../../../../context/tabsContext";
import {DetailsItems} from "../styles";
import {
  Wrapper,
  Row,
  ChargerName,
  Address,
  AttendedCharging,
  IconsContainer,
  Col,
  IconTitle,
  TabsContainer,
  Tab,
} from "./styles";
import Parking from "./Tabs/Parking";
import Charger from "./Tabs/Charger";
import Availability from "./Tabs/Availability";

const Detail = () => {

  const { id } = useContext(TabsContext);
  const { data, isValidating } = useSWR(getChargerDetailsUrl(id))
  const [details,setDetails] = useState<IChargerDetails>()

  useEffect(()=>{
    if(!data){
      return
    }
    setDetails(data)
  },[isValidating])

  const [toggleOpenEditor, setToggleOpenEditor] = useState(false);

  const handleToggle = () => {
    setToggleOpenEditor((prevState) => !prevState);
  };


  if(!details || !Object.keys(details).length){
    return (
      <DetailsItems>
        <h2>Empty</h2>
      </DetailsItems>
    )
  }

  const displayAddress = `${details.address.number} ${details.address.street} ${details.address.locality} ${details.address.city} ${details.address.postCode}`;

  if(isValidating){
    return <p>Loading..</p>
  }

  return (
    <>
      <EditLocationInformation
        details={details}
        setDetails={setDetails}
        toggleOpenEditor={toggleOpenEditor}
        handleToggle={handleToggle}
      />
      <Wrapper>
        <Row>
          <ChargerName>{details.name}</ChargerName>
          <Button type="text" icon={<BlueEdit />} onClick={handleToggle} />
        </Row>
        <Address>{displayAddress}</Address>
        {details.attendedCharging && (
          <AttendedCharging>Attended Charging</AttendedCharging>
        )}
        <IconsContainer>
          {details.wifi && (
            <Col>
              <Wifi />
              <IconTitle>Wi-Fi Available</IconTitle>
            </Col>
          )}
          {details.coveredParking && (
            <Col>
              <CoveredParking />
              <IconTitle>Covered Parking</IconTitle>
            </Col>
          )}
          {details.lighting && (
            <Col>
              <Lighting />
              <IconTitle>Lighting</IconTitle>
            </Col>
          )}
          {details.cctv && (
            <Col>
              <Cctv />
              <IconTitle>CCTV</IconTitle>
            </Col>
          )}
          {details.disabledAccess && (
            <Col>
              <Access />
              <IconTitle>Disabled</IconTitle>
            </Col>
          )}
        </IconsContainer>
        <TabsContainer defaultActiveKey="1">
          <Tab tab="Charger" key="1">
            <Charger details={details} setDetails={setDetails} />
          </Tab>
          <Tab tab="Parking" key="2">
            <Parking details={details} setDetails={setDetails} />
          </Tab>
          <Tab tab="Availability" key="3">
            <Availability details={details} setDetails={setDetails} />
          </Tab>
        </TabsContainer>
      </Wrapper>
    </>
  );
};

export default Detail;
