import styled from "styled-components";

export const RateContainer = styled.div`
  display: flex;
`;

export const RateLeftSide = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 20%;
  gap: 5px;
`;

export const RateRightSide = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  flex: 1 1 80%;
`;

export const RateAmount = styled.div`
  padding-left: 5px;
  font-family: Nunito;
  font-weight: 700;
  font-size: 22px;
  line-height: 26px;
  color: #3b3558;
  font-size: 22px;
  display: flex;
  flex-direction: row;
`;

export const RateAmountInteger = styled.div`
  padding-left: 5px;
  font-family: Nunito;
  font-weight: 700;
  font-size: 22px;
  line-height: 26px;
  color: #3b3558;
  font-size: 22px;
  display: flex;
  flex-direction: row;
`

export const RateStars = styled.div`
  display: flex;
  flex-direction: row;
`;

export const Rates = styled.div`
  padding-left: 5px;
  display: flex;
  align-items: center;
  font-family: Nunito;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #b0b9c6;
`;
