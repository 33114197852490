import React from "react";
import {ButtonFilter, ButtonGroup, WrapperHeader} from "./styles";

interface IProps {
    clearAll: () => void,
}

const TableHeader: React.FC<IProps> = ({clearAll}) => {
    return (
            <WrapperHeader>
                <ButtonGroup>
                    <ButtonFilter onClick={clearAll}>Reset</ButtonFilter>
                </ButtonGroup>
            </WrapperHeader>
    )
}

export default TableHeader;
