import React from "react";

const Lighting = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="33"
      height="33"
      viewBox="0 0 33 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.5 16.4471C0.5 7.6105 7.66344 0.447052 16.5 0.447052V0.447052C25.3366 0.447052 32.5 7.6105 32.5 16.4471V16.4471C32.5 25.2836 25.3366 32.4471 16.5 32.4471V32.4471C7.66344 32.4471 0.5 25.2836 0.5 16.4471V16.4471Z"
        fill="#3B3558"
      />
      <mask id="path-2-inside-1_2784_14214" fill="white">
        <path d="M16.4972 11.1902C13.5761 11.1902 11.208 13.5582 11.208 16.4793C11.208 17.3458 11.4163 18.1636 11.7857 18.8853C12.5236 20.3275 13.6003 21.6529 13.9407 23.2368H18.9655C19.3054 21.6952 20.366 20.4157 21.1296 19.034C21.5481 18.2767 21.7864 17.4058 21.7864 16.4793C21.7864 13.5582 19.4183 11.1902 16.4972 11.1902Z" />
      </mask>
      <path
        d="M11.7857 18.8853L12.6759 18.4298H12.6759L11.7857 18.8853ZM13.9407 23.2368L12.9631 23.4469L13.1328 24.2368H13.9407V23.2368ZM18.9655 23.2368V24.2368H19.769L19.942 23.4521L18.9655 23.2368ZM21.1296 19.034L22.0048 19.5178V19.5178L21.1296 19.034ZM12.208 16.4793C12.208 14.1105 14.1283 12.1902 16.4972 12.1902V10.1902C13.0238 10.1902 10.208 13.0059 10.208 16.4793H12.208ZM16.4972 12.1902C18.866 12.1902 20.7864 14.1105 20.7864 16.4793H22.7864C22.7864 13.0059 19.9706 10.1902 16.4972 10.1902V12.1902ZM12.6759 18.4298C12.377 17.8458 12.208 17.1838 12.208 16.4793H10.208C10.208 17.5078 10.4556 18.4814 10.8954 19.3409L12.6759 18.4298ZM13.9407 24.2368H18.9655V22.2368H13.9407V24.2368ZM20.7864 16.4793C20.7864 17.2324 20.5931 17.9373 20.2544 18.5503L22.0048 19.5178C22.5031 18.6161 22.7864 17.5793 22.7864 16.4793H20.7864ZM19.942 23.4521C20.0784 22.8336 20.3668 22.2349 20.7506 21.5824C20.9429 21.2555 21.1472 20.9344 21.3643 20.588C21.5773 20.2484 21.7995 19.8892 22.0048 19.5178L20.2544 18.5503C20.0779 18.8697 19.8812 19.1886 19.6699 19.5256C19.4628 19.8559 19.2375 20.2101 19.0267 20.5684C18.6043 21.2866 18.1924 22.0984 17.9889 23.0215L19.942 23.4521ZM10.8954 19.3409C11.2721 20.077 11.7876 20.8779 12.1432 21.5027C12.5309 22.1836 12.8252 22.8053 12.9631 23.4469L14.9184 23.0267C14.7159 22.0844 14.3016 21.2515 13.8813 20.5132C13.429 19.7187 13.0372 19.1359 12.6759 18.4298L10.8954 19.3409Z"
        fill="white"
        mask="url(#path-2-inside-1_2784_14214)"
      />
      <path
        d="M17.8043 24.872C17.5984 25.4546 17.0428 25.872 16.3896 25.872C15.7365 25.872 15.1809 25.4546 14.975 24.872H17.8043Z"
        stroke="white"
        strokeLinecap="round"
      />
      <path
        d="M15.3896 16.372C15.3896 15.8198 15.8374 15.372 16.3896 15.372"
        stroke="white"
        strokeLinecap="round"
      />
      <path
        d="M16.3926 7.12204L16.3926 9.12204"
        stroke="white"
        strokeLinecap="round"
      />
      <line
        x1="21.5229"
        y1="10.527"
        x2="22.9247"
        y2="9.12524"
        stroke="white"
        strokeLinecap="round"
      />
      <path
        d="M11.3369 10.2899L10.1724 9.12534"
        stroke="white"
        strokeLinecap="round"
      />
      <line
        x1="23.8111"
        y1="14.6266"
        x2="25.7259"
        y2="14.1135"
        stroke="white"
        strokeLinecap="round"
      />
      <line
        x1="7.27399"
        y1="14.1136"
        x2="9.18883"
        y2="14.6267"
        stroke="white"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default Lighting;
