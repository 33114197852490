import styled from "styled-components";
import { Button } from "antd";

export const CountSide = styled.div`
  display: flex;
  align-items: center;
  width: 42px;
  justify-content: center;
  border: 1px solid #f0f0f0;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  gap: 5px;
`;

export const Question = styled.div`
  display: flex;
`;
export const DeleteButton = styled(Button)`
  display: flex;
  border: none;
  align-items: center;
  justify-content: center;
  background: transparent;
  box-shadow: none;
  width: 16px;
  height: 16px;
`;
