import * as Yup from "yup";

const ValidationEditParkingInformation = Yup.object().shape({
    heightRestrict:  Yup.number().min(1, "Too Short").typeError('Please enter number.If you want pass float number use . ').notRequired(),
    narrowAccess:  Yup.number().min(1, "Too Short").typeError('Please enter number.If you want pass float number use . ').notRequired(),
    wideAccess:  Yup.number().min(1, "Too Short").typeError('Please enter number.If you want pass float number use . ').notRequired(),
    additionalInformation: Yup.string().notRequired(),
    keyFob: Yup.bool().notRequired(),
    securityCode: Yup.bool().notRequired(),
    physicalKey: Yup.bool().notRequired(),
    gated: Yup.bool().notRequired(),
    permitRequired: Yup.bool().notRequired(),
});

export default ValidationEditParkingInformation;
