import React from "react";
import {LineDividerModal} from "../styles";

interface IProps {
    top: string;
    bottom: string;
    color?: string;
}

const DividerModal: React.FC<IProps> = ({ top, bottom, color }) => {
    return <LineDividerModal top={top} bottom={bottom} color={color} />;
};

export default DividerModal;
