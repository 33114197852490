import InboxContainer from "./InboxCard";
import ThreadsContainer from "./ThreadsCard";
import {Container} from "./styles";

const InboxCardContainer = () => {
  return (
    <Container>
      <InboxContainer />
      <ThreadsContainer />
    </Container>
  );
};

export default InboxCardContainer;
