import * as Yup from "yup";

const ValidationEditChargerInformation = Yup.object().shape({
  type: Yup.string().min(1, "Too Short"),
  chargerRate: Yup.string().min(1, "Too Short").max(20,"Too long"),
  hourlyRateFee: Yup.number().min(1, "Too Short").typeError('Please enter number.If you want pass float number use . '),
  minimumSessionFee: Yup.number().min(1, "Too Short").typeError('Please enter number'),
  chargerAdditionalInformation: Yup.string(),
  tethered: Yup.bool(),
});

export default ValidationEditChargerInformation;
