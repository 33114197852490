import { ConfigProvider } from "antd";
import { SWRConfig } from "swr";
import "antd/dist/antd.variable.min.css";
import "./antDesign.css";
import theme from "./theme";
import "./i18n";
import AppRouter from "./routes/AppRouter";
import { swrFetcher } from "./swrFetcher";

ConfigProvider.config({
  theme: {
    primaryColor: theme.colors.primary,
  },
});

function App() {
  return (
    <SWRConfig
      value={{
        shouldRetryOnError: true,
        loadingTimeout: 3000,
        errorRetryInterval: 5000,
        errorRetryCount: 2,
        fetcher: swrFetcher,
        revalidateOnFocus: false,
      }}
    >
      <ConfigProvider>
        <AppRouter />
      </ConfigProvider>
    </SWRConfig>
  );
}

export default App;
