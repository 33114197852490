import styled from "styled-components";
import { Button, Input } from "antd";
import TextArea from "antd/es/input/TextArea";

export const Wrapper = styled.div`
  display: flex;
  height: 270px;
  width: 100%;
  flex-grow: 1;
  overflow-y: scroll;
  overflow-x: hidden;
  position: relative;
  flex-direction: column;
`;

export const List = styled.ul`
  position: relative;
  height: 100%;
  right: 20px;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  gap: 2px;
  width: calc(100% + 20px);
  list-style: none;
`;

export const ListItem = styled.li`
  height: 30px;
  font-family: "Nunito";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;

  &:before {
    content: "• ";
    color: #2d2245;
  }
`;

export const ListItemValue = styled.span`
  padding-left: 10px;
  font-family: "Nunito";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: #b0b9c6;
`;

export const EditButton = styled(Button)`
  position: absolute;
  left: 80%;
  top: 0;
`;

export const TextAreaField = styled(TextArea)`
  position:relative;
  background: transparent !important;
  color: black !important;
  display:flex;
  right:15px;
  flex: 1;
  margin-top:1.8rem;
`;
