import styled from "styled-components";
import {Card} from "antd";
import {CardProps} from "antd/lib/card";

export const CardBody = styled.div`
  position:relative;
  width:29.625rem;
  height:30.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0;
  gap: 1rem;
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
`

export const CardContainer: typeof Card = styled(Card)<CardProps>`
  width: 34.625rem;
  height: 38.43rem;
  display: flex;
  align-items: center;
  flex-direction: column;
  border-radius: 1rem;
  padding: 5rem 2.5rem 5rem 2.5rem;
`
