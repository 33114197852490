import styled from "styled-components";

export const Title = styled.span`
  font-family: "Nunito";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  padding: 5px;
  line-height: 20px;
  color: #3b3558;
`;

export const TitleCard = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
  width: 100%;
  background: white;
  border: 1px solid white;
  border-radius: 16px;
`;
export const AboutTitle = styled.span`
  border: 1px solid #dddddd;
  border-radius: 2px;
  padding: 5px;
  font-family: "Nunito";
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 26px;
`;
