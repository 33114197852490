import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
`;

export const Col = styled.div`
  display: flex;
  flex-direction: column;
  flex: 0 0 48%;
`;

export const Row = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-around;
`;

export const Days = styled.span`
  display: flex;
  flex: 0 0 40%;
  justify-content: flex-start;
`;
