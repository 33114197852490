import { initializeApp } from "firebase/app"
import { getAuth } from "@firebase/auth"
import { getFirestore } from "@firebase/firestore"

// const firebaseConfig = {
//     apiKey: process.env.REACT_APP_FIRE_API_KEY,
//     authDomain: process.env.REACT_APP_FIRE_AUTH_DOMAIN,
//     projectId: process.env.REACT_APP_PROJECT_ID,
//     storageBucket: process.env.REACT_APP_FIRE_STORAGE_BUCKET,
//     messagingSenderId: process.env.REACT_APP_FIRE_MESSAGING_SENDER_ID,
//     appId: process.env.REACT_APP_FIRE_APP_ID,
// }

const firebaseConfig = {
    apiKey: "AIzaSyCM7ywqoir5UZFCIf3qKyehIZp7SV_7WNQ",
    authDomain: "joosup-66c96.firebaseapp.com",
    projectId: "joosup-66c96",
    storageBucket: "joosup-66c96.appspot.com",
    messagingSenderId: "684496206772",
    appId: "1:684496206772:web:02d577a48d8356a56910e1"
};


const fireBaseApp = initializeApp(firebaseConfig)

export const auth = getAuth(fireBaseApp)
export const db = getFirestore(fireBaseApp)


