import {Form, message} from "antd";
import { Formik, FormikValues } from "formik";
import { useTranslation } from "react-i18next";
import {useNavigate} from "react-router-dom";
import Cookies from "universal-cookie";
import {AxiosError} from "axios";
import getLoginUrl from "../../consts/getLoginUrl";
import ILoginBody from "../../types/rest/login/login";
import { ILoginForm } from "../../types/ILoginForm";
import ValidationLoginSchema from "../../validationSchema/ValidationLoginSchema";
import Axios from "../../serverConfig";
import {NAVIGATION} from "../../enums/navigation";
import TextInput from "../TextInput";
import {
  ForgetPasswordContainer,
  ForgetPasswordText,
  SubmitButton,
} from "./styles";

const initialValues: ILoginForm = {
  email: "",
  password: "",
};

const LoginForm = () => {
  const { t } = useTranslation();

  const navigate = useNavigate()
  const cookies = new Cookies();

  const errorMessage = (err:string) => message.error(err)

  const handleSubmitForm = async (values: FormikValues) => {
    if (values) {
      const body: ILoginBody = {
        email: values.email,
        password: values.password,
      };
      try {
        const res = await Axios.post(getLoginUrl(), body);
        if(res.data.token){
          cookies.set('access_token', res.data.token, { path: '/' });
        }
        navigate(NAVIGATION.USERS)
      } catch (err: unknown | AxiosError) {
        const error = err as AxiosError
        if(error.response){
          const msg:any = error.response.data
          errorMessage(msg.message)
        }
      }
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={ValidationLoginSchema}
      onSubmit={handleSubmitForm}
    >
      {({ handleSubmit, values, handleChange, errors, touched }) => (
        <Form
          onFinish={handleSubmit}
          name="basic"
          layout="vertical"
          autoComplete="off"
        >
          <Form.Item>
            <TextInput
              name="email"
              value={values.email}
              errors={errors.email}
              touched={touched.email}
              handleChange={handleChange}
            />
          </Form.Item>
          <Form.Item>
            <TextInput
              name="password"
              value={values.password}
              handleChange={handleChange}
              errors={errors.password}
              touched={touched.password}
              passwordType={true}
            />
          </Form.Item>
          <ForgetPasswordContainer>
            <ForgetPasswordText href="/forgotPassword">
              {t("forgot_password")}
            </ForgetPasswordText>
          </ForgetPasswordContainer>
          <SubmitButton htmlType="submit">{t("sign_in")}</SubmitButton>
        </Form>
      )}
    </Formik>
  );
};

export default LoginForm;
