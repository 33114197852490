import { useEffect, useState } from "react";
import {Layout, message} from "antd";
import useSWR from "swr";
import EditorContent from "../../components/EditorContent/EditorContent";
import { Content, Header, Nav } from "../../layout";
import ContentEditorTitle from "../../components/ContentEditorTitle";
import PreviewAbout from "../../components/Modal/Previews/PreviewAbout";
import ContentEditorRightSide from "../../components/ContentEditorRightSide";
import getContentUrl from "../../consts/getContentUrl";
import { IContent } from "../../types/rest/editor/IContent";
import { CONTENT_EDITOR_TITLE } from "../../enums/contentEditorTitle";
import Axios from "../../serverConfig";
import { CONTENT_EDITOR_TYPE } from "../../enums/contentEditorType";
import getEditContentUrl from "../../consts/getEditContentUrl";
import { RightSection } from "../Users/styles";
import {
  ContentTextHeader,
  EditorCard,
  LeftCard,
  LeftSide,
  WrapperCard,
} from "./styles";

const About = () => {
  const [isOpenPreview, setIsOpenPreview] = useState(false);
  const { data, isValidating } = useSWR(getContentUrl());
  const [stateEditor, setStateEditor] = useState<IContent>();

  const errorMessage = () => message.error("Sorry something going wrong!")
  const successMessage = () => message.success("Success!")

  useEffect(() => {
    if (!data) {
      return;
    }
    handleData();
  }, [isValidating, data]);

  const handleData = () => {
    try {
      data.map((item: IContent) => {
        if (
          item.title === CONTENT_EDITOR_TITLE.ABOUT &&
          item.type === CONTENT_EDITOR_TYPE.SINGLE
        )
          setStateEditor(item);
      });
    } catch (e) {
       errorMessage()
    }
  };

  const handleToggle = () => {
    setIsOpenPreview((prevState) => !prevState);
  };

  const handleSubmit = async () => {
    try {
      if (!stateEditor) {
        return;
      }
      await Axios.post(getEditContentUrl(), {
        id: stateEditor.id,
        type: stateEditor.type,
        title: stateEditor.title,
        items: [
          {
            markdown: stateEditor.items.map((item) => item.markdown),
          },
        ],
      });
      successMessage()
    } catch (err: unknown) {
      errorMessage()
    }
  };

  if (isValidating) {
    return <p>Loading..</p>;
  }
  if (!stateEditor) {
    return <p>something going wrong</p>;
  }

  return (
    <>
      <PreviewAbout
        data={stateEditor}
        handleToggle={handleToggle}
        isOpenPreview={isOpenPreview}
      />
      <Layout>
        <Nav />
        <RightSection>
          <Header />
          <Content>
            <WrapperCard>
              <LeftSide>
                <LeftCard>
                  <ContentEditorTitle nameSection={stateEditor.title} />
                  <EditorCard>
                    <ContentTextHeader>Content</ContentTextHeader>
                    <EditorContent
                      setData={setStateEditor}
                      data={stateEditor}
                    />
                  </EditorCard>
                </LeftCard>
              </LeftSide>
              <ContentEditorRightSide
                handleToggle={handleToggle}
                lastEditionDate={stateEditor.items}
                onSubmit={handleSubmit}
              />
            </WrapperCard>
          </Content>
        </RightSection>
      </Layout>
    </>
  );
};

export default About;
