import styled from "styled-components";
import { Button } from "antd";

export const Wrapper = styled.div`
  position:relative;
  display: flex;
  width: 100%;
  gap: 20px;
  flex-direction: column;
`;
export const Row = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;
export const Days = styled.span`
  font-family: "Nunito";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
`;
export const Time = styled.span`
  font-family: "Nunito";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: #2d2245;
`;

export const HourButton = styled(Button)`
  border:none;
`

export const DateButton = styled(Button)`
  display: flex;
  width:200px;
  justify-content: flex-start;
  position: relative;
  right:20px;
  gap:5px;
  border: none;
  box-shadow: none;
`;
