import styled from "styled-components";
import {Button, Select} from "antd";

export const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 10px;
`;

export const ButtonSubmit: typeof Button = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 63px;
  height: 36px;
  padding: 8px 16px 8px 16px;
  background: #32a6d5;
  border-radius: 2px;
  box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.043);
  color: #ffffff;
  font-family: Nunito;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
`;
export const CancelButton: typeof Button = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 63px;
  height: 36px;
  padding: 8px 16px 8px 16px;
  border-radius: 2px;
  background: #ffffff;
  border: 1px solid #d9d9d9;
`;
export const SelectCar = styled.select`
  height: 32px;
  display: flex;
  flex: 0 0 75%;
  background: white;
  border: 1px solid #d9d9d9;
  padding: 5px 6px;
  border-radius: 2px;
  align-items: center;

  &:focus {
    outline-color: #d9d9d9;
    border: 1px solid #d9d9d9;
    box-shadow: none !important;
  }
`;
export const DateContainer = styled.div`
  height: 32px;
  display: flex;
  align-items: center;
  border: 1px solid #d9d9d9;
  padding-left: 6px;
  align-items: center;
  width: 100%;
  justify-content: space-between;
`;

export const Row = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
`;
export const Label = styled.label`
  display: flex;
  flex: 0 0 25%;
  font-family: Nunito;
  font-weight: 400;
  font-style: normal;
  font-size: 0.875rem;
  line-height: 1.375rem;
  color: rgba(0, 0, 0, 0.85);
`;

export const RowPhoto = styled.div`
  display: flex;
  flex-direction: row;
  gap: 5px;
`;

export const SelectItems = styled(Select)`
  height: 32px;
  display: flex;
  flex: 0 0 75%;
  background: white;
  border: 1px solid #d9d9d9;
  padding: 5px 6px;
  border-radius: 2px;
  align-items: center;

  &:focus {
    outline-color: #d9d9d9;
    border: 1px solid #d9d9d9;
    box-shadow: none !important;
  }
`

export const RowModal = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
`;
