import React from "react";
import {CirclePhoto} from "../../Cards/CardUserData/styles";
import renderStars from "../../../helpers/createRateStar";
import {
  OpinionDate,
  OpinionRightSide,
  OpinionSection,
  OpinionStar,
  OpinionUserDate,
  OpinionUserName,
} from "./styles";

interface IProps {
  comment: string;
  date: string;
  rating: number;
  handle: string;
  image: string;
}

const Opinion: React.FC<IProps> = ({ comment, date, rating, handle, image }) => {
  return (
    <OpinionSection>
      {image? <img src={`data:image/png;base64,${image}`} width={45} height={45} style={{borderRadius:"50%"}}/> : <CirclePhoto>{handle[0].toUpperCase()}</CirclePhoto>}
      <OpinionRightSide>
        <OpinionUserDate>
          <OpinionUserName>{handle}</OpinionUserName>
          <OpinionDate>{date}</OpinionDate>
        </OpinionUserDate>
        <OpinionStar>{renderStars(rating)}</OpinionStar>
        <span>{comment}</span>
      </OpinionRightSide>
    </OpinionSection>
  );
};

export default Opinion;
