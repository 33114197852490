import React from "react";
import { AboutTitle, TitleCard,Title } from "./styles";

interface IProps {
    nameSection : string
}

const ContentEditorTitle:React.FC<IProps> = ({nameSection}) => {
    return (
      <TitleCard>
        <Title>Title</Title>
        <AboutTitle>{nameSection}</AboutTitle>
      </TitleCard>
    );
}

export default ContentEditorTitle;
