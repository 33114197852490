import React from "react";

const DarkCharger1 = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="26"
      height="30"
      viewBox="0 0 26 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.6909 2.97928C21.891 4.49296 25.6909 9.29405 25.6909 14.9827C25.6909 20.6713 21.891 25.4724 16.6909 26.986V28.2217C16.6909 28.774 16.2432 29.2217 15.6909 29.2217H10.6909C10.1386 29.2217 9.69092 28.774 9.69092 28.2217V26.986C4.49088 25.4724 0.690918 20.6713 0.690918 14.9827C0.690918 9.29405 4.49088 4.49296 9.69092 2.97928V1.72168C9.69092 1.1694 10.1386 0.72168 10.6909 0.72168H15.6909C16.2432 0.72168 16.6909 1.16939 16.6909 1.72168V2.97928ZM8.69092 14.4827C10.3478 14.4827 11.6909 13.1395 11.6909 11.4827C11.6909 9.82581 10.3478 8.48267 8.69092 8.48267C7.03406 8.48267 5.69092 9.82581 5.69092 11.4827C5.69092 13.1395 7.03406 14.4827 8.69092 14.4827ZM17.6909 14.4827C19.3478 14.4827 20.6909 13.1395 20.6909 11.4827C20.6909 9.82581 19.3478 8.48267 17.6909 8.48267C16.0341 8.48267 14.6909 9.82581 14.6909 11.4827C14.6909 13.1395 16.0341 14.4827 17.6909 14.4827ZM16.1909 20.4827C16.1909 22.1395 14.8478 23.4827 13.1909 23.4827C11.5341 23.4827 10.1909 22.1395 10.1909 20.4827C10.1909 18.8258 11.5341 17.4827 13.1909 17.4827C14.8478 17.4827 16.1909 18.8258 16.1909 20.4827ZM19.6909 20.4827C20.7955 20.4827 21.6909 19.5872 21.6909 18.4827C21.6909 17.3781 20.7955 16.4827 19.6909 16.4827C18.5863 16.4827 17.6909 17.3781 17.6909 18.4827C17.6909 19.5872 18.5863 20.4827 19.6909 20.4827ZM8.69092 18.4827C8.69092 19.5872 7.79549 20.4827 6.69092 20.4827C5.58635 20.4827 4.69092 19.5872 4.69092 18.4827C4.69092 17.3781 5.58635 16.4827 6.69092 16.4827C7.79549 16.4827 8.69092 17.3781 8.69092 18.4827Z"
        fill="#3B3558"
      />
    </svg>
  );
};

export default DarkCharger1;
