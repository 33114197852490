import React from "react";

const UserIcon = (props: React.SVGProps<SVGSVGElement>) => {
    return (<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M2.53995 16.106C1.85699 15.9487 1.35598 15.7929 1.02957 15.6783C1.29813 13.8525 3.00462 11.8173 5.53768 10.7436C5.89426 10.5925 6.12908 10.6119 6.61643 10.7203C6.67489 10.7333 6.73601 10.7473 6.80005 10.7621C7.30738 10.8789 7.99796 11.0378 8.99912 11.0378C10.0003 11.0378 10.6915 10.8789 11.1992 10.7621C11.2632 10.7474 11.3243 10.7333 11.3828 10.7203C11.871 10.6119 12.1053 10.5927 12.46 10.7434C14.9971 11.8212 16.7007 13.8709 16.9701 15.703C16.6433 15.8151 16.1437 15.9668 15.4642 16.1198C14.0798 16.4316 11.9459 16.7499 9.00059 16.7499C6.05653 16.7499 3.92349 16.4246 2.53995 16.106Z"
                stroke="#32A6D5" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path
                d="M5.37124 4.88088H5.37124V4.87833C5.37124 2.87446 6.9962 1.25 9.00047 1.25C11.0045 1.25 12.6288 2.87425 12.6288 4.87833C12.6288 6.88262 11.0043 8.50758 9.00047 8.50758H8.97108C6.97676 8.50023 5.36447 6.87662 5.37124 4.88088Z"
                stroke="#32A6D5" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    )
}

export default UserIcon