import Axios from "./serverConfig";

export const swrFetcher = (url: string, config?: string | undefined) => {
    let axiosConfig = {};
    if (config) {
        axiosConfig = JSON.parse(config);
    }
    return Axios({
        url,
        ...axiosConfig,
    })
        .then((res:any) => {
            return res.data;
        })
        .catch((err:any) => {
            throw err;
        });
};

