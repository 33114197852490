import {createContext, Dispatch, SetStateAction} from "react";

interface IContext {
    date:string | null
    setDate: Dispatch<SetStateAction<string>>
    bookingId:string,
    setBookingId:Dispatch<SetStateAction<string>>,
    id: number,
    handleId:(x:number )=>void
    activeChannel:string | null
    handleChannelId:(channel:string)=>void
    activeTab:number
    handleActiveTab:(id:number)=>void
}

const TabsContext = createContext<IContext>({} as IContext)

export default TabsContext

