import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height:100%;
`;

export const LeftSide = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  flex: 0 0 10%;
`;

export const BookingDay = styled.span`
  padding-right: 10px;
`;
export const BookingDayNumber = styled.span`
  font-family: Nunito;
  font-weight: 700;
  font-size: 26px;
  line-height: 35px;
  color: #3b3558;
  padding-right: 10px;
`;
export const Right = styled.div`
  display: flex;
  padding-left: 5px;
  padding-top: 5px;
  padding-bottom: 5px;
  flex-direction: column;
  height:100%;
  flex: 0 0 90%;
  gap: 10px;
  overflow-y: scroll;
`;
