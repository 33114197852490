import React, { useState } from "react";
import moment, {utc} from "moment";
import convertDays, {
  convertArrayToDaysLimit,
} from "../../../../../../../helpers/convertDays";
import EditAvailability from "../../../../../../Modal/EditAvailabilityInformation";
import {
  IAvailability,
  IChargerDetails,
} from "../../../../../../../types/rest/charger/IChargerDetails";
import { AddButton, PencilEdit } from "../../../../../../../icons";
import NewAvailability from "../../../../../../Modal/NewAvailability";
import { Wrapper, Row, Days, Time, DateButton, HourButton } from "./styles";

interface IProps {
  details: IChargerDetails;
  setDetails: React.Dispatch<React.SetStateAction<IChargerDetails | undefined>>;
}

const Availability: React.FC<IProps> = ({ details, setDetails }) => {
  const [toggleOpenEditor, setToggleOpenEditor] = useState(false);
  const [toggleOpenNewAvailability, setToggleOpenNewAvailability] = useState(false);

  const [editAvailability, setEditAvailability] = useState<IAvailability>();

  const handleToggle = () => setToggleOpenEditor((prevState) => !prevState);

  const handleToggleNewAvailability = () =>{
    setToggleOpenNewAvailability((prevState) => !prevState);}

  const handleGetEditId = (id: number) => {
    const item = chargerAvailability.find((item) => item.id === id);
    setEditAvailability(item);
    handleToggle();
  };

  const { chargerAvailability } = details;

  return (
    <>
      {toggleOpenEditor && (
        <EditAvailability
          editAvailability={editAvailability}
          toggleOpenEditor={toggleOpenEditor}
          handleToggle={handleToggle}
          details={details}
          setDetails={setDetails}
        />
      )}
      {toggleOpenNewAvailability && (
        <NewAvailability
          toggleOpenEditor={toggleOpenNewAvailability}
          handleToggle={handleToggleNewAvailability}
          details={details}
          setDetails={setDetails}
        />
      )}
      <Wrapper>
        {chargerAvailability.map((item) => {
          const { startTime, endTime, id, ...rest } = item;
          const availableDays = convertDays(rest).map((day) => convertArrayToDaysLimit(day))
          return (
            <Row>
              <Days>
                {availableDays}
              </Days>
              <div>
                {availableDays.length >0 &&  (
                    <>
                    <Time>
                      {(moment(startTime)).format("HH:mm")}
                       -
                      {endTime && (moment(endTime)).format("HH:mm")}
                    </Time>
                {startTime && endTime && (
                  <HourButton
                  onClick={() => handleGetEditId(id!)}
                  icon={<PencilEdit />} />
                  )}
                 </> ) }
              </div>
            </Row>
          );
        })}

        <DateButton icon={<AddButton />} onClick={handleToggleNewAvailability}>
          Add new time range
        </DateButton>
      </Wrapper>
    </>
  );
};

export default Availability;
