import React from "react";
import { DividerWithMargin } from "../../Divider";
import { CardItem, CardItemWithOverflow, CardTitle } from "./styles";

interface IProps {
  children?: JSX.Element | JSX.Element[];
  title?: string;
  overflow?: boolean;
}

const Card: React.FC<IProps> = ({ children, title, overflow }) => {
  if (overflow) {
    return (
      <CardItemWithOverflow>
        <CardTitle>{title}</CardTitle>
        <DividerWithMargin top={"10px"} bottom={"24px"} />
        {children}
      </CardItemWithOverflow>
    );
  }
  return (
    <CardItem>
      <CardTitle>{title}</CardTitle>
      <DividerWithMargin top={"10px"} bottom={"15px"} />
      {children}
    </CardItem>
  );
};

export default Card;
