import React, { ReactNode } from "react";
import { InputField, Label, RowModal } from "./styles";

interface IProps {
  addonAfter?: ReactNode;
  name: string;
  labelText?: string;
  value: string;
  errors?: string;
  touched?: boolean;
  handleChange: {
    (e: React.ChangeEvent<any>): void;
    <T = string | React.ChangeEvent<any>>(
      field: T
    ): T extends React.ChangeEvent<any>
      ? void
      : (e: string | React.ChangeEvent<any>) => void;
  };
  disabled?: boolean;
}

const TextInputRow: React.FC<IProps> = ({
  addonAfter,
  name,
  labelText,
  value,
  errors,
  touched,
  handleChange,
  disabled,
}) => {
  return (
    <>
      <RowModal>
        <Label>{labelText}</Label>
        <InputField
          name={name}
          value={value}
          onChange={handleChange}
          addonAfter={addonAfter}
          disabled={disabled}
        />
      </RowModal>
      {errors && touched ? <div>{errors}</div> : null}
    </>
  );
};

export default TextInputRow;
