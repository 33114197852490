import { useState } from "react";
import { Button } from "antd";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { NAVIGATION } from "../../enums/navigation";
import ResetPasswordForm from "../../components/ResetPasswordForm";
import Success from "../../icons/Success";
import { Logo } from "../../icons";
import {
    CardHeader,
    LoginText,
    Wrapper,
} from "../Login/styles";
import { TextReset } from "../ForgotPassword/styles";
import { CardBody, CardContainer } from "./styles";

const ResetPassword = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const [isPasswordReset, setIsPasswordReset] = useState(false);

    const handleRedirect = () => navigate(NAVIGATION.LOGIN);
    const handleResetPassword = () => setIsPasswordReset(true)

    if (isPasswordReset) {
        return (
            <Wrapper>
                <CardContainer>
                    <CardHeader>
                        <Logo width={270} height={71} />
                    </CardHeader>
                    <CardBody>
                        <Success/>
                        <LoginText>Congratulations!</LoginText>
                        <TextReset>
                            Your password has been reset. Log into your account to continue
                        </TextReset>
                        <Button onClick={handleRedirect}>Back</Button>
                    </CardBody>
                </CardContainer>
            </Wrapper>
        );
    }

    return (
        <Wrapper>
            <CardContainer>
                <CardHeader>
                    <Logo width={270} height={71} />
                </CardHeader>
                <CardBody>
                    <LoginText>Reset Password</LoginText>
                    <TextReset>
                        Enter new password
                    </TextReset>
                    <ResetPasswordForm handleResetPassword={handleResetPassword} />
                </CardBody>
            </CardContainer>
        </Wrapper>
    );
};

export default ResetPassword;
