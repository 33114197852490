import React from "react";
import BottomBiggerCard from "../../../Cards/BottomBiggerCard";
import CalendarComponent from "./CalendarComponent";

const CalendarContainer = () => {
  return (
    <BottomBiggerCard title={"Calendar"}>
      <CalendarComponent />
    </BottomBiggerCard>
  );
};

export default CalendarContainer;
