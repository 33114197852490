import { IUsersRest } from "../types/rest/user/IUsersRest";

const parseNullToEmptyStringArray = (data: IUsersRest[]) => {
  const parsedData = data.map((item) =>
    Object.fromEntries(
      Object.entries(item).map(([key, value]) =>
        value === null ? [key, "none"] : [key, value]
      )
    )
  )as IUsersRest[]
  return parsedData
}


export default parseNullToEmptyStringArray;
