import styled from "styled-components";

export const InboxCardsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  height:100%;
`;

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 10px;
`;
