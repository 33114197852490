import styled from "styled-components";
import { Divider, DividerProps } from "antd";

interface customPropsOfDivider extends DividerProps {
  top: string,
  bottom: string,
  color?: string,
}

export const LineDivider = styled(Divider)<customPropsOfDivider>`
  margin-top: ${(props) => props.top} !important;
  margin-bottom: ${(props) => props.bottom} !important;
  padding: 0 !important;
  width: 100vw;
  margin-left: -10%;
  background-color: ${(props)=>props.color};
`

export const LineDividerNoMargin = styled(Divider)<customPropsOfDivider>`
  margin-top: ${(props) => props.top} !important;
  margin-bottom: ${(props) => props.bottom} !important;
  padding: 0 !important;
  width: 80%;
  background-color: ${(props)=>props.color};
`

export const LineDividerModal = styled(Divider)<customPropsOfDivider>`
  margin-top: ${(props) => props.top} !important;
  margin-bottom: ${(props) => props.bottom} !important;
  padding: 0 !important;
  position: relative;
  right: 15px;
  width: calc(100% + 5%);
`
