import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import useSWR from "swr";
import moment from "moment";
import renderStars from "../../../../../helpers/createRateStar";
import { UserProfile } from "../../../../../icons";
import ChargerImg from "../../../../../icons/Charger";
import dateContext from "../../../../../context/tabsContext";
import getBookingDetails from "../../../../../consts/getBookingDetails";
import handleSumTimeBooking from "../../../../../helpers/handleSumTimeBooking";
import handleCostBooking from "../../../../../helpers/handleCostBooking";
import { ICalendar } from "../../../../../types/rest/calendar/ICalendar";
import {IBookingUser} from "../../../../../types/rest/bookingUser/IBookingUser";
import handleRoundPrice from "../../../../../helpers/handleRoundPrice";
import { DividerWithMargin, DividerWithNoMargin } from "../../../../Divider";
import {
  ChargerCardRate,
  Rate,
} from "../../../Chargers/ChargersCard/Charger/styles";
import {
  BookingScheduleContainer,
  VehicleName,
  VehicleNameAndStatus,
  VehicleStatus,
  Wrapper,
  Row,
  Time,
  CardTime,
  Card,
  CardDate,
  Rectangle,
  OccurrenceContainer,
  Title,
  UserContent,
  UserHandle,
  UserFullName,
  UserHandleAndFullName,
  ChargerContainer,
  ChargerImage,
  ChargerType,
  RightSide,
  ChargerName,
  ChargerAddress,
  SummaryCard,
  SummaryRow,
  SummaryTextStart,
  SummaryTextEnd,
  MinimumSessionFee,
  MinimumSessionValue,
  AnticipatedCost,
} from "./styles";
import getUserDetailUrl from "../../../../../consts/getUserDetailUrl";
import {CirclePhoto} from "../../../../Cards/CardUserData/styles";

const VehicleCharger = () => {
  const [chargerDetails, setChargerDetails] = useState<any>();
  const [userImg,setUserImg] = useState("")

  const params = useParams();
  const { userId } = params;

  const { bookingId } = useContext(dateContext);

  const { data } = useSWR(getBookingDetails(userId));
  const { data: userData, isValidating } = useSWR(getUserDetailUrl(chargerDetails?.contactUserId));

  let allBookings: IBookingUser[] = [];

  useEffect(()=>{
    if(!userData){
      return
    }
    setUserImg(userData.image)
  },[isValidating])

  useEffect(() => {
    if (!data) {
      return;
    }

    Object.values(data).forEach((item: any) => {
      item.forEach((val: ICalendar) => {
        allBookings.push(JSON.parse(val.name));
      });
    });

    const bookingsWithDate = allBookings.map((item: any) => {
      if (item.bookingId === bookingId) {
        return item;
      }
      return;
    });
    const filteredArr = bookingsWithDate.filter((item: any) => item !== undefined);
    if (!filteredArr.length) {
      return;
    }
    filteredArr.forEach((item) => {
      setChargerDetails({
        vehicleName: item.vehicleName,
        minimumSessionFee: item.minimumSessionFee,
        status: item.status,
        startTime: item.startSession,
        endTime: item.endSession,
        contactHandle: item.contactHandle,
        contactFullName: `${item?.contactFirstName}  ${item.contactLastName}`,
        chargerName: item.chargerName,
        chargerAddress: `${item.chargerAddress.number} ${item.chargerAddress.street} ${item.chargerAddress.locality} ${item.chargerAddress.city} ${item.chargerAddress.postCode}`,
        hourlyRateFee: item.hourlyRateFee,
        averageRating: item.averageRating,
        chargerType: item.chargerType,
        contactUserId: item.contactUserId
      });
    });
  }, [bookingId]);

  if (!chargerDetails) {
    return (
      <Wrapper>
        <p>Empty</p>
      </Wrapper>
    );
  }

  if (!Object.keys(chargerDetails).length) {
    return (
      <Wrapper>
        <p>Empty</p>
      </Wrapper>
    );
  }

  const sumTime = handleSumTimeBooking(chargerDetails.startTime,chargerDetails.endTime).toFixed(0).toString()

  return (
    <Wrapper>
      <VehicleNameAndStatus>
        <VehicleName>{chargerDetails.vehicleName}</VehicleName>
        <VehicleStatus>{chargerDetails.status}</VehicleStatus>
      </VehicleNameAndStatus>
      <DividerWithMargin top={"12px"} bottom={"12px"} />
      <BookingScheduleContainer>
        <Title>Booking Schedule</Title>
        <Row>
          <Time>Start Time</Time>
          <Time>End Time</Time>
        </Row>
        <Row>
          <Card>
            <CardDate>
              {moment(chargerDetails.startTime).format("DD-MM-YYYY")}
            </CardDate>
            <CardTime>
              {moment(chargerDetails.startTime).format("HH:mm")}
            </CardTime>
          </Card>
          <Rectangle />
          <Card>
            <CardDate>
              {moment(chargerDetails.endTime).format("DD-MM-YYYY")}
            </CardDate>
            <CardTime>
              {moment(chargerDetails.endTime).format("HH:mm")}
            </CardTime>
          </Card>
        </Row>
        <DividerWithMargin top={"12px"} bottom={"12px"} />
        <OccurrenceContainer>
          <Row>
            <Title>Occurrence</Title>
            <Time>One time</Time>
          </Row>
          <DividerWithMargin top={"12px"} bottom={"12px"} />
          <UserContent>
            {!userImg ? (
                <CirclePhoto>{chargerDetails.contactFullName[0].toUpperCase()}</CirclePhoto>
            ) : (
                <img
                    src={`data:image/png;base64,${userImg}`}
                    width={45}
                    height={45}
                    style={{ borderRadius: "50%" }}
                />
            )}
            <UserHandleAndFullName>
              <UserHandle>{chargerDetails.contactHandle} </UserHandle>
              <UserFullName>{chargerDetails.contactFullName}</UserFullName>
            </UserHandleAndFullName>
          </UserContent>
          <ChargerContainer>
            <ChargerImage>
              <ChargerImg />
              <ChargerType>{chargerDetails.chargerType}</ChargerType>
            </ChargerImage>
            <RightSide>
              <ChargerCardRate>
                {renderStars(Math.round(chargerDetails.averageRating))}
                <Rate>{chargerDetails.averageRating}</Rate>
              </ChargerCardRate>
              <ChargerName>{chargerDetails.chargerName}</ChargerName>
              <ChargerAddress>{chargerDetails.chargerAddress}</ChargerAddress>
            </RightSide>
          </ChargerContainer>
        </OccurrenceContainer>
        <DividerWithMargin top={"12px"} bottom={"12px"} />
        <Title>Summary</Title>
        <SummaryCard>
          <SummaryRow>
            <SummaryTextStart>Planned Charging Duration</SummaryTextStart>
            <SummaryTextEnd>
              {sumTime} hour
            </SummaryTextEnd>
          </SummaryRow>
          <SummaryRow>
            <SummaryTextStart>Hourly Rating</SummaryTextStart>
            <SummaryTextEnd>£ {handleRoundPrice(chargerDetails.hourlyRateFee)}</SummaryTextEnd>
          </SummaryRow>
          <SummaryRow>
            <MinimumSessionFee>Minimum Session fee</MinimumSessionFee>
            <MinimumSessionValue>
              £ {handleRoundPrice(chargerDetails.minimumSessionFee)}
            </MinimumSessionValue>
          </SummaryRow>
          <DividerWithNoMargin top={"2px"} bottom={"2px"} />
          <SummaryRow>
            <AnticipatedCost>Anticipated Cost</AnticipatedCost>
            <AnticipatedCost>£ {handleRoundPrice(handleCostBooking(parseInt(sumTime),chargerDetails.hourlyRateFee,chargerDetails.minimumSessionFee).toString())}</AnticipatedCost>
          </SummaryRow>
        </SummaryCard>
      </BookingScheduleContainer>
    </Wrapper>
  );
};

export default VehicleCharger;
