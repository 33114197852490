import {IUserDetails,IUserDetailsWithNull} from "../types/rest/user/IUserDetails";

export const parseUserDetail = (data:IUserDetailsWithNull) => {
    const parsedData = Object.fromEntries(Object.entries(data).map(([key, value]) =>
            value === null ? [key, "none"] : [key, value]
        )
    )as IUserDetails
    return parsedData;
}

export default parseUserDetail
