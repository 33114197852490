import React, { useRef, useState } from "react";
import moment from "moment";
import { Formik } from "formik";
import { useTranslation } from "react-i18next";
import {
  Button,
  DatePicker,
  DatePickerProps,
  Form,
  message,
  Modal, Popconfirm,
} from "antd";
import Axios from "../../../serverConfig";
import { IUserData } from "../../../types/IUserData";
import { getEditUserDetailUrl } from "../../../consts/getEditUserDetailUrl";
import ValidationUserEditSchema from "../../../validationSchema/ValidationUserEditSchema";
import { IUserAddress } from "../../../types/IUserAdress";
import getDeleteUserImage from "../../../consts/getDeleteUserImage";
import { TextInputRow } from "../../index";
import {
  ButtonSubmit,
  ButtonsWrapper,
  CancelButton,
  Row,
  Label,
  DateContainer,
  RowPhoto,
} from "../styles";

interface IProps {
  toggleOpenEditor: boolean;
  handleToggle: () => void;
  userData: IUserData;
  setUserData: React.Dispatch<React.SetStateAction<IUserData | undefined>>;
}

const EditUser: React.FC<IProps> = ({
  toggleOpenEditor,
  handleToggle,
  userData,
  setUserData,
}) => {
  const { t } = useTranslation();
  const [newDateBirth, setNewDateBirth] = useState(userData.dateOfBirth);

  const errorMessage = () => message.error("Sorry something going wrong!");
  const successEditMessage = () =>
    message.success("Success, user data information changed");

  const parseNullAddressToNone: IUserAddress = Object.fromEntries(
    Object.entries(userData.address).map(([key, value]) =>
      value === null ? [key, "none"] : [key, value]
    )
  ) as any;

  const { address: addressFromUserData, ...rest } = userData;

  const initialState: IUserData = {
    ...rest,
    address: parseNullAddressToNone,
  };

  const initialValues: IUserData = {
    ...initialState,
  };

  const formikRef = useRef<any>();

  const handleSubmitForm = async (values: IUserData) => {
    if (!values) {
      return;
    }
    try {
      const { dateOfBirth, id, isHost, ratings, ...rest } = values;
      setUserData({
        id: userData.id,
        isHost: userData.isHost,
        ...rest,
        dateOfBirth: newDateBirth,
      });
      await Axios.post(getEditUserDetailUrl(), {
        id: userData.id,
        dateOfBirth: newDateBirth,
        ...rest,
      });
      successEditMessage();
      handleToggle();
    } catch (err: unknown) {
      errorMessage();
    }
  };

  const handleDateOfBirthDay: DatePickerProps["onChange"] = (
    date,
    dateString
  ) => {
    setNewDateBirth(dateString);
  };

  const handleFormikClick = () => {
    if (formikRef?.current !== null) {
      formikRef.current.handleSubmit();
    }
  };

  const handleDeleteImage = async () => {
    try {
      await Axios.delete(getDeleteUserImage(userData.id));
      successEditMessage();
    } catch (err: unknown) {
      errorMessage();
    }
  };

  let defaultDateTime = moment(new Date());

  if (userData.dateOfBirth !== "none") {
    defaultDateTime = moment(userData.dateOfBirth)
  }

  return (
    <Modal
      mask={true}
      maskStyle={{ backdropFilter: "blur(8px)" }}
      title="Change user data"
      centered
      visible={toggleOpenEditor}
      onOk={handleFormikClick}
      onCancel={handleToggle}
    >
      <Formik
        innerRef={formikRef}
        initialValues={initialValues}
        validationSchema={ValidationUserEditSchema}
        onSubmit={handleSubmitForm}
      >
        {({
          handleSubmit,
          values,
          handleChange,
          errors,
          touched,
          setFieldValue,
        }) => (
          <Form
            onFinish={handleSubmit}
            name="basic"
            layout="vertical"
            autoComplete="off"
          >
            <Form.Item name={"profilePicture"}>
              <RowPhoto>
                <Label>{t("photo")}</Label>
                <Popconfirm placement="right" title={"Are you sure?"} onConfirm={handleDeleteImage} okText="Yes" cancelText="No">
                  <Button  style={{ borderColor: "#D5324F", color: "#D5324F" }}>Delete Photo</Button>
                </Popconfirm>
              </RowPhoto>
            </Form.Item>
            <Form.Item>
              <TextInputRow
                labelText={t("handle")}
                name={"handle"}
                value={values.handle}
                handleChange={handleChange}
                errors={errors.handle}
                touched={touched.handle}
              />
            </Form.Item>
            <Form.Item>
              <TextInputRow
                labelText={t("firstName")}
                name={"firstName"}
                value={values.firstName}
                handleChange={handleChange}
                errors={errors.firstName}
                touched={touched.firstName}
              />
            </Form.Item>
            <Form.Item>
              <TextInputRow
                labelText={t("lastName")}
                name={"lastName"}
                value={values.lastName}
                handleChange={handleChange}
                errors={errors.lastName}
                touched={touched.lastName}
              />
            </Form.Item>
            <Form.Item>
              <TextInputRow
                labelText={t("company")}
                name={"company"}
                value={values.company}
                handleChange={handleChange}
                errors={errors.company}
                touched={touched.company}
              />
            </Form.Item>
            <Form.Item>
              <TextInputRow
                labelText={t("email")}
                name={"email"}
                value={values.email}
                handleChange={handleChange}
                errors={errors.email}
                touched={touched.email}
              />
            </Form.Item>
            <Form.Item>
              <TextInputRow
                labelText={t("phone")}
                name={"phone"}
                value={values.phone}
                handleChange={handleChange}
                errors={errors.phone}
                touched={touched.phone}
              />
            </Form.Item>
            <Form.Item>
              <TextInputRow
                  labelText={"Building Number/Name"}
                  name={"address.number"}
                  value={values.address.number}
                  handleChange={handleChange}
                  errors={errors.address?.number}
                  touched={touched.address?.number}
              />
            </Form.Item>
            <Form.Item>
              <TextInputRow
                  labelText={"Street address"}
                  name={"address.street"}
                  value={values.address.street}
                  handleChange={handleChange}
                  errors={errors.address?.street}
                  touched={touched.address?.street}
              />
            </Form.Item>
            <Form.Item>
              <TextInputRow
                  labelText={"Locality"}
                  name={"address.locality"}
                  value={values.address.locality}
                  handleChange={handleChange}
                  errors={errors.address?.locality}
                  touched={touched.address?.locality}
              />
            </Form.Item>
            <Form.Item>
              <TextInputRow
                labelText="Town/City"
                name={"address.city"}
                value={values.address.city}
                handleChange={handleChange}
                errors={errors.address?.city}
                touched={touched.address?.city}
              />
            </Form.Item>
            <Form.Item>
              <TextInputRow
                labelText={t("postCode")}
                name={"address.postCode"}
                value={values.address.postCode}
                handleChange={handleChange}
                errors={errors.address?.postCode}
                touched={touched.address?.postCode}
              />
            </Form.Item>
            <Form.Item>
              <Row>
                <Label>Date Of Birth</Label>
                <DateContainer>
                  <DatePicker
                    name={t("dateOfBirth")}
                    defaultValue={defaultDateTime}
                    bordered={false}
                    onChange={handleDateOfBirthDay}
                  />
                </DateContainer>
              </Row>
            </Form.Item>
            <ButtonsWrapper>
              <CancelButton onClick={handleToggle}>Cancel</CancelButton>
              <ButtonSubmit htmlType="submit">Save</ButtonSubmit>
            </ButtonsWrapper>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};
export default EditUser;
