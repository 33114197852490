import { Layout } from "antd";
import { Content, Header, Nav } from "../../layout";
import ContentNav from "../../components/ContentEditorNav";
import { RightSection } from "../Users/styles";
import {
  Wrapper,
} from "./styles";

const ContentEditor = () => {
  return (
    <Layout>
      <Nav />
      <RightSection>
        <Header />
        <Content>
          <Wrapper>
           <ContentNav/>
          </Wrapper>
        </Content>
      </RightSection>
    </Layout>
  );
};

export default ContentEditor;
