import styled from "styled-components";
import { Button } from "antd";

export const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 10px;
`;

export const ButtonSubmitGreen: typeof Button = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 63px;
  height: 36px;
  padding: 8px 16px 8px 16px;
  background: #8BD033;
  border-radius: 2px;
  box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.043);
  color: #ffffff;
  font-family: Nunito;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
`;

export const ButtonSubmitRed: typeof Button = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 63px;
  height: 36px;
  padding: 8px 16px 8px 16px;
  background: #dc0028;
  border-radius: 2px;
  box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.043);
  color: #ffffff;
  font-family: Nunito;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
`;
export const CancelButton: typeof Button = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 63px;
  height: 36px;
  padding: 8px 16px 8px 16px;
  border-radius: 2px;
  background: #ffffff;
  border: 1px solid #d9d9d9;
`;

export const Title = styled.span`
  font-family: Nunito;
  font-size: 16px;
  line-height: 24px;
`;
