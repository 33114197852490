import React from "react";
import { Progress } from "antd";

const ProgressBar = ({
  percent,
  count,
}: {
  percent: number;
  count: number;
}) => {
  return (
    <>
      <Progress
        percent={percent}
        strokeColor="#FFE600"
        format={() => `${count}`}
      />
    </>
  );
};

export default ProgressBar;
