import * as Yup from "yup";

const ValidationEditLocationInformation = Yup.object().shape({
  name: Yup.string().min(2, "Too Short"),
  address:Yup.object().shape({
    city: Yup.string().nullable().max(50,"Too Long").notRequired(),
    number: Yup.string().nullable().max(50,"Too Long").notRequired(),
    street: Yup.string().nullable().max(50,"Too Long").notRequired(),
    postCode: Yup.string().nullable().notRequired(),
    locality:Yup.string().nullable().max(50,"Too Long").notRequired(),
  }),
  attendedCharging: Yup.bool(),
  coveredParking: Yup.bool(),
  cctv: Yup.bool(),
  wifi: Yup.bool(),
  lighting: Yup.bool(),
  disabledAccess: Yup.bool(),
});

export default ValidationEditLocationInformation;
