const convertArrayToRate = (ratings: number[]) => {
  let rate1 = 0;
  let rate2 = 0;
  let rate3 = 0;
  let rate4 = 0;
  let rate5 = 0;
  let count = 0

  ratings.map((item) => {
    switch (item) {
      case 1:
        rate1 += 1;
        count += 1
        break;
      case 2:
        rate2 += 1;
        count += 1
        break;
      case 3:
        rate3 += 1
        count += 1
        break;
      case 4:
        rate4 += 1
        count += 1
        break;
      case 5:
        rate5 += 1
        count += 1
        break;
    }
    return;
  });
  let sumRate = rate1 * 1 + rate2 * 2 + rate3 * 3 + rate4 * 4 + rate5 * 5;
  let mediumRate = sumRate/count

  return { sumRate, mediumRate, count , rate1, rate2, rate3, rate4, rate5 };
};

export default convertArrayToRate;
