import * as Yup from "yup";

const ValidationUserEditSchema = Yup.object().shape({
    name: Yup.string().min(2, "Too Short").max(26,"Too long"),
    make: Yup.string().min(1, "Too Short"),
    model: Yup.string().min(2, "Too Short").max(20,"Too long"),
    batteryCapacity: Yup.number().min(2, "Too Short").max(50000,"Too High").typeError('Please enter number'),
    plateReg: Yup.string().min(3, "Too Short").max(9,"Too long"),
});

export default ValidationUserEditSchema;
