import React from "react";

const DarkRapidCharger = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="27"
      height="37"
      viewBox="0 0 27 37"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26.1492 10.0936C26.1492 17.206 20.3835 22.9717 13.2711 22.9717C6.15878 22.9717 0.393066 17.206 0.393066 10.0936C0.393066 6.82661 1.60959 3.84375 3.61436 1.57331C3.96466 1.17659 4.47791 0.97168 5.00715 0.97168H21.5351C22.0644 0.97168 22.5776 1.17659 22.9279 1.57331C24.9327 3.84375 26.1492 6.82661 26.1492 10.0936ZM6.07471 13.8812C7.53902 13.8812 8.72608 12.6942 8.72608 11.2299C8.72608 9.76555 7.53902 8.57849 6.07471 8.57849C4.6104 8.57849 3.42334 9.76555 3.42334 11.2299C3.42334 12.6942 4.6104 13.8812 6.07471 13.8812ZM15.9224 11.2299C15.9224 12.6942 14.7353 13.8812 13.271 13.8812C11.8067 13.8812 10.6196 12.6942 10.6196 11.2299C10.6196 9.76555 11.8067 8.57849 13.271 8.57849C14.7353 8.57849 15.9224 9.76555 15.9224 11.2299ZM9.86231 19.184C11.3266 19.184 12.5137 17.9969 12.5137 16.5326C12.5137 15.0683 11.3266 13.8812 9.86231 13.8812C8.398 13.8812 7.21094 15.0683 7.21094 16.5326C7.21094 17.9969 8.398 19.184 9.86231 19.184ZM23.1191 11.2299C23.1191 12.6942 21.9321 13.8812 20.4678 13.8812C19.0035 13.8812 17.8164 12.6942 17.8164 11.2299C17.8164 9.76555 19.0035 8.57849 20.4678 8.57849C21.9321 8.57849 23.1191 9.76555 23.1191 11.2299ZM17.059 8.57848C17.8958 8.57848 18.5741 7.90016 18.5741 7.06341C18.5741 6.22666 17.8958 5.54834 17.059 5.54834C16.2223 5.54834 15.5439 6.22666 15.5439 7.06341C15.5439 7.90016 16.2223 8.57848 17.059 8.57848ZM10.9984 7.06341C10.9984 7.90016 10.3201 8.57848 9.48333 8.57848C8.64658 8.57848 7.96826 7.90016 7.96826 7.06341C7.96826 6.22666 8.64658 5.54834 9.48333 5.54834C10.3201 5.54834 10.9984 6.22666 10.9984 7.06341ZM17.0591 19.184C18.5234 19.184 19.7105 17.9969 19.7105 16.5326C19.7105 15.0683 18.5234 13.8812 17.0591 13.8812C15.5948 13.8812 14.4077 15.0683 14.4077 16.5326C14.4077 17.9969 15.5948 19.184 17.0591 19.184Z"
        fill="#3B3558"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.393066 29.4717C0.393066 25.3295 3.75093 21.9717 7.89307 21.9717H18.8931C23.0352 21.9717 26.3931 25.3295 26.3931 29.4717C26.3931 33.6138 23.0352 36.9717 18.8931 36.9717H7.89307C3.75093 36.9717 0.393066 33.6138 0.393066 29.4717ZM4.39307 29.4717C4.39307 27.5387 5.96007 25.9717 7.89307 25.9717C9.82606 25.9717 11.3931 27.5387 11.3931 29.4717C11.3931 31.4047 9.82606 32.9717 7.89307 32.9717C5.96007 32.9717 4.39307 31.4047 4.39307 29.4717ZM18.8931 25.9717C16.9601 25.9717 15.3931 27.5387 15.3931 29.4717C15.3931 31.4047 16.9601 32.9717 18.8931 32.9717C20.8261 32.9717 22.3931 31.4047 22.3931 29.4717C22.3931 27.5387 20.8261 25.9717 18.8931 25.9717Z"
        fill="#3B3558"
      />
    </svg>
  );
};

export default DarkRapidCharger;
