import { Button } from "antd";
import styled from "styled-components";

export const OutlinedButton: typeof Button = styled(Button)`
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0.063rem solid #d9d9d9;
  font-family: Roboto;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.375rem;
  width: 5.875rem;
  height: 2rem;
  border-radius: 0.125rem;
  padding: 0.313rem 1rem 0.313rem 1rem;
  border: 0.063rem solid #32a6d5;
  color: #32a6d5;
  background: white;
`;