import styled from "styled-components";
import {Card} from "antd";
import {CardProps} from "antd/lib/card";

export const Wrapper = styled.div`
  min-height: 100vh;
  min-width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #E5E5E5;
`
export const LoginText = styled.h1`
  padding:1rem;
  font-size: 1.625rem;
  font-weight: 700;
  line-height: 2.188rem;
  text-align: center;
  font-family: Nunito;
  color: #3B3558;
`

export const CardContainer: typeof Card = styled(Card)<CardProps>`
  width: 34.625rem;
  height: 34.43rem;
  display: flex;
  align-items: center;
  flex-direction: column;
  border-radius: 1rem;
  padding: 5rem 2.5rem 5rem 2.5rem;
`

export const CardLogo = styled.img`
  flex: none;
  order: 0;
  flex-grow: 0;
  width: 16.875rem;
  height: 4.438rem;
`

export const CardBody = styled.div`
  position:relative;
  width:29.625rem;
  height:17.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0;
  gap: 1rem;
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
`

export const CardHeader = styled.div`
display:flex;
align-items: center;
justify-content: center;
`