import React from "react";

const DarkCharger2 = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M37 17.5208C37 26.9097 29.3888 34.5208 20 34.5208C10.6112 34.5208 3 26.9097 3 17.5208C3 13.1131 4.67751 9.0971 7.42894 6.07653C7.78533 5.68527 8.29717 5.47925 8.82641 5.47925H31.1736C31.7028 5.47925 32.2147 5.68527 32.5711 6.07653C35.3225 9.0971 37 13.1131 37 17.5208ZM10.5 22.5208C12.433 22.5208 14 20.9537 14 19.0208C14 17.0878 12.433 15.5208 10.5 15.5208C8.567 15.5208 7 17.0878 7 19.0208C7 20.9537 8.567 22.5208 10.5 22.5208ZM23.5 19.0208C23.5 20.9537 21.933 22.5208 20 22.5208C18.067 22.5208 16.5 20.9537 16.5 19.0208C16.5 17.0878 18.067 15.5208 20 15.5208C21.933 15.5208 23.5 17.0878 23.5 19.0208ZM15.5 29.5208C17.433 29.5208 19 27.9537 19 26.0208C19 24.0878 17.433 22.5208 15.5 22.5208C13.567 22.5208 12 24.0878 12 26.0208C12 27.9537 13.567 29.5208 15.5 29.5208ZM33 19.0208C33 20.9537 31.433 22.5208 29.5 22.5208C27.567 22.5208 26 20.9537 26 19.0208C26 17.0878 27.567 15.5208 29.5 15.5208C31.433 15.5208 33 17.0878 33 19.0208ZM25 15.5208C26.1046 15.5208 27 14.6253 27 13.5208C27 12.4162 26.1046 11.5208 25 11.5208C23.8954 11.5208 23 12.4162 23 13.5208C23 14.6253 23.8954 15.5208 25 15.5208ZM17 13.5208C17 14.6253 16.1046 15.5208 15 15.5208C13.8954 15.5208 13 14.6253 13 13.5208C13 12.4162 13.8954 11.5208 15 11.5208C16.1046 11.5208 17 12.4162 17 13.5208ZM25 29.5208C26.933 29.5208 28.5 27.9537 28.5 26.0208C28.5 24.0878 26.933 22.5208 25 22.5208C23.067 22.5208 21.5 24.0878 21.5 26.0208C21.5 27.9537 23.067 29.5208 25 29.5208Z"
        fill="#3B3558"
      />
    </svg>
  );
};

export default DarkCharger2;
