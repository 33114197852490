import React from "react";

const Wifi = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="34"
      height="33"
      viewBox="0 0 34 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M0.399902 16.4471C0.399902 7.6105 7.56335 0.447052 16.3999 0.447052H17.3999C26.2365 0.447052 33.3999 7.6105 33.3999 16.4471V16.4471C33.3999 25.2836 26.2365 32.4471 17.3999 32.4471H16.3999C7.56335 32.4471 0.399902 25.2836 0.399902 16.4471V16.4471Z"
        fill="#3B3558"
      />
      <path
        d="M25.1055 15.0695C20.5737 10.5377 13.2261 10.5377 8.69434 15.0695"
        stroke="white"
        strokeLinecap="round"
      />
      <path
        d="M22.6922 17.4829C19.4933 14.284 14.3068 14.284 11.1079 17.4829"
        stroke="white"
        strokeLinecap="round"
      />
      <path
        d="M20.2795 19.8964C18.4135 18.0304 15.388 18.0304 13.522 19.8964"
        stroke="white"
        strokeLinecap="round"
      />
      <circle cx="16.8999" cy="22.5302" r="1" fill="white" />
    </svg>
  );
};

export default Wifi;
