import { useParams } from "react-router-dom";
import moment from "moment";
import { useEffect, useState } from "react";
import useSubscribeMessages from "../../../../../firebase/useSubscribeMessages";
import {IMessage} from "../../../../../types/IMessage";
import {
  Col,
  ContainerMessages,
  TextMessageRight,
  TextMessageLeft,
  UserNameRight,
  UserNameLeft, TextDate,
} from "./styles";

const Thread = () => {
  const { messages } = useSubscribeMessages();
  const [parsedMessages, setParsedMessages] = useState<IMessage[]>([]);
  const params = useParams();

  const { userId } = params;

  useEffect(() => {
    const newData = messages.map((item) => ({
      ...item,
      createdAt: moment(item.createdAt.toDate())
        .format("DD-MM-YYYY HH:MM")
        .toString(),
    }));

    const sortedData = newData.sort(function (a, b) {
      return new Date(b.createdAt).valueOf() - new Date(a.createdAt).valueOf();
    });
    setParsedMessages(sortedData);
  }, [messages]);

  return (
    <ContainerMessages>
      {parsedMessages.map(({ text, user, createdAt }, index) => {
        return (
          <>
            {index + 1 < parsedMessages.length &&
              parsedMessages[index + 1].createdAt !==
                parsedMessages[index].createdAt && (
              <TextDate>
                  {parsedMessages[index].createdAt}
              </TextDate>
              )}
            {user._id.toString() === userId && (
              <Col>
                <UserNameRight>{user.name}</UserNameRight>
                <TextMessageRight>{text}</TextMessageRight>
              </Col>
            )}
            {user._id.toString() !== userId && (
              <Col>
                <UserNameLeft>{user.name}</UserNameLeft>
                <TextMessageLeft>{text}</TextMessageLeft>
              </Col>
            )}
          </>
        );
      })}
    </ContainerMessages>
  );
};

export default Thread;
