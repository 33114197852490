import styled from "styled-components";
import { Button } from "antd";

export const ChargerCard = styled.div.attrs(
  (props: { active: boolean }) => props
)`
  background-color: ${(props) => (props.active ? "#F1F3F5" : "#FFFFFF")};
  border: ${(props) =>
    props.active ? "1px solid #F1F3F5" : "1px solid #F1F3F5"};
  padding: 16px;
  border-radius: 8px;
  display: flex;
  flex-grow: 0;
  min-height: 120px;
  flex-direction: column;
  gap: 7px;
  position: relative;
`;

export const ChargerCardRate = styled.div`
  display: flex;
  gap: 3px;
  flex-direction: row;
  align-items: center;
  font-family: Nunito;
  font-size: 11px;
  line-height: 16.5px;
  font-weight: 400;
`;
export const Rate = styled.span`
  padding-left: 5px;
  display: flex;
  font-family: Nunito;
  font-weight: 400;
  font-size: 12px;
  line-height: 16.5px;
  color: #908e9b;
`;
export const ChargerName = styled.span`
  display: flex;
  font-family: Nunito;
  font-weight: 700;
  line-height: 26px;
  font-size: 22px;
  color: #2d2245;
`;
export const ChargerAddressDetails = styled.span`
  display: flex;
  font-family: Nunito;
  font-size: 14px;
  line-height: 18px;
  font-weight: 400;
`;

export const DisableButton = styled(Button)`
  position: absolute;
  right: 0;
  padding-right: 5px;
`;
