import styled from "styled-components";

export const Col = styled.div`
  display: flex;
  flex-direction: column;
  gap:2px;
`;

export const TextMessageRight = styled.span`
  display: flex;
  flex:0 1 auto;
  justify-content: center;
  margin-left:80%;
  background: #32a6d5;
  padding: 8px 16px;
  color:white;
  border-radius: 8px;
`;

export const TextMessageLeft = styled.span`
  display: flex;
  margin-right:80%;
  flex:0 1 auto;
  justify-content: center;
  background: #F1F3F5;
  padding: 8px 16px;
  border-radius: 8px;
  color:black;
`;

export const UserNameRight = styled.span`
  margin-left:80%;
  text-align: right;
  color:#B0B9C6;
`

export const UserNameLeft = styled.span`
  color:#B0B9C6
`

export const ContainerMessages = styled.div`
  gap:5px;
  display: flex;
  overflow-y: auto;
  overflow-x: hidden;
  width:100%;
  height: 470px;
  flex-direction: column;
`;

export const TextDate = styled.span`
  text-align: center;
  font-size:14px;
  line-height:20px;
  color:#B0B9C6;
`
