import React from "react";
import { Input } from "antd";
import { Label } from "../LoginForm/styles";

interface IProps {
  passwordType?: boolean;
  name: string;
  value: string;
  errors?: string;
  touched?: boolean;
  handleChange: {
    (e: React.ChangeEvent<any>): void;
    <T = string | React.ChangeEvent<any>>(
      field: T
    ): T extends React.ChangeEvent<any>
      ? void
      : (e: string | React.ChangeEvent<any>) => void;
  };
}

const TextInput: React.FC<IProps> = ({
  name,
  value,
  errors,
  touched,
  handleChange,
  passwordType,
}) => {
  const labelName = name[0].toUpperCase() + name.substring(1);
  return (
    <>
      {passwordType ? (
        <>
          <Label>{labelName}</Label>
          <Input.Password name={name} value={value} onChange={handleChange} />
          {errors && touched ? <div>{errors}</div> : null}
        </>
      ) : (
        <>
          <Label>{labelName}</Label>
          <Input name={name} value={value} onChange={handleChange} />
          {errors && touched ? <div>{errors}</div> : null}
        </>
      )}
    </>
  );
};

export default TextInput;
