import React, { useEffect, useMemo, useState } from 'react';
import { Container } from '../../layout/Container';
import TableHeader from './TableHeader';
import { LoaderContainer, TableWrapper } from '../TableUsers/styles';
import { Modal, notification, Spin, Switch, Table, TableProps } from 'antd';
import { TableText } from '../TableText';
import CreatePromoCode, {
  CreatePromoCodeValues,
} from '../Modal/CreatePromoCode';
import { PromoCode, PromoCodeData, PromoCodeResponse } from './types';
import useSWR from 'swr';
import useSWRMutation from 'swr/mutation';
import { PromoCodes } from '../../services/promoCodes/promo-codes';
import { ButtonsWrapper } from '../ButtonsWrapper';
import { OutlinedButton } from '../OutlinedButton';
import { SolidButton } from '../SolidButton';

const TablePromoCodes = () => {
  const [api, contextHolder] = notification.useNotification();

  const [open, setOpen] = useState<boolean>(false);
  const [selectedRow, setSelectedRow] = useState<PromoCode | null>(null);

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);

  const {
    data,
    isLoading,
    error: fetchError,
  } = useSWR<PromoCodeResponse, Error>('/promo-code', () => {
    return PromoCodes.getAll();
  });

  const { trigger: createPromoCode, error: createError } = useSWRMutation(
    '/promo-code',
    async (_url: string, { arg }: { arg: Omit<PromoCodeData, 'id'> }) =>
      PromoCodes.create(arg)
  );

  const { trigger: updatePromoCode, error: updateError } = useSWRMutation(
    `/promo-code`,
    async (_url: string, { arg }: { arg: Partial<PromoCodeData> }) => {
      return PromoCodes.update(arg.id!, arg);
    }
  );

  const { trigger: deletePromoCode, error: deleteError } = useSWRMutation(
    `/promo-code`,
    async (_url: string, { arg }: { arg: string }) => {
      return PromoCodes.delete(arg);
    }
  );

  useEffect(() => {
    if (fetchError) {
      api.error({
        message: 'Error fetching promo codes',
        description: fetchError.message,
        placement: 'bottomRight',
      });
    }
  }, [api, fetchError]);

  useEffect(() => {
    if (createError) {
      api.error({
        message: 'Error creating promo code',
        description: createError.message,
        placement: 'bottomRight',
      });
    }
  }, [api, createError]);

  useEffect(() => {
    if (updateError) {
      api.error({
        message: 'Error updating promo code',
        description: updateError.message,
        placement: 'bottomRight',
      });
    }
  }, [api, updateError]);

  useEffect(() => {
    if (deleteError) {
      api.error({
        message: 'Error deleting promo code',
        description: deleteError.message,
        placement: 'bottomRight',
      });
    }
  }, [api, deleteError]);

  const promoCodes = useMemo(() => {
    return data?.map((item) => {
      return {
        ...item,
        variants: item.variants ? item.variants.join(', ') : '',
      } as PromoCode;
    });
  }, [data]);

  const columns: TableProps<PromoCode>['columns'] = [
    {
      title: 'Promo Code',
      dataIndex: 'code',
      render: (text) => <TableText>{text}</TableText>,
    },
    {
      title: 'Variants',
      dataIndex: 'variants',
      render: (text) => <TableText>{text}</TableText>,
    },
    {
      title: 'Total Trial Days',
      dataIndex: 'trialDays',
      render: (text) => <TableText>{text}</TableText>,
    },
    {
      title: 'Active',
      dataIndex: 'isActive',
      render: (text, record) => (
        <Switch
          checked={record.isActive}
          onChange={() => {
            const variantsArray = record.variants
              .split(',')
              .map((item) => item.trim())
              .filter((item) => item);

            updatePromoCode({
              id: record.id,
              code: record.code,
              trialDays: record.trialDays,
              variants: variantsArray,
              isActive: !record.isActive,
            });
          }}
        />
      ),
    },
  ];

  const handleAddPromoCode = () => {
    setSelectedRow(null);
    setOpen(true);
  };

  const handleSavePromoCode = async (values: CreatePromoCodeValues) => {
    const variantsArray = values.variants
      .split(',')
      .map((item) => item.trim())
      .filter((item) => item);

    if (values.id) {
      await updatePromoCode({
        id: values.id,
        code: values.code,
        variants: variantsArray,
        trialDays: values.trialDays,
        isActive: values.isActive,
      });
      setOpen(false);
      return;
    }

    createPromoCode({
      code: values.code,
      variants: variantsArray,
      trialDays: values.trialDays,
      isActive: values.isActive,
    });

    setOpen(false);
  };

  const handleDeletePromoCode = (id: string) => {
    setIsDeleteModalOpen(true);
  };

  if (isLoading) {
    return (
      <LoaderContainer>
        <Spin size={'large'} />
      </LoaderContainer>
    );
  }

  return (
    <>
      <Container>
        <TableHeader handleAddPromoCode={handleAddPromoCode} />
        <TableWrapper>
          <Table
            onRow={(record) => {
              return {
                onClick: (e) => {
                  if (
                    e.target instanceof HTMLButtonElement ||
                    e.target instanceof HTMLDivElement
                  ) {
                    return;
                  }

                  setOpen(true);
                  setSelectedRow(record);
                },
              };
            }}
            dataSource={promoCodes}
            columns={columns}
            scroll={{ y: 'calc(100vh)' }}
          />
        </TableWrapper>
      </Container>
      <CreatePromoCode
        onDelete={handleDeletePromoCode}
        onSubmit={handleSavePromoCode}
        open={open}
        onCancel={() => setOpen(false)}
        promoCode={selectedRow}
      />
      <Modal
        title="Delete Promo Code"
        visible={isDeleteModalOpen}
        onCancel={() => {
          setIsDeleteModalOpen(false);
        }}
      >
        <p>Are you sure you want to delete this promo code?</p>
        <ButtonsWrapper>
          <OutlinedButton
            onClick={() => {
              setIsDeleteModalOpen(false);
            }}
          >
            Cancel
          </OutlinedButton>
          <SolidButton
            onClick={() => {
              deletePromoCode(selectedRow!.id);
              setIsDeleteModalOpen(false);
              setOpen(false);
            }}
          >
            Ok
          </SolidButton>
        </ButtonsWrapper>
      </Modal>
      {contextHolder}
    </>
  );
};

export default TablePromoCodes;
