import styled from "styled-components";
import { InputNumber } from "antd";

export const NumberInputField: typeof InputNumber = styled(InputNumber)`
  display: flex;
  flex: 0 0 75%;
`;

export const NumberLabel = styled.label`
  display: flex;
  flex: 0 0 26%;
  font-family: Nunito;
  font-weight: 400;
  font-style: normal;
  font-size: 0.875rem;
  line-height: 1.375rem;
  color: rgba(0, 0, 0, 0.85);
`;