const link = process.env.REACT_APP_SERVER_API_V2;

const getBookingDetails = (id: string | undefined) => {
  if (!id) {
    return "null";
  }

  return `${link}/booking/user/${id}`;
};

export default getBookingDetails;
