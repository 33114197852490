import React from 'react';
import useSWR from 'swr';
import { Image } from '../../services/image/image';
import { Spin } from 'antd';

export default function Avatar({ filename }: { filename: string }) {
  const { data, isLoading } = useSWR(filename, async () => {
    return Image.getImageUrl(filename);
  });

  if (isLoading) {
    return (
      <Spin
        style={{
          alignItems: 'center',
          justifyContent: 'center',
        }}
      />
    );
  }

  return (
    <img
      src={`data:image/png;base64,${data}`}
      width={45}
      height={45}
      style={{ borderRadius: '50%' }}
    />
  );
}
