import React, { useState } from "react";
import { Menu } from "antd";
import {Link, useLocation} from "react-router-dom";
import { EditIcon, SmallLogo, UserIcon } from "../../icons";
import { GiftFilled } from '@ant-design/icons';

import Logo from "../../icons/Logo";
import { LogoContainer, NavContainer, NavHeader } from "./styles";

const Nav = () => {
  const [isOpen, setIsOpen] = useState(false);

  const location = useLocation();

  const {pathname} = location

  return (
    <NavContainer
      width={285}
      collapsible={true}
      theme="light"
      onCollapse={() => setIsOpen(!isOpen)}
      collapsed={isOpen}
    >
      <NavHeader>
        <LogoContainer>
          {!isOpen ? <Logo /> : <SmallLogo width={40} height={40} />}
        </LogoContainer>
      </NavHeader>
      <Menu
        defaultSelectedKeys={[pathname]}
        defaultOpenKeys={["sub1"]}
        mode="inline"
      >
        <Menu.Item key="/users" icon={<UserIcon />}>
          <Link to={"/users"}>Users</Link>
        </Menu.Item>
        <Menu.Item key="/content" icon={<EditIcon />}>
          <Link to={"/content"}>Content Editor</Link>
        </Menu.Item>
        <Menu.Item key="/promo-codes" icon={<GiftFilled style={{fontSize: '18px'}}/>}>
          <Link to={"/promo-codes"}>Promo Codes</Link>
        </Menu.Item>
      </Menu>
    </NavContainer>
  );
};

export default Nav;
