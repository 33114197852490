import Cookies from "universal-cookie";
import getRefreshTokenUrl from "./consts/getRefreshTokenUrl";

const Axios = require('axios');

const cookies = new Cookies()

const getToken = async () => cookies.get('access_token')

    Axios.create({
    baseURL: process.env.REACT_APP_SERVER_API
});

const refreshAccessToken = async () => {
    const res = await Axios.get(getRefreshTokenUrl());
   await  cookies.set('access_token', res.data.token);
    return Promise.resolve(res.data);
};
Axios.interceptors.request.use(
    async (request: any) => {
        // check if request URL is blacklisted
        if (request?.url) {
            const accessToken = await getToken();
            // Inject token to header if there is one (if user is logged in)
            if (accessToken && accessToken.length > 0) {
                request.headers.Authorization = `Bearer ${accessToken}`;
                return request;
            }
        }
        return request;
    },
    (error: any) => {
        return Promise.reject(error);
    }
);
Axios.interceptors.response.use(
    (response:any) => {
        return response;
    },
    (error:any) => {
        // handle unauthorized request
        if (
            error.request &&
            error.request.status === 401 &&
            (error.response.data.message === 'token-expired' ||
                error.response.data.message === "Account isn't verified")
        ) {
            //TODO: Handle refresh token
            const failedRequest = error.config;
            return refreshAccessToken().then(async (token: string) => {
                // inject new accessToken and repeat failed req
                failedRequest.headers.Authorization = `Bearer ${token}`;
                return Axios.request(failedRequest);
            });
        }
        throw error;
    }
);


export default Axios
