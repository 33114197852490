import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
`;

export const TableWrapper = styled.div`
  display: flex;
  margin: 1rem;
  overflow-y: auto;
  overflow-x: scroll;
  height: 80vh;
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
`;

export const ArrowWrapper = styled.div`
  display: block;
  padding-left: 50px;
`;

export const LoaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`;

export const BookingButton = styled.button`
  background: transparent;
  border: none;
  box-shadow: none;
`;
