import styled from "styled-components";
import TextArea from "antd/es/input/TextArea";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;

  flex-grow: 1;
  height:250px;
  padding-right:30px;
  overflow-y: scroll;
  overflow-x: hidden;
`;
export const Row = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
`;
export const BetweenRow = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Type = styled.span`
  font-family: "Nunito";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 25px;
  color: #3b3558;
`;

export const Power = styled.span`
  font-family: "Nunito";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: #3b3558;
`;

export const Tethered = styled.span`
  font-family: "Nunito";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  padding-left: 11%;
  line-height: 20px;
  color: #b0b9c6;
`;

export const HourlyRate = styled.span`
  font-family: "Nunito";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: #2d2245;
`;

export const ValueText = styled.span`
  font-family: "Nunito";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 25px;
  text-align: right;
  color: #2d2245;
`;

export const MinimumSessionFee = styled.span`
  font-family: "Nunito";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: #2d2245;
`;

export const TextAreaField = styled(TextArea)`
  position: relative;
  background: transparent !important;
  color: black !important;
  display:flex;
  flex: 1;
  margin-top:1.8rem;
`;


