import styled from "styled-components";
import { Tabs } from "antd";

const { TabPane } = Tabs;

export const Wrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 5px;
`;

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
`;

export const ChargerName = styled.span`
  font-family: "Nunito";
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 26px;
  color: #3b3558;
`;

export const Address = styled.span`
  font-family: "Nunito";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: #3b3558;
`;

export const AttendedCharging = styled.span`
  font-family: "Nunito";
  font-style: normal;
  font-weight: 400;
  padding-top: 5px;
  color: #b0b9c6;
  font-size: 14px;
  line-height: 20px;
`;

export const IconsContainer = styled.div`
  padding-top: 10px;
  display: flex;
  width: 100%;
  align-items: center;
  flex-direction: row;
`;

export const Col = styled.div`
  flex: 1;
  height: 66px;
  display: flex;
  gap: 10px;
  align-items: center;
  flex-direction: column;
`;
export const IconTitle = styled.span`
  text-align: center;
  font-family: "Nunito";
  font-style: normal;
  font-weight: 400;
  color: #000000;
  font-size: 11px;
  line-height: 120%;
`;

export const TabsContainer: typeof Tabs = styled(Tabs)`
  display: flex;

  &.ant-tabs-tab {
    color: #32a6d5 !important;
  }

  &.ant-tabs > .ant-tabs-nav .ant-tabs-nav-wrap,
  .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-wrap {
    color: #b0b9c6;
  }
`;
export const Tab: typeof TabPane = styled(TabPane)`
  display: flex;
`;
