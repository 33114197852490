import React from "react";

const CoveredParking = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="32"
      height="33"
      viewBox="0 0 33 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M0.299805 16.4471C0.299805 7.6105 7.46325 0.447052 16.2998 0.447052V0.447052C25.1364 0.447052 32.2998 7.6105 32.2998 16.4471V16.4471C32.2998 25.2836 25.1364 32.4471 16.2998 32.4471V32.4471C7.46325 32.4471 0.299805 25.2836 0.299805 16.4471V16.4471Z"
        fill="#3B3558"
      />
      <path
        d="M24.8377 12.9377L18.2574 7.68994C17.1046 6.7762 15.4659 6.7762 14.3131 7.68994L7.76172 12.9472"
        stroke="white"
        strokeLinecap="round"
      />
      <path
        d="M18.7269 23.2971H13.8735C11.916 23.2971 10.3291 21.7102 10.3291 19.7527C10.3291 19.4629 10.3646 19.1742 10.4349 18.8931L11.1639 15.9771C11.6091 14.1964 13.209 12.9472 15.0445 12.9472H17.5559C19.3914 12.9472 20.9913 14.1964 21.4365 15.9771L22.1655 18.8931C22.2358 19.1742 22.2713 19.4629 22.2713 19.7527C22.2713 21.7102 20.6844 23.2971 18.7269 23.2971Z"
        stroke="white"
        stroke-linecap="square"
      />
      <path
        d="M11.125 22.501V24.8894H13.1154V23.2972M19.4845 23.2972V24.8894H21.4749V22.501"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.7173 20.1127H13.9115M18.6884 20.1127H19.8826"
        stroke="white"
        strokeLinecap="round"
      />
      <path
        d="M10.7269 16.9277H9.53271M21.873 16.9277H23.0672"
        stroke="white"
        strokeLinecap="round"
      />
      <path d="M12.7173 17.7239H19.8826" stroke="white" strokeLinecap="round" />
    </svg>
  );
};

export default CoveredParking;
