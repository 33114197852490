import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import useSWR from "swr";
import moment from "moment";
import getBookingDetails from "../../../../../consts/getBookingDetails";
import handleCostOfBooking from "../../../../../helpers/handleCostBooking";
import handleSumTimeBooking from "../../../../../helpers/handleSumTimeBooking";
import { ICalendar } from "../../../../../types/rest/calendar/ICalendar";
import dateContext from "../../../../../context/tabsContext";
import {
  LeftSide,
  Wrapper,
  BookingDayNumber,
  BookingDay,
  Right,
} from "./styles";
import Book from "./Book";
import handleRoundPrice from "../../../../../helpers/handleRoundPrice";

const Booking: React.FC = () => {
  const [bookDetail, setBookDetail] = useState([]);

  const { date } = useContext(dateContext);
  const parsedDate = moment(date).format("DD-MM-YYYY");

  const { setBookingId } = useContext(dateContext);

  const params = useParams();
  const { userId } = params;

  let allBookings: any = [];

  const { data } = useSWR(getBookingDetails(userId));

  useEffect(() => {
    if (!date || !data) {
      return;
    }

    Object.values(data).map((item: any) => {
      item.forEach((val: ICalendar) => {
        allBookings.push(JSON.parse(val.name));
      });
    });

    const bookingsWithDate = allBookings.map((item: any) => {
      if (item.date === parsedDate) {
        return item;
      }
      return;
    });
    const filteredArr = bookingsWithDate.filter(
      (item: any) => item !== undefined
    );
    setBookDetail(filteredArr);
  }, [date]);

  const handleSelectBooking = (uid: string) => {
    setBookingId(uid);
  };

  if (!bookDetail.length) {
    return (
      <Wrapper>
        <p>Empty</p>
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <LeftSide>
        <BookingDayNumber>{parsedDate[0] + parsedDate[1]}</BookingDayNumber>
        <BookingDay>{moment(date).format("MMMM")}</BookingDay>
      </LeftSide>
      <Right>
        {bookDetail.map((item: any) => {
          const sumPrice = handleCostOfBooking(
            handleSumTimeBooking(item.startSession, item.endSession),
            item.hourlyRateFee,
            item.minimumSessionFee
          );
          const roundedSum = handleRoundPrice(sumPrice.toString());
          return (
            <Book
              type={item.type}
              handleSelectBooking={handleSelectBooking}
              uid={item.bookingId}
              isHost={true}
              status={item.status}
              hourStart={moment(item.startSession).format("HH:mm")}
              hourEnd={moment(item.endSession).format("HH:mm")}
              hourTime={handleSumTimeBooking(
                item.startSession,
                item.endSession
              ).toFixed(0).toString()}
              price={roundedSum}
              nameCharger={item.chargerName}
              nameCar={item.vehicleName}
              modelCar={item.vehicleModel}
              nameUser={item.contactFirstName}
              lastName={item.contactLastName}
            />
          );
        })}
      </Right>
    </Wrapper>
  );
};

export default Booking;
