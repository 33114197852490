import styled from "styled-components";

export const UserProfileContent = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
`;
export const Details = styled.div`
  display: flex;
  flex-direction: column;
`;

export const UserNameContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
`;
export const WrapperName = styled.div`
  display: flex;
  flex-direction: column;
`;
export const ActionWrapper = styled.div`
  display: flex;
  gap: 4px;
  flex-direction: row;
`;

export const UserHandle = styled.span`
  font-family: Nunito;
  font-weight: 700;
  font-size: 22px;
  line-height: 26px;
  color: #3b3558;
  width:200px;
  text-overflow: ellipsis;
  word-break: break-all;
  overflow:hidden;
  white-space: nowrap;
  &:hover {
    overflow: visible;
    white-space: normal;
    height:auto;
  }
`;
export const UserName = styled.span`
  font-family: Nunito;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #3b3558;
`;

export const Row = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 8px 4px 12px 8px;
`;

export const CirclePhoto = styled.span`
width:48px;
  height:48px;
  display:flex;
  justify-content: center;
  align-items: center;
  font-size:20px;
  font-weight: 600;
  background: #3B3558;
  border-radius:50%;
  color:white;
`
