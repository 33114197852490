import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { useState } from "react";
import TabsContext from "../context/tabsContext";
import {
  Login,
  UserDetails,
  Users,
  About,
  ContentEditor,
  Faq,
  Contact,
  TermsConditions,
} from "../pages";
import ForgotPassword from "../pages/ForgotPassword";
import ResetPassword from "../pages/ResetPassword";
import PrivateRoutes from "./guards/PrivateRoute";
import PromoCodes from "../pages/PromoCodes";

const AppRouter = () => {
  const [date, setDate] = useState("");
  const [bookingId, setBookingId] = useState("");
  const [id, setId] = useState(0);
  const [activeChannel, setActiveChannel] = useState<null | string>(null);
  const [activeTab, setActiveTab] = useState(1);

  const handleId = (id: number) => setId(id);
  const handleChannelId = (channelId: string) => setActiveChannel(channelId);
  const handleActiveTab = (id: number) => setActiveTab(id);

  return (
    <TabsContext.Provider
      value={{
        date,
        setDate,
        bookingId,
        setBookingId,
        id,
        handleId,
        activeChannel,
        handleChannelId,
        activeTab,
        handleActiveTab
      }}
    >
      <BrowserRouter>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/forgotPassword" element={<ForgotPassword />} />
          <Route path="/resetPassword/:codeId" element={<ResetPassword />} />
          <Route element={<PrivateRoutes />}>
            <Route path="/" element={<Navigate to={'/users'} replace />} />
            <Route path="/users" element={<Users />} />
            <Route path="/users/:userId" element={<UserDetails />} />
            <Route path="/promo-codes" element={<PromoCodes />} />
            <Route path="/content" element={<ContentEditor />} />
            <Route path="/content/about" element={<About />} />
            <Route path="/content/faq" element={<Faq />} />
            <Route path="/content/contact" element={<Contact />} />
            <Route path="/content/terms" element={<TermsConditions />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </TabsContext.Provider>
  );
};

export default AppRouter;
