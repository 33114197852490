import { useEffect, useState } from "react";
import { collection, where, query, limit, getDocs } from "firebase/firestore";
import { db } from "./firebaseConfig";

interface IChannel {
  id: string;
  concatChannelId: string;
  assignedUsers: number[];
}

const useGetChannels = (userId: string) => {
  const [channels, setChannels] = useState<IChannel[]>([]);
  const [idChannels, setIdChannels] = useState<string[]>([]);

  useEffect(() => {
    const handleFetch = async () => {
      const val: any = await fetchChannels(userId);
      if (!val) {
        return;
      }
      val.forEach((item: any) =>
        setChannels((prevState) => [
          ...prevState,
          {
            id: item.id,
            concatChannelId: item.data().concatChannelId,
            assignedUsers: item.data().assignedUsers,
          },
        ])
      );

      val.forEach((item: any) =>
        setIdChannels((prevState) => [...prevState, item.id])
      );
    };
    handleFetch();
  }, []);

  return { channels, idChannels };
};

const fetchChannels = (id: string) => {
  return new Promise(async (resolve, reject) => {
    const q = query(
      collection(db, "channels"),
      where("assignedUsers", "array-contains-any", [Number(id)]),
      limit(10)
    );

    const data = await getDocs(q);
    if (data) {
      resolve(data);
    }
  });
};
export default useGetChannels;
