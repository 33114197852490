import React, { ReactNode, useEffect, useState } from "react";
import useSWR from "swr";
import {message, Spin} from "antd";
import { useParams } from "react-router-dom";
import getUserDetailUrl from "../../../consts/getUserDetailUrl";
import parseUserDetail from "../../../helpers/parseUserDetail";
import convertArrayToRate from "../../../helpers/convertArrayToRate";
import {IRating} from "../../../types/IRating";
import {LoaderContainer} from "../../TableUsers/styles";
import Card from "../Card/Card";
import RateCard from "./RateCard";

interface IProps {
  children: ReactNode;
}

const RatingContainer: React.FC<IProps> = ({ children }) => {
  const params = useParams();
  const { userId } = params;

  const [rate, setRate] = useState<IRating>();

  const errorMessage = () => message.error("Sorry something going wrong!")

  const { data, isValidating } = useSWR(
    getUserDetailUrl(userId),
  );

  useEffect(() => {
    if (!data) {
      return;
    }
    handleParse()
  }, [isValidating]);

  const handleParse = () => {
    try {
      let arrayForParsedRate:number[] = []
      const parsed = parseUserDetail(data);
      const { ratings } = parsed;
      if(!ratings.length){
        return
      }
      ratings.map(({rating})=> (
        arrayForParsedRate.push(rating)
        ))
      if(!arrayForParsedRate.length){
        return
      }
      const ratingParsed = convertArrayToRate(arrayForParsedRate);
      if(ratingParsed){
        setRate(ratingParsed);
      }
    }catch (e:unknown) {
      errorMessage()
    }
  }

  if(isValidating){
    return  <LoaderContainer>
      <Spin size={"large"}  />
    </LoaderContainer>
  }

  if (!rate || rate.count  ===0 ) {
    return (
      <Card title="Rating">
        <h1>Empty</h1>
      </Card>
    );
  }

  return (
    <Card title="Rating">
      <RateCard
        rate_count={rate.count}
        rate={rate.mediumRate}
        rate_5={rate.rate5}
        rate_4={rate.rate4}
        rate_3={rate.rate3}
        rate_2={rate.rate2}
        rate_1={rate.rate1}
      />
      <> {children} </>
    </Card>
  );
};

export default RatingContainer;
