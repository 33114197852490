import React, {useContext, useEffect, useState} from "react";
import useSWR from "swr";
import {getChargerDetailsUrl} from "../../../consts/getChargerDetailsUrl";
import {IOpinion} from "../../../types/IOpinion";
import TabsContext from "../../../context/tabsContext";
import { OpinionsWrapper } from "./styles";
import Opinion from "../Opinion";

const OpinionsCharger = () => {
  const { id } = useContext(TabsContext);
  const { data,isValidating } = useSWR(getChargerDetailsUrl(id))
  const [opinionState,setOpinionState] = useState([])

  useEffect(() => {
    if (!data) {
      return;
    }
    const {ratings} = data
    setOpinionState(ratings)
  },[isValidating])

  if(isValidating){
    return <p>Loading...</p>
  }

  return (
    <OpinionsWrapper>
      {opinionState.map((item:IOpinion) => {
        const {...rest} = item
        return (
          <Opinion
              {...rest}
          />
        );
      })}
    </OpinionsWrapper>
  );
};

export default OpinionsCharger;
