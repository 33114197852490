import ChargersCard from "./ChargersCard";
import { ChargersWrapper } from "./styles";
import DetailsCard from "./DetailsCard";
import RateCharger from "./RatingCard/RateCharger";

const ChargersContainer = () => {
  return (
    <ChargersWrapper>
      <ChargersCard />
      <DetailsCard />
      <RateCharger />
    </ChargersWrapper>
  );
};

export default ChargersContainer;
