import styled from "styled-components";

export const Wrapper = styled.div`
  overflow: hidden;
  display: flex;
  padding: 20px;
  width: 100%;
  justify-content: center;
  height: 100%;
`;

