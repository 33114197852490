import { Form, Modal, Switch } from 'antd';
import React, { FC } from 'react';
import TextInputRow from '../../TextInput/TextInputRow';
import { Label, RowModal } from '../styles';
import { ButtonsWrapper } from '../../ButtonsWrapper';
import { OutlinedButton } from '../../OutlinedButton';
import { SolidButton } from '../../SolidButton';
import NumberInputRow from '../../NumberInput/NumberInputRow';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { CreatePromoCodeValues } from './types';
import { PromoCode } from '../../TablePromoCodes';

const CreatePromoCodeSchema = Yup.object().shape({
  code: Yup.string().required('Required'),
  variants: Yup.string().required('Required'),
  trialDays: Yup.number().required('Required').min(1).max(500),
  isActive: Yup.boolean().required('Required'),
});

interface Props {
  open: boolean;
  onCancel: () => void;
  onDelete?: (id: string) => void;
  onSubmit: (values: CreatePromoCodeValues) => void;
  promoCode?: PromoCode | null;
}

const CreatePromoCode: FC<Props> = ({
  open,
  onCancel,
  onDelete,
  onSubmit,
  promoCode,
}) => {
  const defaultValues = promoCode
    ? {
        code: promoCode.code,
        variants: promoCode.variants,
        trialDays: promoCode.trialDays,
        isActive: promoCode.isActive,
      }
    : {
        code: '',
        variants: '',
        trialDays: 1,
        isActive: true,
      };

  return (
    <Formik
      onSubmit={(values) => {
        if (promoCode) {
          onSubmit({ ...values, id: promoCode.id });
        } else {
          onSubmit(values);
        }
      }}
      initialValues={defaultValues}
      validationSchema={CreatePromoCodeSchema}
      enableReinitialize
    >
      {({ values, handleChange, handleSubmit, setFieldValue, resetForm }) => {
        return (
          <Modal
            title="Create/Edit Promo Code"
            visible={open}
            onCancel={onCancel}
            afterClose={resetForm}
          >
            <Form onFinish={handleSubmit}>
              <Form.Item>
                <TextInputRow
                  labelText="Promo Code"
                  name="code"
                  handleChange={handleChange}
                  value={values.code}
                />
              </Form.Item>
              <Form.Item>
                <TextInputRow
                  labelText="Variants"
                  name="variants"
                  handleChange={handleChange}
                  value={values.variants}
                />
              </Form.Item>
              <Form.Item>
                <NumberInputRow
                  max={500}
                  min={1}
                  defaultValue={1}
                  value={values.trialDays}
                  labelText="Total Trial Days"
                  name="trialDays"
                  handleChange={(value) => {
                    setFieldValue('trialDays', value);
                  }}
                />
              </Form.Item>
              <Form.Item>
                <RowModal>
                  <Label>Active</Label>
                  <Switch
                    checked={values.isActive}
                    onChange={() => {
                      setFieldValue('isActive', !values.isActive);
                    }}
                  />
                </RowModal>
              </Form.Item>
              <ButtonsWrapper>
                {promoCode && (
                  <SolidButton
                    onClick={() => {
                      if (promoCode?.id) {
                        onDelete?.(promoCode.id);
                      }
                    }}
                    backgroundColor="red"
                  >
                    Delete Promo Code
                  </SolidButton>
                )}
                <div style={{ flex: 1 }} />
                <OutlinedButton
                  onClick={() => {
                    resetForm();
                    onCancel();
                  }}
                >
                  Cancel
                </OutlinedButton>
                <SolidButton htmlType="submit">Save</SolidButton>
              </ButtonsWrapper>
            </Form>
          </Modal>
        );
      }}
    </Formik>
  );
};

export default CreatePromoCode;
