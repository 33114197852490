import { Button, Layout } from "antd";
import styled from "styled-components";

export const { Header } = Layout;

export const HeaderWrapper: typeof Header = styled(Header)`
  background-color: #e5e5e5;
  height: 60px;
  padding:5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const HeaderWrapperFlexEnd:typeof Header = styled(Header)`
  background-color: #e5e5e5;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

export const ButtonBack = styled(Button)`
  position: relative;
  right: 3px;
  display: flex;
  align-items: center;
  flex-direction: row;
  border: none;
  background: transparent;
  box-shadow: none;
  width: 101px;
  height: 25px;
  gap: 10px;
  font-family: "Nunito";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 25px;
  color: #32a6d5;
  
  &:hover, &:active, &:visited, :focus{
    background: transparent;
    border:none;
    box-shadow:none;
  }
`;
