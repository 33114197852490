import React from "react";
import {IContent} from "../../../../types/rest/editor/IContent";
import { Container, Head, ModalComponent, Title } from "../styles";

interface IProps {
    handleToggle: () => void;
    isOpenPreview: boolean;
    data:  IContent;
}

const PreviewTerms: React.FC<IProps> = ({
                                            data,
                                            handleToggle,
                                            isOpenPreview,
                                        }) => {
    if (!data) {
        return (
            <ModalComponent
                mask={true}
                maskStyle={{ backdropFilter: "blur(8px)" }}
                visible={isOpenPreview}
                onOk={handleToggle}
                onCancel={handleToggle}
            >
                <p>Empty</p>
            </ModalComponent>
        );
    }
    return (
        <ModalComponent
            mask={true}
            maskStyle={{ backdropFilter: "blur(8px)" }}
            visible={isOpenPreview}
            onOk={handleToggle}
            onCancel={handleToggle}
        >
            <Container>
                <Head>
                    <Title> Terms & Conditions</Title>
                </Head>
                <Title>Hello!</Title>
                {data.items.map(({markdown})=>(<div dangerouslySetInnerHTML={{ __html: markdown }} />))}
            </Container>
        </ModalComponent>
    );
};

export default PreviewTerms;
