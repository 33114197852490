import styled from "styled-components";

export const ChargersWrapper = styled.div`
  width:100%;
  display: flex;
  flex-direction: row;
  gap: 16px;
  padding: 8px;
  justify-content: space-between;
`
