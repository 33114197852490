import React from "react";

const PencilEdit = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="19"
      height="20"
      viewBox="0 0 19 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.729 11.0643L11.202 3.59133C12.133 2.66133 13.642 2.66133 14.573 3.59133L15.79 4.80833C16.721 5.73933 16.721 7.24933 15.79 8.17933L8.281 15.6883C7.874 16.0953 7.322 16.3243 6.746 16.3243H3L3.094 12.5443C3.108 11.9883 3.335 11.4583 3.729 11.0643Z"
        stroke="#32A6D5"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.0664 4.74588L14.6324 9.31188"
        stroke="#32A6D5"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default PencilEdit;
