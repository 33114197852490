import styled from "styled-components";
import { Input } from "antd";

export const RowModal = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
`;
export const Label = styled.label`
  display: flex;
  flex: 0 0 26%;
  font-family: Nunito;
  font-weight: 400;
  font-style: normal;
  font-size: 0.875rem;
  line-height: 1.375rem;
  color: rgba(0, 0, 0, 0.85);
`;

export const InputField: typeof Input = styled(Input)`
  display: flex;
  flex: 0 0 74%;
`;
