import styled from "styled-components";
import { Checkbox } from "antd";

export const ContainerPadding = styled.div`
  padding-top: 10px;
`;

export const Col = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  gap: 10px;
  flex-direction: column;
  justify-content: flex-start;
`;

export const Row = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
`;

export const ShortLabel = styled.label`
  display: flex;
  flex: 0 0 26%;
`;

export const Label = styled.label`
  display: flex;
  flex: 0 0 50%;
`;

export const Check = styled(Checkbox)`
  display: flex;
  flex: 0 0 30%;
`;

export const Wrapper = styled.div`
  padding-top: 10px;
  padding-bottom: 5px;
  margin-bottom: 2px;
  position:relative;
`;

export const DisabledContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;
export const ErrorContainer = styled.div`
  position:absolute;
  top:42px;
  right:60px;
`
