import { useEffect, useState } from "react";
import { collection, getDocs, orderBy, query } from "firebase/firestore";
import { db } from "./firebaseConfig";

const useSubscribeChannelMessages = (ids: string[]) => {
  const [channelMessages, setChannelMessages] = useState<any[]>([]);

  useEffect(() => {
    if (!ids.length) {
      return;
    }

    const handleFetch = async () => await fetchData();

    handleFetch();
  }, [ids]);

  const fetchData = async () => {
      let data: any [] = []

    await Promise.all(
      ids.map(async (id) => {
        const q = query(
          collection(db, "channels", id, "messages"),
          orderBy("createdAt", "desc")
        );
        const querySnapshot = await getDocs(q);
        if (!querySnapshot) {
          return;
        }
        querySnapshot.docs.forEach((item) => data.push(item.data()));
      })
    );
      setChannelMessages(data)
  };

  return { channelMessages };
};

export default useSubscribeChannelMessages;
