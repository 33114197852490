import React from "react";
import { Form, message } from "antd";
import { Formik, FormikValues } from "formik";
import { useTranslation } from "react-i18next";
import { AxiosError } from "axios";
import Axios from "../../serverConfig";
import ValidationForgotPassword from "../../validationSchema/ValidationForgotPassword";
import { IForgotPassword } from "../../types/IForgotPassword";
import TextInput from "../TextInput";
import { SubmitButton } from "../LoginForm/styles";
import getPasswordResetUrl from "../../consts/getPasswordResetUrl";

const initialValues: IForgotPassword = {
  email: "",
};

interface IProps {
  handleSendPassword: () => void;
}

const ForgotPasswordForm: React.FC<IProps> = ({ handleSendPassword }) => {
  const { t } = useTranslation();

  const errorMessage = (err: string) => message.error(err);

  const handleSubmitForm = async (values: FormikValues) => {
    if (!values) {
      return;
    }
    const body: IForgotPassword = {
      email: values.email,
    };
    try {
      const res = await Axios.post(getPasswordResetUrl(), body);
      handleSendPassword()
    } catch (err: unknown | AxiosError) {
      const error = err as AxiosError;
      if (error.response) {
        const msg: any = error.response.data;
        errorMessage(msg.message);
      }
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={ValidationForgotPassword}
      onSubmit={handleSubmitForm}
    >
      {({ handleSubmit, values, handleChange, errors, touched }) => (
        <Form
          onFinish={handleSubmit}
          name="basic"
          layout="vertical"
          autoComplete="off"
        >
          <Form.Item>
            <TextInput
              name="email"
              value={values.email}
              errors={errors.email}
              touched={touched.email}
              handleChange={handleChange}
            />
          </Form.Item>
          <SubmitButton htmlType="submit">Send instructions</SubmitButton>
        </Form>
      )}
    </Formik>
  );
};

export default ForgotPasswordForm;
