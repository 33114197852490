import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import useSWR from "swr";
import { IVehicle } from "../../../types/IVehicle";
import getUserDetailUrl from "../../../consts/getUserDetailUrl";
import parseUserDetail from "../../../helpers/parseUserDetail";
import Card from "../Card";
import {VehiclesWrapper} from "./styles";
import Vehicle from "./Vehicle";
import {LoaderContainer} from "../../TableUsers/styles";
import {Spin} from "antd";

const CardUserVehicles = () => {
  const [vehicleData, setVehicleData] = useState<IVehicle[]>();

  const params = useParams()
  const {userId} = params
  const { data,isValidating } = useSWR(getUserDetailUrl(userId))

  useEffect(()=>{
    if(!data){
      return
    }
    const parsedUserDetails = parseUserDetail(data)
    const {vehicles} = parsedUserDetails
    setVehicleData(vehicles)
  },[isValidating])

  if(!data){
     return  <LoaderContainer>
      <Spin size={"large"}  />
    </LoaderContainer>
  }

  if(!vehicleData?.length){
    return(
    <Card title="Vehicles">
      <VehiclesWrapper>
        Empty
      </VehiclesWrapper>
    </Card>
    )
  }

  return (
    <Card title="Vehicles">
      <VehiclesWrapper>
        {vehicleData.map((item) => {
          const {...rest} = item
          return (
            <Vehicle
                {...rest}
            />
          );
        })}
      </VehiclesWrapper>
    </Card>
  );
};

export default CardUserVehicles;
