import React from "react";
import OpinionsCharger from "../../../../Opinions/OpinionsChargers";
import BottomBiggerCard from "../../../../Cards/BottomBiggerCard";
import RatesContainer from "../RatesContainer";

const RateCharger = () => {
  return (
    <BottomBiggerCard title={"Rating"}>
      <RatesContainer />
      <OpinionsCharger />
    </BottomBiggerCard>
  );
};

export default RateCharger;
