import React, {useEffect, useState} from "react";
import { useParams } from "react-router-dom";
import useSWR from "swr";
import getUserDetailUrl from "../../../consts/getUserDetailUrl";
import parseUserDetail from "../../../helpers/parseUserDetail";
import { OpinionsWrapper } from "../styles";
import Opinion from "../Opinion";

interface IOpinion {
  id:string
  comment: string
  rating: number
  date:string
  handle: string
  image: string
}

const OpinionsUser: React.FC = () => {
  const params = useParams();
  const { userId } = params;

  const [opinionState,setOpinionState] = useState([])

  const { data, isValidating } = useSWR(
    getUserDetailUrl(userId),
  );

  useEffect(()=>{
    if(!data){
      return
    }
    const parsed = parseUserDetail(data)
    const {ratings} = parsed
    setOpinionState(ratings)
  },[isValidating])

  if (!data || !opinionState.length) {
    return (
      <OpinionsWrapper>
        <h2>Empty</h2>
      </OpinionsWrapper>
    );
  }

  return (
    <OpinionsWrapper>
      {opinionState.map((item: IOpinion) => {
        const { ...rest } = item;
        return <Opinion {...rest} />;
      })}
    </OpinionsWrapper>
  );
};

export default OpinionsUser;
