import {useContext, useEffect, useState} from "react";
import { collection, onSnapshot, orderBy, query } from "firebase/firestore";
import { db } from "./firebaseConfig";
import TabsContext from "../context/tabsContext";

const useSubscribeMessages =  () => {
    const [messages, setMessages] = useState<any[]>([]);
    const {activeChannel} = useContext(TabsContext)

    useEffect(() => {
        if(!activeChannel){
            return
        }

        const q = query(collection(db,"channels",activeChannel,"messages"),orderBy('createdAt', 'desc'))
        const unsubscribeFetchAllMessages = onSnapshot(q,(doc)=>{
                const data = doc.docs.map((item)=>item.data())
                setMessages(data)
            }
        )

        return unsubscribeFetchAllMessages

    }, [activeChannel]);

    return { messages };
};

export default useSubscribeMessages;
