import BookingStatus from "../enums/bookingStatus";

const handleColorStatusType = (status: BookingStatus, isHost: boolean) => {
  if (isHost) {
    switch (status) {
      case BookingStatus.COMPLETED:
        return {
          cardColor: "#F1F3F5",
          hourBackgroundColor: "#FFFFFF",
          hourColor: "#32A6D5",
          chargerName: "#B0B9C6",
          chargerAddress: "#32A6D5",
          name: "#32A6D5",
          lastName: "#B0B9C6",
          border: "none",
          borderSelected: "4px solid #FFFFFF",
          boxShadow: "none",
          boxShadowSelected:
            "rgba(105, 103, 130, 0.24) 0px 1px 4px, rgb(105, 103, 130, 0.24) 0px 0px 0px 3px",
        };
      case BookingStatus.APPROVED:
        return {
          cardColor: "#32A6D5",
          hourBackgroundColor: "#B5D3E3",
          hourColor: "#FFFFFF",
          chargerName: "#F1F3F5",
          chargerAddress: "#FFFFFF",
          name: "#FFFFFF",
          lastName: "#F1F3F5",
          border: "none",
          borderSelected: "4px solid #FFFFFF",
          boxShadow: "none",
          boxShadowSelected:
            "rgba(105, 103, 130, 0.24) 0px 1px 4px, rgb(105, 103, 130, 0.24) 0px 0px 0px 3px",
        };
      case BookingStatus.ACTIVE:
        return{
          cardColor: "#32A6D5",
          hourBackgroundColor: "#B5D3E3",
          hourColor: "#FFFFFF",
          chargerName: "#F1F3F5",
          chargerAddress: "#FFFFFF",
          name: "#FFFFFF",
          lastName: "#F1F3F5",
          border: "none",
          borderSelected: "4px solid #FFFFFF",
          boxShadow: "none",
          boxShadowSelected:
              "rgba(105, 103, 130, 0.24) 0px 1px 4px, rgb(105, 103, 130, 0.24) 0px 0px 0px 3px",
        };
      case BookingStatus.PENDING:
        return {
          cardColor: "#E5E5E5",
          hourBackgroundColor: "#F1F3F5",
          hourColor: "#32A6D5",
          chargerName: "#B0B9C6",
          chargerAddress: "#32A6D5",
          name: "#32A6D5",
          border: "2px solid #E5E5E5",
          borderSelected: "2px solid #E5E5E5",
          boxShadow: "none",
          boxShadowSelected:
            "rgba(105, 103, 130, 0.24) 0px 1px 4px, rgb(105, 103, 130, 0.24) 0px 0px 0px 3px",
        };
      case BookingStatus.REJECTED:
        return {
          cardColor: "#FFFFFF",
          hourBackgroundColor: "#F1F3F5",
          hourColor: "#B5D3E3",
          chargerName: "#B0B9C6",
          chargerAddress: "#B5D3E3",
          name: "#B5D3E3",
          border: "2px solid #E5E5E5",
          borderSelected: "2px solid #E5E5E5",
          boxShadow: "none",
          boxShadowSelected:
            "rgba(105, 103, 130, 0.24) 0px 1px 4px, rgb(105, 103, 130, 0.24) 0px 0px 0px 3px",
        };
    }
  } else {
    switch (status) {
      case BookingStatus.COMPLETED:
        return {
          cardColor: "#F1F3F5",
          hourBackgroundColor: "#FFFFFF",
          hourColor: "#8BD033",
          chargerName: "#B0B9C6",
          chargerAddress: "#8BD033",
          name: "#8BD033",
          lastName: "#B0B9C6",
          border: "none",
          borderSelected: "4px solid #FFFFFF",
          boxShadow: "none",
          boxShadowSelected:
            "rgba(105, 103, 130, 0.24) 0px 1px 4px, rgb(105, 103, 130, 0.24) 0px 0px 0px 3px",
        };
      case BookingStatus.APPROVED:
        return {
          cardColor: "#8BD033",
          hourBackgroundColor: "#CEECA7",
          hourColor: "#FFFFFF",
          chargerName: "#F1F3F5",
          chargerAddress: "#FFFFFF",
          name: "#FFFFFF",
          lastName: "#F1F3F5",
          border: "none",
          borderSelected: "4px solid #FFFFFF",
          boxShadow: "none",
          boxShadowSelected:
            "rgba(105, 103, 130, 0.24) 0px 1px 4px, rgb(105, 103, 130, 0.24) 0px 0px 0px 3px",
        };
      case BookingStatus.PENDING:
        return {
          cardColor: "#E5E5E5",
          hourBackgroundColor: "#F1F3F5",
          hourColor: "#8BD033",
          chargerName: "#B0B9C6",
          chargerAddress: "#8BD033",
          name: "#8BD033",
          border: "2px solid #E5E5E5",
          borderSelected: "2px solid #E5E5E5",
          boxShadow: "none",
          boxShadowSelected:
            "rgba(105, 103, 130, 0.24) 0px 1px 4px, rgb(105, 103, 130, 0.24) 0px 0px 0px 3px",
        };
      case BookingStatus.REJECTED:
        return {
          cardColor: "FFF",
          hourBackgroundColor: "#F1F3F5",
          hourColor: "#CEECA7",
          chargerName: "#B0B9C6",
          chargerAddress: "#CEECA7",
          name: "#CEECA7",
          border: "2px solid #E5E5E5",
          borderSelected: "2px solid #E5E5E5",
          boxShadow: "none",
          boxShadowSelected:
            "rgba(105, 103, 130, 0.24) 0px 1px 4px, rgb(105, 103, 130, 0.24) 0px 0px 0px 3px",
        };
      case BookingStatus.ACTIVE:
        return{
          cardColor: "#32A6D5",
          hourBackgroundColor: "#B5D3E3",
          hourColor: "#FFFFFF",
          chargerName: "#F1F3F5",
          chargerAddress: "#FFFFFF",
          name: "#FFFFFF",
          lastName: "#F1F3F5",
          border: "none",
          borderSelected: "4px solid #FFFFFF",
          boxShadow: "none",
          boxShadowSelected:
              "rgba(105, 103, 130, 0.24) 0px 1px 4px, rgb(105, 103, 130, 0.24) 0px 0px 0px 3px",
        };
    }
  }
};

export default handleColorStatusType;
