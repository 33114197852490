import React, {useContext, useEffect, useState} from "react";
import useSWR from "swr";
import {message} from "antd";
import RateCard from "../../../Cards/CardUserRating/RateCard";
import {getChargerDetailsUrl} from "../../../../consts/getChargerDetailsUrl";
import convertArrayToRate from "../../../../helpers/convertArrayToRate";
import {IRating} from "../../../../types/IRating";
import TabsContext from "../../../../context/tabsContext";

const RatesContainer = () => {
    const { id } = useContext(TabsContext);
    const { data, isValidating } = useSWR(getChargerDetailsUrl(id))
    const [rate,setRate] = useState<IRating>()

    const errorMessage = () => message.error("Sorry something going wrong!")

    useEffect(() => {
        if(!data){
            return
        }
        handleParse()
    }, [isValidating]);

    const handleParse = () => {
        try {
            let arrayForParsedRate:number[] = []
            const { ratings } = data;
            if(!ratings.length){
                return
            }
            ratings.map(({rating}:{rating:number})=> (
                arrayForParsedRate.push(rating)
            ))
            if(!arrayForParsedRate.length){
                return
            }
            const ratingParsed = convertArrayToRate(arrayForParsedRate);
            if(ratingParsed){
                setRate(ratingParsed);
            }
        }catch (e:unknown) {
            errorMessage()
        }
    }

    if (!rate || rate.count  === 0 ) {
        return (<h1>Empty</h1>);
    }

    return (
      <RateCard
          rate_count={rate.count}
          rate={rate.mediumRate}
          rate_5={rate.rate5}
          rate_4={rate.rate4}
          rate_3={rate.rate3}
          rate_2={rate.rate2}
          rate_1={rate.rate1}
      />
  );
};

export default RatesContainer;
