import React, { ReactNode } from 'react';
import { RowModal } from '../../Modal/styles';
import { NumberInputField, NumberLabel } from './styles';
import { InputNumberProps } from 'antd';

interface IProps {
  addonAfter?: ReactNode;
  name: string;
  labelText?: string;
  defaultValue?: number;
  value: number;
  errors?: string;
  touched?: boolean;
  max: number;
  min: number;
  handleChange: InputNumberProps['onChange'];
  disabled?: boolean;
}

const TextInputRow: React.FC<IProps> = ({
  addonAfter,
  name,
  labelText,
  defaultValue,
  value,
  errors,
  touched,
  min,
  max,
  handleChange,
  disabled,
}) => {
  return (
    <>
      <RowModal>
        <NumberLabel>{labelText}</NumberLabel>
        <NumberInputField
          name={name}
          defaultValue={defaultValue}
          value={value}
          onChange={handleChange}
          addonAfter={addonAfter}
          disabled={disabled}
          min={min}
          max={max}
        />
      </RowModal>
      {errors && touched ? <div>{errors}</div> : null}
    </>
  );
};

export default TextInputRow;
