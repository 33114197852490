import React, { useState } from "react";
import {
  BlueEdit,
  DarkCharger1,
  DarkCharger2,
  DarkRapidCharger,
} from "../../../../../../../icons";
import EditChargerInformation from "../../../../../../Modal/EditChargerInformation";
import { CHARGER_TYPE } from "../../../../../../../enums/chargerType";
import {IChargerDetails} from "../../../../../../../types/rest/charger/IChargerDetails";
import handleRoundPrice from "../../../../../../../helpers/handleRoundPrice";
import {EditButton} from "../Parking/style";
import {
  BetweenRow,
  HourlyRate,
  MinimumSessionFee,
  Power,
  Row,
  Tethered, TextAreaField,
  Type,
  ValueText,
  Wrapper,
} from "./style";

interface IProps {
  details:IChargerDetails
  setDetails: React.Dispatch<React.SetStateAction<IChargerDetails | undefined>>
}

const Charger:React.FC<IProps> = ({details,setDetails}) => {
  const [toggleOpenEditor, setToggleOpenEditor] = useState(false);

  const handleToggle = () => {
    setToggleOpenEditor((prevState) => !prevState);
  };

  return (
    <>
      <EditChargerInformation
        toggleOpenEditor={toggleOpenEditor}
        handleToggle={handleToggle}
        chargerInfo={details}
        setChargerInfo={setDetails}
      />
      <Wrapper>
        <Row>
          {details.type === CHARGER_TYPE.TYPE1 && (
            <DarkCharger1 width={30} height={30} />
          )}
          {details.type === CHARGER_TYPE.TYPE2 && (
            <DarkCharger2 width={30} height={30} />
          )}
          {details.type === CHARGER_TYPE.RAPID && (
            <DarkRapidCharger width={30} height={30} />
          )}
          <Type>
            {details.type[0].toUpperCase() + details.type.substring(1)}
          </Type>
          <Power>{details.chargerRate}kW</Power>
        </Row>
        {details.tethered && <Tethered>Tethered</Tethered>}
        <BetweenRow>
          <HourlyRate>Rate</HourlyRate>
          <ValueText>£ {handleRoundPrice(details.hourlyRateFee)}</ValueText>
        </BetweenRow>
        <BetweenRow>
          <MinimumSessionFee>Minimum Session Fee</MinimumSessionFee>
          <ValueText>£ {handleRoundPrice(details.minimumSessionFee)}</ValueText>
        </BetweenRow>
        <EditButton type="text" icon={<BlueEdit />} onClick={handleToggle} />
        <TextAreaField disabled value={details.chargerAdditionalInformation} />
      </Wrapper>
    </>
  );
};

export default Charger;
