import React from "react";

const Cctv = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="32"
      height="33"
      viewBox="0 0 32 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M0.599609 15.9471C0.599609 7.38664 7.5392 0.447052 16.0996 0.447052V0.447052C24.66 0.447052 31.5996 7.38664 31.5996 15.9471V16.9471C31.5996 25.5075 24.66 32.4471 16.0996 32.4471V32.4471C7.5392 32.4471 0.599609 25.5075 0.599609 16.9471V15.9471Z"
        fill="#3B3558"
      />
      <rect
        x="12.7822"
        y="8.22501"
        width="13.8433"
        height="6.29242"
        rx="2"
        transform="rotate(30 12.7822 8.22501)"
        stroke="white"
      />
      <line
        x1="25.414"
        y1="17.5489"
        x2="24.0262"
        y2="19.9525"
        stroke="white"
        strokeLinecap="round"
      />
      <line
        x1="13.3827"
        y1="11.7642"
        x2="15.7863"
        y2="13.1519"
        stroke="white"
        strokeLinecap="round"
      />
      <path
        d="M15.4459 17.0282V20.8037H11.6704"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.65332 18.7867H9.67029C10.4987 18.7867 11.1703 19.4583 11.1703 20.2867V21.3207C11.1703 22.1491 10.4987 22.8207 9.67029 22.8207H9.65332V18.7867Z"
        stroke="white"
      />
      <rect
        x="23.5127"
        y="17.3262"
        width="1.25848"
        height="1.25848"
        transform="rotate(30 23.5127 17.3262)"
        stroke="white"
      />
    </svg>
  );
};

export default Cctv;
