import styled from "styled-components";
import { Button, Card } from "antd";

export const WrapperCard = styled.div`
  display: flex;
  overflow-x: hidden;
  flex-direction: row;
  max-width: 100%;
  padding: 20px;
  gap: 20px;
  background: #e5e5e5;
  height: 100%;
`;

export const LeftSide = styled.div`
  flex: 0 0 70%;
  max-width: 70%;
  display: flex;
  flex-direction: column;
`;

export const LeftCard = styled.div`
  display: flex;
  border-radius: 16px;
  flex-direction: column;
  width: 100%;
  gap: 20px;
`;

export const RightSide = styled(Card)`
  display: flex;
  flex-direction: column;
  height: 241px;
  width: 400px;
  border-radius: 16px;
  background: white;
`;
export const StatusItems = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const EditorCard = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1200px;
  min-height: 800px;
  padding: 16px 16px 8px;
  gap: 10px;
  background: #ffffff;
  border-radius: 16px 16px 0px 0px;
`;

export const Row = styled.div`
  gap: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const StatusCardItem = styled.span`
  font-family: "Nunito";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: #3b3558;
`;

export const PreviewButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background: #ffffff;
  border: 1px solid #d9d9d9;
  box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.016);
  border-radius: 2px;
  padding: 10px 16px;
  height: 40px;
  font-family: "Nunito";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: rgba(0, 0, 0, 0.85);
`;

export const UpdateContentButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 16px;
  width: 100%;
  height: 40px;
  background: #32a6d5;
  box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.043);
  border-radius: 2px;
  font-family: "Nunito";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #ffffff;
`;
export const WrapperCardRightSide = styled.div`
  display: flex;
  flex: 0 0 20%;
`;
export const ContentTextHeader = styled.span`
  font-family: "Nunito";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #3b3558;
`;
