import styled from "styled-components";
import {Badge} from "antd";

export const Wrapper = styled.div`
  border: 1px solid #f0f0f0;
  border-radius: 2px;
  width: 100%;
`

export const BadgeItem = styled(Badge)`
  bottom:7px;
`
