import { Layout, message } from "antd";
import { useEffect, useState } from "react";
import useSWR from "swr";
import { Content, Header, Nav } from "../../layout";
import ContentEditorTitle from "../../components/ContentEditorTitle";
import PreviewFaq from "../../components/Modal/Previews/PreviewFaq";
import MultipleEditors from "../../components/EditorContent/MultipleEditor";
import ContentEditorRightSide from "../../components/ContentEditorRightSide";
import getContentUrl from "../../consts/getContentUrl";
import { CONTENT_EDITOR_TITLE } from "../../enums/contentEditorTitle";
import { CONTENT_EDITOR_TYPE } from "../../enums/contentEditorType";
import Axios from "../../serverConfig";
import getEditContentUrl from "../../consts/getEditContentUrl";
import {
  IFaq,
  IMultiContent,
  IMultiItems,
} from "../../types/rest/editor/IMultiContent";
import {
  ContentTextHeader,
  LeftCard,
  LeftSide,
  WrapperCard,
} from "../About/styles";
import { RightSection } from "../Users/styles";
import { AddEditorButton, EditorCard } from "./styles";

const Faq = () => {
  const [isOpenPreview, setIsOpenPreview] = useState(false);

  const { data, error, isValidating } = useSWR(getContentUrl());
  const [faqInformation, setFaqInformation] = useState<IFaq>();
  const [states, setStates] = useState<IMultiItems[]>([]);

  const errorMessage = () => message.error("Sorry something going wrong!");
  const successMessage = () => message.success("Success!");

  useEffect(() => {
    if (!data) {
      return;
    }
    handleData();
  }, [isValidating, data]);

  const handleData = () => {
    try {
      data.map((item: IMultiContent) => {
        if (
          item.title === CONTENT_EDITOR_TITLE.FAQ &&
          item.type === CONTENT_EDITOR_TYPE.MULTI
        ) {
          setFaqInformation({
            id: item.id,
            title: item.title,
            type: item.type,
          });
          setStates(item.items);
        }
      });
    } catch (e) {
      errorMessage();
    }
  };

  const handleToggle = () => {
    setIsOpenPreview((prevState) => !prevState);
  };

  const deleteEditor = (id: number) => {
    setStates(states.filter((item) => item.id !== id));
  };

  const editorFunction = () => {
    return states.map((data, index) => {
      const setData = (newState: string) =>
        setStates((prevStates) =>
          prevStates?.map((state, idx) =>
            idx === index
              ? {
                  title: state.title,
                  markdown: newState,
                  id: state.id,
                }
              : state
          )
        );
      const setQuestion = (newName: string) =>
        setStates((prevState) =>
          prevState.map((state, idx) =>
            idx === index
              ? {
                  title: newName,
                  markdown: state.markdown,
                  id: state.id,
                }
              : state
          )
        );
      return (
        <MultipleEditors
          questionTitle={data.title}
          id={data.id}
          data={data.markdown}
          count={index}
          deleteEditor={deleteEditor}
          setData={setData}
          setQuestion={setQuestion}
        />
      );
    });
  };

  const generateEditor = () => {
    setStates((prevStates) => [
      ...prevStates,
      {
        title: `Question${states.length + 1}`,
        markdown: "",
      },
    ]);
  };

  const handleSubmit = async () => {
    try {
      await Axios.post(getEditContentUrl(), {
        id: faqInformation?.id,
        type: faqInformation?.type,
        title: faqInformation?.title,
        items: states,
      });
      successMessage();
    } catch (err: unknown) {
      errorMessage();
    }
  };

  if (!states || isValidating) {
    return <p>Loading</p>;
  }

  return (
    <>
      <PreviewFaq
        data={states}
        handleIsOpen={handleToggle}
        isOpenPreview={isOpenPreview}
      />
      <Layout>
        <Nav />
        <RightSection>
          <Header />
          <Content>
            <WrapperCard>
              <LeftSide>
                <LeftCard>
                  <ContentEditorTitle nameSection={"Faq"} />
                  <EditorCard>
                    <ContentTextHeader>Items</ContentTextHeader>
                    {editorFunction()}
                    <AddEditorButton onClick={generateEditor}>
                      + Add new item
                    </AddEditorButton>
                  </EditorCard>
                </LeftCard>
              </LeftSide>
              <ContentEditorRightSide
                lastEditionDate={states}
                handleToggle={handleToggle}
                onSubmit={handleSubmit}
              />
            </WrapperCard>
          </Content>
        </RightSection>
      </Layout>
    </>
  );
};

export default Faq;
