import React from "react";
import BottomBiggerCard from "../../../Cards/BottomBiggerCard";
import { DetailsItems } from "./styles";
import Detail from "./Detail";

const DetailsCard = () => {
    return(
        <BottomBiggerCard title={"Details"}>
            <DetailsItems>
                <Detail />
            </DetailsItems>
        </BottomBiggerCard>
    )
}

export default DetailsCard;
