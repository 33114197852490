import styled from "styled-components";

export const Wrapper = styled.div.attrs(
    (props: { active: boolean }) => props
)`
  min-height: 70px;
  width: 100%;
  gap: 10px;
  padding:16px;
  display: flex;
  flex-grow:1;
  flex-direction: row;
  border: ${(props) =>
          props.active ? "1px solid #32A6D5" : "1px solid #F1F3F5"};
  filter: drop-shadow(0px 0px 8px rgba(105, 103, 130, 0.08));
  border-radius: 8px;
`;

export const Col = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`;

export const HandleAndUserNameContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap:5px;
`;

export const Row = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  flex-direction: row;
`;

export const Handle = styled.span`
  display: flex;
  font-family: "Nunito";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  color: #3b3558;
`;

export const UserName = styled.span`
  display: flex;
  font-family: "Nunito";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #908e9b;
`;

export const DateOrHour = styled.span`
  display: flex;
  align-items: center;
  gap:5px;
  font-family: "Nunito";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 150%;
  color: #908e9b;
`;

export const IntroText = styled.span`
  font-family: "Nunito";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #908d9b;
`;
