import React from "react";
import { useTranslation } from "react-i18next";
import { Form, message } from "antd";
import { AxiosError } from "axios";
import { Formik, FormikValues } from "formik";
import { useParams } from "react-router-dom";
import Axios from "../../serverConfig";
import { SubmitButton } from "../LoginForm/styles";
import { IResetPassword } from "../../types/IResetPassword";
import ValidationResetPassword from "../../validationSchema/ValidationResetPassword";
import getConfirmChangePassword from "../../consts/getConfirmChangePassword";
import TextInput from "../TextInput";

interface IProps {
  handleResetPassword: () => void;
}

const initialValues: IResetPassword = {
  password: "",
  confirmPassword: "",
};

const ResetPasswordForm: React.FC<IProps> = ({ handleResetPassword }) => {
  const { t } = useTranslation();
  const params = useParams();

  const { codeId } = params;

  const errorMessage = (err: string) => message.error(err);

  const handleSubmitForm = async (values: FormikValues) => {
    if (!values) {
      return;
    }
    const body = {
      password: values.password,
      verificationCode:codeId,
    };
    try {
      const res = await Axios.post(getConfirmChangePassword(), body);
      handleResetPassword();
    } catch (err: unknown | AxiosError) {
      const error = err as AxiosError;
      if (error.response) {
        const msg: any = error.response.data;
        errorMessage(msg.message);
      }
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={ValidationResetPassword}
      onSubmit={handleSubmitForm}
    >
      {({ handleSubmit, values, handleChange, errors, touched }) => (
        <Form
          onFinish={handleSubmit}
          name="basic"
          layout="vertical"
          autoComplete="off"
        >
          <Form.Item>
            <TextInput
              passwordType={true}
              name="password"
              value={values.password}
              errors={errors.password}
              touched={touched.password}
              handleChange={handleChange}
            />
          </Form.Item>
          <Form.Item>
            <TextInput
              passwordType={true}
              name="confirmPassword"
              value={values.confirmPassword}
              errors={errors.confirmPassword}
              touched={touched.confirmPassword}
              handleChange={handleChange}
            />
          </Form.Item>
          <SubmitButton htmlType="submit">Reset Password</SubmitButton>
        </Form>
      )}
    </Formik>
  );
};

export default ResetPasswordForm;
