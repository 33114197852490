import React from "react";

const GreyStar = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <>
      <svg
        width="13"
        height="11"
        viewBox="0 0 13 11"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <path
          d="M5.81247 0.303056C5.98562 -0.101019 6.55847 -0.101018 6.73162 0.303057L8.07979 3.44909L11.5441 3.71865C11.9916 3.75347 12.1701 4.31465 11.825 4.60161L9.19705 6.78647L10.0049 10.0736C10.1107 10.504 9.64564 10.849 9.26439 10.623L6.27205 8.84909L3.27971 10.623C2.89845 10.849 2.4334 10.504 2.53918 10.0736L3.34705 6.78647L0.719139 4.60161C0.373981 4.31465 0.552488 3.75347 1.00001 3.71865L4.4643 3.44909L5.81247 0.303056Z"
          fill="#B0B9C6"
        />
      </svg>
    </>
  );
};

export default GreyStar;
