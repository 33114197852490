import { AxiosError } from 'axios';
import { PromoCodeData } from '../../components/TablePromoCodes';
import Axios from '../../serverConfig';

export const PromoCodes = {
  getAll: async () => {
    try {
      const response = await Axios({
        url: `${process.env.REACT_APP_SERVER_API_V2}/promo-code/list`,
      })

      return response.data;
    } catch (error) {
      if (error instanceof AxiosError) {
        throw new Error('Error fetching promo codes', {
          cause: error.response?.data,
        });
      }

      throw error;
    }
  },
  update: async (id: string, data: Partial<PromoCodeData>) => {
    try {
      await Axios({
        url: `${process.env.REACT_APP_SERVER_API_V2}/promo-code/${id}`,
        method: 'POST',
        data,
      })
    } catch (error) {
      if (error instanceof AxiosError) {
        throw new Error('Error updating promo code', {
          cause: error.response?.data,
        });
      }

      throw error;
    }
  },
  create: async (data: Omit<PromoCodeData, 'id'>) => {
    try {
      await Axios({
        url: `${process.env.REACT_APP_SERVER_API_V2}/promo-code`,
        method: 'POST',
        data,
      })
    } catch (error) {
      if (error instanceof AxiosError) {
        throw new Error('Error creating promo code', {
          cause: error.response?.data,
        });
      }

      throw error;
    }
  },
  delete: async (id: string) => {
    try {
      await Axios({
        url: `${process.env.REACT_APP_SERVER_API_V2}/promo-code/${id}`,
        method: 'DELETE',
      })
    } catch (error) {
      if (error instanceof AxiosError) {
        throw new Error('Error deleting promo code', {
          cause: error.response?.data,
        });
      }

      throw error;
    }
  },
};
