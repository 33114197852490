import React, { Dispatch, useEffect, useState } from "react";
import * as ReactDOMServer from "react-dom/server";
import {Input, Popconfirm} from "antd";
import draftToHtml from "draftjs-to-html";
import { MinusCircleOutlined } from "@ant-design/icons";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import htmlToDraft from "html-to-draftjs";
import { Editor } from "react-draft-wysiwyg";
import {
    ContentState,
    convertToRaw,
    EditorState,
} from "draft-js";
import { ContentEditorsWrapper } from "../../../pages/Faq/styles";
import { Column, CountSide, DeleteButton, Question } from "../styles";
import "../editorContent.css";

const HtmlToReactParser = require("html-to-react").Parser;
const htmlToReactParser = new HtmlToReactParser();

interface IProps {
    data:  string
    setData: Dispatch<any>;
    questionTitle?: string;
    id?: number;
    setQuestion?: (name: string) => void;
    deleteEditor?: (id: number) => void;
    count?: number;
}

const MultipleEditors:React.FC<IProps> = ({setData,data,questionTitle,setQuestion,deleteEditor,id,count}) => {
    const [editorState, setEditorState] = useState(() => {
        if (data) {
                const blockFromHtml = htmlToDraft(data);
                const state = ContentState.createFromBlockArray(
                    blockFromHtml.contentBlocks,
                    blockFromHtml.entityMap
                );
                return EditorState.createWithContent(state);
            }
        return EditorState.createEmpty();
    });


    useEffect(() => {
        const text = draftToHtml(convertToRaw(editorState.getCurrentContent()));
        const newValues = htmlToReactParser.parse(text);
        const reactHtml = ReactDOMServer.renderToStaticMarkup(newValues);
        setData(reactHtml);
    }, [editorState]);

        return (
            <ContentEditorsWrapper>
                <CountSide>{(count! + 1).toString()}</CountSide>
                <Column>
                    <Question>Question</Question>
                    <Input
                        value={questionTitle}
                        onChange={(e) => setQuestion!(e.target.value)}
                    />
                    <Question>Answer</Question>
                    <Editor
                        editorState={editorState}
                        wrapperClassName="editor-content-faq"
                        editorClassName="editor-wrapper-faq"
                        onEditorStateChange={setEditorState}
                        toolbar={{
                            list: { inDropdown: true },
                            link: { inDropdown: true },
                            options: ['inline', 'blockType', 'list', 'textAlign', 'colorPicker', 'link', 'embedded', 'emoji', 'image', 'remove', 'history'],
                            inline: {
                                options: [
                                    "bold",
                                    "italic",
                                    "underline",
                                    "strikethrough",
                                    "monospace",
                                ],
                            },
                        }}
                    ></Editor>
                </Column>
                <CountSide>
                    <Popconfirm placement="right" title="Are you sure?" onConfirm={()=>deleteEditor!(id!)} okText="Yes" cancelText="No">
                        <DeleteButton> <MinusCircleOutlined /></DeleteButton>
                    </Popconfirm>
                </CountSide>
            </ContentEditorsWrapper>
        );
    }

export default MultipleEditors;

