import React from "react";

const YellowStar = (props: React.SVGProps<SVGSVGElement>) => {
    return (<>
        <svg width="13" height="11" viewBox="0 0 13 11" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path d="M5.87692 0.303056C6.05008 -0.101019 6.62292 -0.101018 6.79608 0.303057L8.14425 3.44909L11.6085 3.71865C12.0561 3.75347 12.2346 4.31465 11.8894 4.60161L9.2615 6.78647L10.0694 10.0736C10.1751 10.504 9.7101 10.849 9.32884 10.623L6.3365 8.84909L3.34416 10.623C2.9629 10.849 2.49785 10.504 2.60363 10.0736L3.4115 6.78647L0.783592 4.60161C0.438434 4.31465 0.616942 3.75347 1.06446 3.71865L4.52875 3.44909L5.87692 0.303056Z" fill="#FFE600"/>
        </svg>
    </>)
}

export default YellowStar;