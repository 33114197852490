import {Button, ButtonProps} from "antd";
import styled from "styled-components";

export const SubmitButton: typeof Button = styled(Button)<ButtonProps>`
  width: 29.5rem;
  border-radius: 0.125rem;
  background-color: #32A6D5;
  font-family: Nunito;
  font-style: normal;
  font-weight: 700;
  font-size: 1.25rem;
  line-height: 1.56rem;
  color: #FFFFFF;

  &:hover, :active, :focus, :visited {
    background-color: #32A6D5;
    color: #FFFFFF;
  }
`

export const ForgetPasswordContainer = styled.span`
  display: flex;
  justify-content: flex-end;
  padding: 0rem 0rem 0.75rem;
`

export const ForgetPasswordText = styled.a`
  font-size: 0.875rem;
  line-height: 1.125rem;
  font-weight: 400;
  font-family: Nunito;
  color: #3B3558;

  &:hover, :active, :visited, :focus {
    color: #3B3558;
    text-decoration: none;
  }
`

export const Label = styled.label`
  font-family: Nunito;
  font-weight: 400;
  font-style: normal;
  font-size: 0.875rem;
  line-height: 1.375rem;
  color: rgba(0, 0, 0, 0.85);
`