import styled from "styled-components";

export const ChargerItems = styled.div`
  overflow-y: scroll;
  overflow-x: hidden;
  display: flex;
  flex:1;
  flex-direction: column;
  gap: 8px;
`;
