import React, { useContext } from "react";
import useSWR from "swr";
import { Popconfirm, Spin } from "antd";
import { IActiveCharger } from "../../../../../types/IActiveCharger";
import renderStars from "../../../../../helpers/createRateStar";
import TabsContext from "../../../../../context/tabsContext";
import { Delete } from "../../../../../icons";
import { getChargerDetailsUrl } from "../../../../../consts/getChargerDetailsUrl";
import UnBlock from "../../../../../icons/UnBlock";
import { LoaderContainer } from "../../../../TableUsers/styles";
import {
  ChargerAddressDetails,
  ChargerName,
  ChargerCard,
  ChargerCardRate,
  Rate,
  DisableButton,
} from "./styles";
import handleRoundPrice from "../../../../../helpers/handleRoundPrice";

interface IProps {
  id: number;
  name: string;
  address: string;
  chargerRate: string;
  handleSetCurrentCharger: (id: number) => void;
  currentCharger: IActiveCharger | undefined;
  handleDisableCharger: (id: number) => void;
  handleUnDisableCharger: (id: number) => void;
}

const Charger: React.FC<IProps> = ({
  name,
  address,
  chargerRate,
  id,
  handleSetCurrentCharger,
  currentCharger,
  handleDisableCharger,
  handleUnDisableCharger,
}) => {
  const { handleId } = useContext(TabsContext);
  const { data, isValidating } = useSWR(getChargerDetailsUrl(id));

  const handleClick = (id: number) => {
    handleId(id);
    handleSetCurrentCharger(id);
  };

  if (isValidating) {
    return (
      <LoaderContainer>
        <Spin size={"large"} />
      </LoaderContainer>
    );
  }

  return (
    <ChargerCard
      onClick={() => handleClick(id)}
      active={currentCharger?.id === id ? true : false}
    >
      {data.live ? (
        <Popconfirm
          placement="right"
          title="Are you sure?"
          onConfirm={() => handleDisableCharger(id)}
          okText="Yes"
          cancelText="No"
        >
          <DisableButton type="text" icon={<UnBlock />} />
        </Popconfirm>
      ) : (
        <Popconfirm
          placement="right"
          title="Are you sure?"
          onConfirm={() => handleUnDisableCharger(id)}
          okText="Yes"
          cancelText="No"
        >
          <DisableButton type="text" icon={<Delete />} />
        </Popconfirm>
      )}
      <ChargerCardRate>
        {renderStars(parseInt(chargerRate))}
        <Rate>{handleRoundPrice(chargerRate)}</Rate>
      </ChargerCardRate>
      <ChargerName>{name}</ChargerName>
      <ChargerAddressDetails>{address}</ChargerAddressDetails>
    </ChargerCard>
  );
};

export default Charger;
