import styled from "styled-components";
import {Button} from "antd";

export const VehicleContainer = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  gap: 7px;
  width:94%;
`
export const VehicleRow = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`
export const ButtonVehicle:typeof Button = styled(Button)`
   display:flex;
  align-items: center;
`
export const BatteryTitle = styled.span`
  font-family: Nunito;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #3b3558;
`;
export const BatteryText = styled.span`
  font-family: Nunito;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #b0b9c6;
`;
export const VehicleTitle = styled.span`
  font-family: Nunito;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #32a6d5;
`;
export const VehicleNameAndModelContainer = styled.span`
  display: flex;
  flex-direction: row;
  gap: 8px;
`;

export const VehicleNameMark = styled.span`
  font-size: 22px;
  font-family: Nunito;
  max-width: 300px;
  font-weight: 700;
  line-height: 26px;
  color: #3b3558;
`;

export const VehicleNameModel = styled.span`
  font-size: 22px;
  display:block;
  word-break: break-all;
  overflow:hidden;
  white-space: nowrap;
  font-family: Nunito;
  text-overflow: ellipsis;
  max-width:260px;
  border-right:1em solid transparent;
  font-weight: 400;
  line-height: 26px;
  color: #3b3558;
  &:hover {
    overflow: visible;
    white-space: normal;
    height:auto;
  }
`;
