import { Layout } from "antd";
import { Content, Header, Nav } from "../../layout";
import {
  CardUserData,
  CardUserRating,
  CardUserVehicles,
} from "../../components/Cards";
import CardContainer from "../../components/Cards/CardContainer";
import { Opinions } from "../../components";
import TabsContainer from "../../components/Tabs";
import { RightSection } from "../Users/styles";

export const UserDetails = () => {

  return (
    <Layout>
      <Nav />
      <RightSection>
        <Header />
        <Content>
          <CardContainer>
            <CardUserData/>
            <CardUserRating>
              <Opinions/>
            </CardUserRating>
            <CardUserVehicles />
          </CardContainer>
          <TabsContainer />
        </Content>
      </RightSection>
    </Layout>
  );
};

export default UserDetails;
