import { Button, ButtonProps } from 'antd';
import styled from 'styled-components';

export const SolidButton = styled(Button)<
  ButtonProps & {
    backgroundColor?: string;
  }
>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2rem;
  padding: 8px 16px 8px 16px;
  background: ${(props) => props.backgroundColor || '#1890ff'};
  border-radius: 2px;
  box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.043);
  color: #ffffff;
  font-family: Nunito;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
`;
