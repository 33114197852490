import React, { useState } from "react";
import { IVehicle } from "../../../../types/IVehicle";
import EditVehicle from "../../../Modal/EditVehicle";
import { BlueEdit } from "../../../../icons";
import { DividerWithMargin } from "../../../Divider";
import {
  BatteryText,
  BatteryTitle,
  ButtonVehicle,
  VehicleContainer,
  VehicleNameAndModelContainer,
  VehicleNameMark,
  VehicleNameModel,
  VehicleRow,
  VehicleTitle,
} from "./styles";

const Vehicle: React.FC<IVehicle> = ({
  id,
  name,
  make,
  model,
  batteryCapacity,
  plateReg,
}) => {
  const vehicles: IVehicle = {
    id,
    name,
    make,
    model,
    batteryCapacity,
    plateReg,
  };

  const [toggleOpenEditor, setToggleOpenEditor] = useState(false);
  const [vehicleDetails, setVehicleDetails] = useState<IVehicle>(vehicles);

  const handleToggle = () => {
    setToggleOpenEditor((prevState) => !prevState);
  };

  return (
    <>
      <EditVehicle
          toggleOpenEditor={toggleOpenEditor}
          handleToggle={handleToggle}
          vehicleDetails={vehicleDetails}
          setVehicleDetails={setVehicleDetails}
      />
      <VehicleContainer>
        <VehicleRow>
          <VehicleTitle>{vehicleDetails.name}</VehicleTitle>
          <ButtonVehicle
            type="text"
            icon={<BlueEdit />}
            onClick={handleToggle}
          />
        </VehicleRow>
        <VehicleNameAndModelContainer>
          <VehicleNameMark>{vehicleDetails.make.toUpperCase()}</VehicleNameMark>
          <VehicleNameModel>
            {vehicleDetails.model.toUpperCase()}
          </VehicleNameModel>
        </VehicleNameAndModelContainer>
        <VehicleRow>
          <BatteryTitle> Battery Capacity </BatteryTitle>
          <BatteryText> {vehicleDetails.batteryCapacity}</BatteryText>
        </VehicleRow>
        <VehicleRow>
          <BatteryTitle> Plate Reg </BatteryTitle>
          <BatteryText> {vehicleDetails.plateReg} </BatteryText>
        </VehicleRow>
        <DividerWithMargin top={"16px"} bottom={"20px"} />
      </VehicleContainer>
    </>
  );
};

export default Vehicle;
